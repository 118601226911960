import React from "react";
import {NavLink} from "react-router-dom";
function Webinar() {
    return(
        <>
        </>
        // <section id="lesson-section">
        //     <div className="container-fluid px-4">
        //         <div className="row align-items-center">
        //             <div className="col colmn-1 col-lg-6 col-12 order-lg-1 order-2">
        //                 <div className="img-wrapper text-lg-left text-center pl-xl-5">
        //                     <img className="img-fluid pl-xl-4" src={require('../../../assets/images/illo-video.png')} alt="Video Image" width="586" />
        //                 </div>
        //             </div>
        //             <div
        //                 className="col colmn-2 col-lg-6 col-md-9 col-12 order-lg-2 order-1 mb-lg-0 mb-5 mx-auto">
        //                 <div className="title-area mb-3 w-100" style={{maxWidth:"525px"}}>
        //                     <h6 className="sub-heading text-white mb-1" style={{opacity:"0.7"}}>COURSEREEL LESSON
        //                         #1</h6>
        //                     <h3 className="text-white font-weight-normal">Join us for Live Webinar Training on
        //                         August 26th</h3>
        //                 </div>
        //                 <div className="desc w-100 mb-4" style={{maxWidth:"570"}}>
        //                     <p className="md-txt text-white" style={{lineHeight:"1.56",fontWeight:"500"}}>
        //                         We’re going to walk you through CourseReel and how you can efficiently use it to
        //                         create video courses and
        //                         break into the <b>$25 BILLION</b> e-learning industry and start making money by
        //                         sharing your knowledge.
        //                     </p>
        //                 </div>
        //                 <div className="btn-wrapper">
        //                     <a href="https://coursereel.io/training" target="_blank" className="btn btn-2 w-100">Sign Up Now</a>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section>
    )
}
export default Webinar;