import React, { useContext, useEffect } from "react";
import store from "../../../../../../Store/store";
import { ReactComponent as UndoIcon } from "../../../../../assets/images/undo.svg";
import { ReactComponent as RedoIcon } from "../../../../../assets/images/redo.svg";
import { ReactComponent as LayerBackIcon } from "../../../../../assets/images/layer-back.svg";
import { ReactComponent as LayerFrontIcon } from "../../../../../assets/images/layer-front.svg";
import { ReactComponent as DuplicateIcon } from "../../../../../assets/images/duplicate.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/images/delete.svg";
import CanvasContext from "../../../../../../Global/CanvasContext";
import { update_layer } from "../../../../../../Store/actions/timelineActions";

const duplicate = (_clipboard, FabricCanvas) => {
    // clone again, so you can do multiple copies.
    _clipboard.clone((clonedObj) => {
        FabricCanvas.discardActiveObject();
        clonedObj.set({
            left: clonedObj.left + 10,
            top: clonedObj.top + 10,
            evented: true,
        });
        if (clonedObj.type === 'activeSelection') {
            // active selection needs a reference to the canvas.
            clonedObj.FabricCanvas = FabricCanvas;
            clonedObj.forEachObject(function (obj) {
                FabricCanvas.add(obj);
            });
            clonedObj.setCoords();
        } else {
            FabricCanvas.add(clonedObj);
        }
        _clipboard.top += 10;
        _clipboard.left += 10;
        FabricCanvas.setActiveObject(clonedObj);
        FabricCanvas.requestRenderAll();
    });
}

function Controls(props) {
    const FabricCanvas = useContext(CanvasContext);
    const handleDuplicate = () => {
        const activeObj = FabricCanvas.getActiveObject();
        const returnClonedObject = activeObj.clone((clonedObject) => {
            FabricCanvas.discardActiveObject();
            clonedObject.set({
                left: clonedObject.left + 50,
                top: clonedObject.top + 50,
                evented: true,
            });
            if (clonedObject.type === 'activeSelection') {
                // active selection needs a reference to the canvas.
                clonedObject.FabricCanvas = FabricCanvas;
                clonedObject.forEachObject(function (obj) {
                    FabricCanvas.add(obj);
                });
                clonedObject.setCoords();
            } else {
                FabricCanvas.add(clonedObject);
            }
            activeObj.top += 10;
            activeObj.left += 10;
            FabricCanvas.setActiveObject(clonedObject);
            FabricCanvas.requestRenderAll();
        });

    }
    const handleDelete = () => {
        const activeObject = FabricCanvas.getActiveObject();
        FabricCanvas.remove(activeObject);
        FabricCanvas.renderAll();
        store.dispatch(update_layer(props.slayer.track, props.slayer.layer.id, FabricCanvas.toJSON()))
    }

    const handleFront = () => {
        const activeObject = FabricCanvas.getActiveObject();
        FabricCanvas.bringToFront(activeObject);
        FabricCanvas.renderAll();
    }

    const handleBack = () => {
        const activeObject = FabricCanvas.getActiveObject();
        FabricCanvas.sendToBack(activeObject);

        // This Function will move the object to the back, even back to the background video / image. Now write a function to bring it
        // forward to the background
        const backgroundObject = FabricCanvas.getObjects().find((object) => object.hasOwnProperty('background_slide_type'));
        FabricCanvas.sendToBack(backgroundObject);
        FabricCanvas.renderAll();
    }
    return (
        <ul className="controls d-flex flex-wrap justify-content-center align-items-center">
            {/*<li className="control-btn pr-4 mr-4 d-flex align-items-center border-right">*/}
            {/*    <p className="transparency mb-0 pr-2">TRANSPARENCY</p>*/}
            {/*    <input id="transparent" class="form-control-range" type="range" name="transparency" step="0.01" min="0" max="1" />*/}
            {/*</li>*/}
            <li className="control-btn pr-4">
                <a id="undo" href="#" className="text-white" data-toggle="tooltip" title="Undo">
                    <svg width="18px" height="18px" className="d-block" viewBox="0 0 18 18">
                        <UndoIcon />
                    </svg>
                </a>
            </li>
            <li className="control-btn pr-4 mr-4 border-right">
                <a id="redo" href="#" className="text-white" data-toggle="tooltip" title="Redo">
                    <svg width="18px" height="18px" className="d-block" viewBox="0 0 18 18">
                        <RedoIcon />
                    </svg>
                </a>
            </li>
            <li className="control-btn pr-4">
                <a id="layer-back" href="#" className="text-white" data-toggle="tooltip" title="Layer Back" onClick={handleBack}>
                    <svg width="18px" height="18px" className="d-block" viewBox="0 0 18 18">
                        <LayerBackIcon />
                    </svg>
                </a>
            </li>
            <li className="control-btn pr-4 mr-4 border-right">
                <a id="layer-front" href="#" className="text-white" data-toggle="tooltip"
                    title="Layer Front" onClick={handleFront}>
                    <svg width="18px" height="18px" className="d-block" viewBox="0 0 18 18">
                        <LayerFrontIcon />
                    </svg>
                </a>
            </li>
            <li className="control-btn pr-4 mr-4 border-right" >
                <a id="duplicate" href="#" className="text-white" data-toggle="tooltip" title="Duplicate" onClick={handleDuplicate}>
                    <svg width="18px" height="18px" className="d-block" viewBox="0 0 18 18">
                        <DuplicateIcon />
                    </svg>
                </a>
            </li>
            <li className="control-btn" >
                <a id="delete" href="#" className="text-white" data-toggle="tooltip" title="Delete" onClick={handleDelete}>
                    <svg width="18px" height="18px" className="d-block" viewBox="0 0 18 18">
                        <DeleteIcon />
                    </svg>
                </a>
            </li>
        </ul>
    )
}
export default Controls