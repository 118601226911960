import axios from "axios";
import {baseURL} from "../../Global";
import {UPLOAD_RESET, UPLOADED, UPLOADING} from "./index";
import store from "../store";

export const uploadFile = (data,type) => (dispatch,getState) => {
    // Uploading Files
    const options = {
        headers:{
            'Authorization': 'Bearer '+getState().auth.token
        },
        onUploadProgress: (progressEvent) => {
            const {loaded, total} = progressEvent;
            let percentage = Math.floor((loaded*100) / total);
            if(percentage < 99)
            {
                dispatch({type:UPLOADING,payload:{per:percentage,type:type}});
            }
        }
    }
    axios.post(baseURL+'file_upload',data, options)
    .then(res => {
            console.log(res);
            if(res.data.status === true)
            {
                dispatch({type:UPLOADED,payload:{uploadedPercentage: 100,file:res.data.message,type:type}});
            }
            else
                console.log('Error Uploading file');
    })
}


export const initiateUploadFile = () => (dispatch,getState) => {
    // Uploading Files
    dispatch({type:UPLOADING,payload:0});
}

export const uploadReset = () => (dispatch,getState) => {
    // Uploading Files
    dispatch({type:UPLOAD_RESET});
}

