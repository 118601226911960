import React, { useContext } from "react";
import {
    backInDown, backInLeft, backInRight, backInUp,
    bounce,
    flash,
    headShake,
    heartBeat,
    pulse,
    rubberBand,
    shakeX,
    shakeY,
    swing
} from "../../../../../../Global/Animation";
import CanvasContext from "../../../../../../Global/CanvasContext";
function Motion() {
    const FabricCanvas = useContext(CanvasContext);
    const attention = ['bounce', 'flash', 'pulse', 'rubber_band', 'swing', 'heart_beat'];
    const shake = ['shake_x', 'shake_y', 'head_shake'];
    const back = ['back_in_down', 'back_in_left', 'back_in_right', 'back_in_up'];
    const handleClick = (type) => {
        const activeObj = FabricCanvas.getActiveObject();
        if (activeObj) {
            switch (type) {
                case 'bounce':
                    bounce(FabricCanvas, activeObj);
                    break;
                case 'flash':
                    flash(FabricCanvas, activeObj);
                    break;
                case 'pulse':
                    pulse(FabricCanvas, activeObj);
                    break;
                case 'rubber_band':
                    rubberBand(FabricCanvas, activeObj);
                    break;
                case 'swing':
                    swing(FabricCanvas, activeObj);
                    break;
                case 'heart_beat':
                    heartBeat(FabricCanvas, activeObj);
                    break;
                case 'shake_x':
                    shakeX(FabricCanvas, activeObj);
                    break;
                case 'shake_y':
                    shakeY(FabricCanvas, activeObj);
                    break;
                case 'head_shake':
                    headShake(FabricCanvas, activeObj);
                    break;
                case 'back_in_down':
                    backInDown(FabricCanvas, activeObj);
                    break;
                case 'back_in_left':
                    backInLeft(FabricCanvas, activeObj);
                    break;
                case 'back_in_right':
                    backInRight(FabricCanvas, activeObj);
                    break;
                case 'back_in_up':
                    backInUp(FabricCanvas, activeObj);
                    break;
                default:
                    break;
            }
        }
        else {
            alert('Please select any element to apply animation');
        }
    }
    return (
        <>
            <div className="motion-title mb-2">
                <p className="sm-txt">Attention seekers</p>
            </div>
            <ul className="motion-list list-1 pl-4">
                {attention.map((element, index) => <li onClick={() => handleClick(element)} className="list" key={index}><a className="text-white" href="#">{element.replace('_', ' ')}</a></li>)}
            </ul>
            <hr className="editor_hr" />
            <div className="motion-title mb-2">
                <p className="sm-txt">Shake</p>
            </div>
            <ul className="motion-list list-1 pl-4">
                {shake.map((element, index) => <li onClick={() => handleClick(element)} className="list" key={index}><a className="text-white" href="#">{element.replace('_', ' ')}</a></li>)}
            </ul>
            <hr className="editor_hr" />
            <div className="motion-title mb-2">
                <p className="sm-txt">Back In</p>
            </div>
            <ul className="motion-list list-1 pl-4">
                {back.map((element, index) => <li onClick={() => handleClick(element)} className="list" key={index}><a className="text-white" href="#">{element.replace('_', ' ')}</a></li>)}
            </ul>
            <hr className="editor_hr" />
        </>
    );
}
export default Motion;