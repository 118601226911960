import React from "react";
import {ReactComponent as TextIcon} from "../../../../../assets/images/text.svg";
import {ReactComponent as BackgroundIcon} from "../../../../../assets/images/background.svg";
import {ReactComponent as MediaIcon} from "../../../../../assets/images/media.svg";
import {ReactComponent as AudioIcon} from "../../../../../assets/images/audio.svg";
import {ReactComponent as AnimationIcon} from "../../../../../assets/images/animation.svg";
import {ReactComponent as TransitionIcon} from "../../../../../assets/images/transition.svg";
import { forceCheck } from 'react-lazyload';
import { forceVisible } from 'react-lazyload';
function Navigation() {
    const handleClick = () => {
        forceVisible();
    }
    return(
        <ul id="inner-tab" className="nav nav-tabs" role="tablist">
            <li className="nav-item mb-0">
                <a id="text-tab" className="nav-link active" data-toggle="tab" href="#text" role="tab" aria-controls="text" aria-selected="true">
                    <span className="icon d-block mb-2">
                        <svg width="20px" height="20px" viewBox="0 0 20 20">
                            <TextIcon />
                        </svg>
                    </span>
                    Text
                </a>
            </li>
            <li className="nav-item mb-0">
                <a id="bg-tab" className="nav-link" data-toggle="tab" href="#bg" role="tab" aria-controls="bg" aria-selected="false">
                    <span className="icon d-block mb-2">
                        <svg width="20px" height="20px" viewBox="0 0 20 20">
                            <BackgroundIcon />
                        </svg>
                    </span>
                    Background
                </a>
            </li>
            <li className="nav-item mb-0">
                <a id="media-tab" className="nav-link" data-toggle="tab" href="#media" role="tab" aria-controls="media" aria-selected="false">
                    <span className="icon d-block mb-2">
                        <svg width="20px" height="20px" viewBox="0 0 20 20">
                            <MediaIcon />
                        </svg>
                    </span>
                    Media
                </a>
            </li>
            <li className="nav-item mb-0">
                <a id="audio-tab" onClick={handleClick} className="nav-link" data-toggle="tab" href="#audio" role="tab" aria-controls="audio" aria-selected="false">
                    <span className="icon d-block mb-2">
                        <svg width="20px" height="20px" viewBox="0 0 20 20">
                            <AudioIcon />
                        </svg>
                    </span>
                    Audio
                </a>
            </li>
            <li className="nav-item mb-0">
                <a id="motion-tab" className="nav-link" data-toggle="tab" href="#motion" role="tab" aria-controls="motion" aria-selected="false">
                    <span className="icon d-block mb-2">
                        <svg width="20px" height="20px" viewBox="0 0 20 20">
                            <AnimationIcon />
                        </svg>
                    </span>
                    Motion
                </a>
            </li>
            <li className="nav-item mb-0">
                <a onClick={handleClick} id="transition-tab" className="nav-link" data-toggle="tab" href="#transition" role="tab" aria-controls="transition" aria-selected="false">
                    <span className="icon d-block mb-2">
                        <svg width="20px" height="20px" viewBox="0 0 20 20">
                            <TransitionIcon />
                        </svg>
                    </span>
                    Transition
                </a>
            </li>
        </ul>
    )
}
export default Navigation;