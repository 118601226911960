import React from 'react';
import {NavLink} from "react-router-dom";
import vineasxlogo from "../../assets/images/vineasx_logo.png";
import reellogo from "../../assets/images/ReelApps.png"

function Footer() {
    return (
        <footer id="app-footer">
            <div className="container-fluid px-4">
                <div className="row align-items-center">
                    <div className="col colmn-1 col-xl-3 col-12 mb-xl-0 mb-3 pr-xl-0">
                        <ul id="footer-nav" className="row mx-0">
                            <li className="nav-item"><a href="https://coursereel.io/privacy.html" target="_blank" className="text-white pl-0">Terms & Conditions</a></li>
                            <li className="nav-item"><a href="https://coursereel.io/privacy.html" target="_blank" className="text-white">Privacy Policy</a></li>
                            <li className="nav-item"><a href="https://support.vineasx.com/" target="_blank"
                                className="text-white pr-0">Support</a></li>
                        </ul>
                    </div>
                    <div className="col colmn-2 col-xl-7 col-12 mb-xl-0 mb-3">
                        <div className="copyright text-center">
                            <p className="text-white">
                            © {(new Date().getFullYear())}, All Rights Reserved to VineaSX Solutions LLC. Developed and Maintained by
                                    <a href="https://vega6.com/" target="_blank" className="ml-2">Vega6</a>
                            </p>
                        </div>
                    </div>
                    <div className="col colmn-3 col-xl-2 col-12">
                        <a href="https://reelapps.io/" target="_blank" className="d-block text-xl-right text-center">
                            <img className="img-fluid" src={reellogo} alt="ReelApps Logo" />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default Footer;