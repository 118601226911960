import React, {useEffect, useState} from "react";
import LazyLoad from 'react-lazyload';
function Transition(props) {
    const [transitions,setTransitions] = useState([]);
    useEffect(() => {
        setTransitions([
            {id:'1',name:'Angular',url:'https://development-v6.s3.amazonaws.com/Transitions/Angular.gif'},
            {id:'1',name:'Blend Multiply',url:'https://development-v6.s3.amazonaws.com/Transitions/Blend+Multiply.gif'},
            {id:'1',name:'Boitievertical',url:'https://development-v6.s3.amazonaws.com/Transitions/Boitievertical.gif'},
            {id:'1',name:'Bounce',url:'https://development-v6.s3.amazonaws.com/Transitions/Bounce.gif'},
            {id:'1',name:'Burn',url:'https://development-v6.s3.amazonaws.com/Transitions/Burn.gif'},
            {id:'1',name:'Butterfly wave scrawler',url:'https://development-v6.s3.amazonaws.com/Transitions/Butterfly+wave+scrawler.gif'},
            {id:'1',name:'Circle open',url:'https://development-v6.s3.amazonaws.com/Transitions/Circle+open.gif'},
            {id:'1',name:'Circle',url:'https://development-v6.s3.amazonaws.com/Transitions/Circle.gif'},
            {id:'1',name:'Color distance',url:'https://development-v6.s3.amazonaws.com/Transitions/Color+distance.gif'},
            {id:'1',name:'Crazy parametric fun',url:'https://development-v6.s3.amazonaws.com/Transitions/Crazy+parametric+fun.gif'},
            {id:'1',name:'Cross zoom',url:'https://development-v6.s3.amazonaws.com/Transitions/Cross+zoom.gif'},
            {id:'1',name:'Directional wipe',url:'https://development-v6.s3.amazonaws.com/Transitions/Directional+wipe.gif'},
            {id:'1',name:'Directional wrap',url:'https://development-v6.s3.amazonaws.com/Transitions/Directional+wrap.gif'},
            {id:'1',name:'Directional',url:'https://development-v6.s3.amazonaws.com/Transitions/Directional.gif'},
            {id:'1',name:'Doorway',url:'https://development-v6.s3.amazonaws.com/Transitions/Doorway.gif'},
            {id:'1',name:'Dreamy zoom',url:'https://development-v6.s3.amazonaws.com/Transitions/Dreamy+zoom.gif'},
            {id:'1',name:'Dreamy',url:'https://development-v6.s3.amazonaws.com/Transitions/Dreamy.gif'},
            {id:'1',name:'Fade color',url:'https://development-v6.s3.amazonaws.com/Transitions/Fade+color.gif'},
            {id:'1',name:'Fade gray scale',url:'https://development-v6.s3.amazonaws.com/Transitions/Fade+gray+scale.gif'},
            {id:'1',name:'Fade',url:'https://development-v6.s3.amazonaws.com/Transitions/Fade.gif'},
            {id:'1',name:'Glitch displace',url:'https://development-v6.s3.amazonaws.com/Transitions/Glitch+displace.gif'},
            {id:'1',name:'Glitchmemories',url:'https://development-v6.s3.amazonaws.com/Transitions/Glitchmemories.gif'},
            {id:'1',name:'Heart',url:'https://development-v6.s3.amazonaws.com/Transitions/Heart.gif'},
            {id:'1',name:'Hexagonlize',url:'https://development-v6.s3.amazonaws.com/Transitions/Hexagonlize.gif'},
            {id:'1',name:'Inverted page curl',url:'https://development-v6.s3.amazonaws.com/Transitions/Inverted+page+curl.gif'},
            {id:'1',name:'LinearBlur',url:'https://development-v6.s3.amazonaws.com/Transitions/LinearBlur.gif'},
            {id:'1',name:'Luminance Melt',url:'https://development-v6.s3.amazonaws.com/Transitions/Luminance_Melt.gif'},
            {id:'1',name:'Morph',url:'https://development-v6.s3.amazonaws.com/Transitions/Morph.gif'},
            {id:'1',name:'Multiply blend',url:'https://development-v6.s3.amazonaws.com/Transitions/Multiply+blend.gif'},
            {id:'1',name:'PhinWheel',url:'https://development-v6.s3.amazonaws.com/Transitions/Pixelize.gif'},
            {id:'1',name:'Pixelize',url:'https://development-v6.s3.amazonaws.com/Transitions/PhinWheel.gif'},
            {id:'1',name:'Polar function',url:'https://development-v6.s3.amazonaws.com/Transitions/Polar_function.gif'},
            {id:'1',name:'Polkasquarecertains',url:'https://development-v6.s3.amazonaws.com/Transitions/Polkasquarecertains.gif'},
            {id:'1',name:'Radial',url:'https://development-v6.s3.amazonaws.com/Transitions/Radial.gif'},
            {id:'1',name:'Ripple',url:'https://development-v6.s3.amazonaws.com/Transitions/Ripple.gif'},
            {id:'1',name:'Simple zoom',url:'https://development-v6.s3.amazonaws.com/Transitions/Simple+zoom.gif'},
            {id:'1',name:'Random squares',url:'https://development-v6.s3.amazonaws.com/Transitions/Random+squares.gif'},
            {id:'1',name:'Rotate Scale fade',url:'https://development-v6.s3.amazonaws.com/Transitions/Rotate_Scale_fade.gif'},
            {id:'1',name:'Sunrises shape',url:'https://development-v6.s3.amazonaws.com/Transitions/Sunrises+shape.gif'},
            {id:'1',name:'Swirl',url:'https://development-v6.s3.amazonaws.com/Transitions/Swirl.gif'},
            {id:'1',name:'Waterdrop',url:'https://development-v6.s3.amazonaws.com/Transitions/Waterdrop.gif'},
            {id:'1',name:'Wind',url:'https://development-v6.s3.amazonaws.com/Transitions/Wind.gif'},
            {id:'1',name:'Window blinds',url:'https://development-v6.s3.amazonaws.com/Transitions/Window+blinds.gif'},
            {id:'1',name:'Window slice',url:'https://development-v6.s3.amazonaws.com/Transitions/Window+slice.gif'},
            {id:'1',name:'Wipe down',url:'https://development-v6.s3.amazonaws.com/Transitions/Wipe+down.gif'},
            {id:'1',name:'Wipe left',url:'https://development-v6.s3.amazonaws.com/Transitions/Wipe+left.gif'},
            {id:'1',name:'Wipe Right',url:'https://development-v6.s3.amazonaws.com/Transitions/Wipe+Right.gif'},
            {id:'1',name:'Wipe up',url:'https://development-v6.s3.amazonaws.com/Transitions/Wipe+up.gif'},
            {id:'1',name:'Zoom in circle',url:'https://development-v6.s3.amazonaws.com/Transitions/Zoom+in+circle.gif'}
        ])
    },[])
    return (
        <div className="transition-list d-flex flex-wrap">

            {transitions.map((element,index) =>
                <div className="trans-item pt-0 pl-0" key={index}>
                    <a href="#">
                        <img className="img-fluid" src={element.url} alt="Transition Image" />
                    </a>
                    <span className="trans-title text-white">{element.name}</span>
                </div>
            )}
        </div>
    )
}
export default Transition;