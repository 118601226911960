import React from "react";
import {ReactComponent as Upload} from "../../../../assets/images/upload.svg";
function UploadProgress(props){
    const cName = 'show';
    return(
        <div id="prog-modal" style={{display:'block'}} className={`modal fade ${cName}`} tabIndex="-1" role="dialog" data-backdrop="static"
             aria-labelledby="modal-title" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered mx-auto" role="document">
                <div className="modal-content">
                    <div className="modal-body bg-white text-center">
                        <div className="img-wrapper mb-1">
                            <Upload />
                        </div>
                        <h4 className="modal-title mb-2 upload_title" id="delete-warn">Uploading
                            File</h4>
                        <p className="w-100 mx-auto mb-3 para1">
                            <span className="d-block sp1">Your file is uploading to CourseReel.</span>
                            We will This may take a few moments. We will alert you when completed.
                        </p>
                        <div className="progress-label mb-3">{props.percentage !== undefined ? props.percentage +'%' : 0+'%'}</div>
                        <div className="progress mb-3">
                            <div className="progress-bar"
                                 style={{width:props.percentage+'%'}}
                                 role="progressbar"
                                 aria-valuenow={props.percentage}
                                 aria-valuemin="0"
                                 aria-valuemax="100">
                            </div>
                        </div>
                        <a href="#" className="btn btn-2 mt-3" data-dismiss="modal" onClick={props.onClose}>
                            Cancel
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default UploadProgress;