export const USER_LOADING = () => {
    return{
        type: 'USER_LOADING'
    }
}

export const USER_LOADED = () => {
    return{
        type: 'USER_LOADED'
    }
}

export const AUTH_ERROR = () => {
    return{
        type: 'AUTH_ERROR'
    }
}

export const LOGIN_SUCCESS = () => {
    return{
        type: 'LOGIN_SUCCESS'
    }
}

export const LOGIN_FAIL = () => {
    return{
        type: 'LOGIN_FAIL'
    }
}

export const LOGOUT_SUCCESS = () => {
    return{
        type: 'LOGOUT_SUCCESS'
    }
}
export const USER_UPDATED = () => {
    return{
        type: 'USER_UPDATED'
    }
}

export const GET_ERRORS = () => {
    return{
        type: 'GET_ERRORS'
    }
}

export const CLEAR_ERRORS = () => {
    return{
        type: 'CLEAR_ERRORS'
    }
}

export const UPLOADING = () => {
    return{
        type: 'UPLOADING'
    }
}

export const UPLOADED = () => {
    return{
        type: 'UPLOADED'
    }
}
export const UPLOAD_RESET = () => {
    return{
        type: 'UPLOADED'
    }
}

export const PROJECT = () => {
    return{
        type: 'PROJECT'
    }
}
export const TIMELINE_SETTINGS = () => {
    return{
        type: 'TIMELINE_SETTINGS'
    }
}
export const ACTIVE_ITEM = () => {
    return{
        type: 'ACTIVE_ITEM'
    }
}
export const NEEDLE_POINT = () => {
    return{
        type: 'NEEDLE_POINT'
    }
}
export const PROJECT_DETAILS = () => {
    return{
        type: 'PROJECT_DETAILS'
    }
}
export const PROJECT_UPDATE = () => {
    return{
        type: 'PROJECT_UPDATE'
    }
}
export const PROJECT_UNMOUNT = () => {
    return{
        type: 'PROJECT_UNMOUNT'
    }
}
export const CLEAR_PROJECT = () => {
    return{
        type: 'PROJECT_UNMOUNT'
    }
}

export const TRANSCRIBE_UPDATE = () => {
    return{
        type: 'TRANSCRIBE_UPDATE'
    }
}
export const SHOW_NOTIFICATION = () => {
    return{
        type: 'SHOW_NOTIFICATION'
    }
}
export const HIDE_NOTIFICATION = () => {
    return{
        type: 'HIDE_NOTIFICATION'
    }
}

export const FETCH_ACCOUNTS = () => {
    return{
        type: 'FETCH_ACCOUNTS'
    }
}

export const HIDE_SOCIAL_ACCOUNTS = () => {
    return{
        type: 'HIDE_SOCIAL_ACCOUNTS'
    }
}

export const ADD_REBRAND = () => {
    return {
        type: 'ADD_REBRAND'
    }
}



