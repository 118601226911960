import {useDrag} from "react-dnd";
import React from "react";
import store from "../../../../../../../Store/store";
import {updateCanvasBackgroundProperties} from "../../../../../../../Store/actions/timelineActions";

function ImageLoad(props) {
    const [isDragging,drag] = useDrag({
        item:{
            type:'TRACK',
            trackType:'graphic',
            graphicType:'image',
            url:props.result.thumbnail ? props.result.thumbnail : props.result.url,
            duration:5
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        })
    });

    const handleClick = (url, thumbnail) => {
        if (props.layer !== false) {
            const selectedLayer = props.layer;
            const trackID = props.trackID;
            const properties = {
                type: 'image',
                value: thumbnail ? thumbnail : url,
                thumbnail: thumbnail ? thumbnail : url
            }
            store.dispatch(updateCanvasBackgroundProperties(trackID, selectedLayer.id, properties));
        }
    }

    return(
        <a key={props.index} className="add-img" onClick={() => handleClick(props.result.url, props.result.thumbnail)} style={isDragging.isDragging ? {opacity:'0.2'} : {opacity:'1'}} ref={drag}>
            <img src={props.result.thumbnail ? props.result.thumbnail : props.result.url} className="img-fluid" alt="" />
        </a>
    )
}
export default ImageLoad