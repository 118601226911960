import React, {useEffect, useState} from "react";
import store from "../../../../Store/store";
import axios from "axios";
import {baseURL} from "../../../../Global";
import {displayNotification} from "../../../../Store/actions/notificationAction";
import Notification from "../../Notification/Notification";
import {connect} from "react-redux";
import {fetchSocialAccounts} from "../../../../Store/actions/accountActions";
import ConfirmDelete from "../../ConfirmDelete/ConfirmDelete";
function Amazon(props) {
    const [amazonDetails, setAmazonDetails] = useState({
        access_key:false,
        secret_key:false,
        s3_bucket:false,
        s3_region:false,
        buttonText:'Connect',
        isLoading:false,
        connectedAccounts:[]
    });
    const config = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+store.getState().auth.token
    }
    const fetchAccounts = () => {
        const data1 = {
            user_id:store.getState().auth.user.user_id
        }
        axios.post(baseURL+'fetch-amazon-credentials',data1,{headers:config}).then(res => {
            if(res.data.status === true)
            {
                setAmazonDetails({
                    ...amazonDetails,
                    connectedAccounts:res.data.data
                })
            }
        }).catch(res => {})
    }
    const handleChange = (e) => {
        setAmazonDetails({
            ...amazonDetails,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setAmazonDetails({
            ...amazonDetails,
            isLoading:true,
            buttonText:'Connecting...'
        })
        const data = {
            user_id:store.getState().auth.user.user_id,
            access_key:amazonDetails.access_key,
            secret_key:amazonDetails.secret_key,
            s3_region:amazonDetails.s3_region,
            s3_bucket:amazonDetails.s3_bucket
        };


        axios.post(baseURL+'add-amazon-credentials',data,{headers:config}).then(res => {
            if(res.data.status === true)
            {
                store.dispatch(displayNotification('success',res.data.message))
                fetchAccounts();
            }
            else
            {
                store.dispatch(displayNotification('error',res.data.message))
            }
            setAmazonDetails({
                ...amazonDetails,
                isLoading:false,
                buttonText:'Connect'
            })

        }).catch(error => {})
    }

    const [confirmDelete, setConfirmDelete] = useState({
        status:false,
        id:0
    });

    const handleDelete = (id) => {
        setConfirmDelete({
            status:true,
            id:id
        })
    }

    const handleConfirmClose = () => {
        setConfirmDelete({
            status:false,
            id:0
        })
    }

    const handleConfirmDelete = () => {
        console.log('Delete',confirmDelete.id);
        const data = {
            user_id:store.getState().auth.user.user_id,
            amazon_id:confirmDelete.id
        }
        axios.post(baseURL+'delete-amazon-credential',data,{headers:config}).then(res => {
            if(res.data.status === true)
            {
                store.dispatch(displayNotification('success',res.data.message));
                // Fetch the updated Social Accounts
                store.dispatch(fetchSocialAccounts());
            }
            else
            {
                store.dispatch(displayNotification('error',res.data.message));
            }
            handleConfirmClose(); // close the confirm delete popup
            fetchAccounts();
        }).catch(error => {console.log(error)})
    }


    useEffect(() => {
        // Fetch Amazon Credentials
        fetchAccounts();
    },[])
    return (
        <>
        <div className="row row-3">
            <Notification type={props.notification.notification.type} message={props.notification.notification.message}/>
            <div className="col col-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="text-white">Connect your Amazon account</h4>
                    </div>
                    <div className="card-body">
                        <div className="col col-12">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col col-lg-6 col-12 px-2">
                                        <div className="form-group">
                                            <input className="form-control input-dark" type="text" name="access_key" onChange={handleChange} required />
                                            <label>Access key</label>
                                        </div>
                                    </div>
                                    <div className="col col-lg-6 col-12 px-2">
                                        <div className="form-group">
                                            <input className="form-control input-dark" type="text" name="secret_key"  onChange={handleChange} required />
                                            <label>Secret Key</label>
                                        </div>
                                    </div>
                                    <div className="col col-lg-6 col-12 px-2">
                                        <div className="form-group">
                                            <input className="form-control input-dark" type="text" name="s3_bucket" onChange={handleChange} required />
                                            <label>S3 Bucket</label>
                                        </div>
                                    </div>
                                    <div className="col col-lg-6 col-12 px-2">
                                        <div className="form-group">
                                            <select className="form-control input-dark" name="s3_region" onChange={handleChange} required >
                                                <option disabled selected>Select Region</option>
                                                <option value="us-east-2">US East (Ohio)</option>
                                                <option value="us-east-1">US East (N. Virginia)</option>
                                                <option value="us-west-2">US West (Oregon)</option>
                                                <option value="ap-southeast-2">Asia Pacific (Sydney)</option>
                                                <option value="ca-central-1">Canada (Central)</option>
                                                <option value="eu-west-1">EU (Ireland)</option>
                                                <option value="sa-east-1">South America (São Paulo)</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col col-md-12">
                                        <button className="btn btn-3 update-pass pull-right">{amazonDetails.buttonText}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <br/>
        <div className="row row-3">
            <div className="col col-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="text-white">Your Connected Amazon Account </h4>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col col-lg-12 col-12 px-2">
                                {amazonDetails.connectedAccounts.length > 0 ?
                                    <div className="table-responsive">
                                        <table className="table text-white">
                                            <thead>
                                                <th>Access Key</th>
                                                <th>Action</th>
                                            </thead>
                                            <tbody>
                                            {amazonDetails.connectedAccounts.map(result => <tr>
                                                <td>{result.access_key}</td>
                                                <td><button className="btn btn-3 update-pass" onClick={() => handleDelete(result.id)}><i className="fa fa-trash-o"> </i></button></td>
                                            </tr> )
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                     : <p className="text-white text-center">No Amazon Account connected</p>}

                            </div>
                            {confirmDelete.status ? <ConfirmDelete close={handleConfirmClose} confirm={handleConfirmDelete}/> : null }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
const mapStateToProps = state => {
    return {
        notification:state.notification
    }
}
export default connect(mapStateToProps)(Amazon);