import React, { useEffect, useState } from "react";
import Search from "./Search/Search";
import Project from "./Project/Project";
import './style.css';
import store from "../../../Store/store";
import Menu from "../../Includes/Menu/Menu";
import { connect } from "react-redux";
import { projectFetch } from "../../../Store/actions/projectAction";
import Footer from "../../Includes/Footer/Footer";
import Notification from "../Notification/Notification";
import PublishModal from "../PublishModal/PublishModal";
import { fetchSocialAccounts } from "../../../Store/actions/accountActions";
import axios from "axios";
import { baseURL } from "../../../Global";
import { displayNotification } from "../../../Store/actions/notificationAction";
import ConfirmDelete from "../ConfirmDelete/ConfirmDelete";
function Projects(props) {
    const projectsData = props.projects.projects;
   
    const[projects,setProjects]=useState([])
    const[q,setQ]=useState('')
    
    useEffect(() => {
        const data = 'user_id=' + store.getState().auth.user.user_id;
        store.dispatch(projectFetch(data));
        store.dispatch(fetchSocialAccounts());
        return () => {

        }
    }, [])

    const [publish, setPublish] = useState({
        url: false,
        open: false
    });

    const openPublish = (url) => {
        setPublish({
            url: url,
            open: true
        });
    }

    const closePublish = () => {
        setPublish({
            url: false,
            open: false
        });
    }

    const [confirmDelete, setConfirmDelete] = useState({
        status: false,
        id: 0
    });

    const handleDelete = (id) => {
        setConfirmDelete({
            status: true,
            id: id
        })
    }

    const handleConfirmClose = () => {
        setConfirmDelete({
            status: false,
            id: 0
        })
    }

    const handleConfirmDelete = () => {
        const data = {
            user_id: store.getState().auth.user.user_id,
            project_id: confirmDelete.id
        };
        const config = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + store.getState().auth.token
        }
        axios.post(baseURL + 'project-delete', data, { headers: config }).then(res => {
            if (res.data.status === true) {
                const data = 'user_id=' + store.getState().auth.user.user_id;
                store.dispatch(projectFetch(data));
                store.dispatch(displayNotification('success', res.data.message));
            }
            else {
                store.dispatch(displayNotification('error', res.data.message))
            }
            handleConfirmClose();
        }).catch(error => { })
    }
    useEffect(() => {

        const videoShow1 = projectsData.filter((proj) => {

            if (q !== undefined) {

                return proj.name.toLowerCase().includes(q.toLowerCase())
            } else {
                return proj
            }
        })
        setProjects(videoShow1)



    }, [q,projectsData])
    return (
        <body className="project-page">
            <Menu profile={store.getState().auth.user.profile_pic} location={props.location} />
            <Notification type={props.notification.notification.type} message={props.notification.notification.message} />
            <Search count={projects.length} q={q} setQ={setQ}/>
            <section id="project-section" className="bg-dark min-vh-100">
                <div className="container-fluid px-4">
                    <div className="row mb-0">
                        {projectsData.length > 0 ?projects.length>0? projects.map((element, index) => <Project delete={handleDelete} key={index} project={element} onPublish={openPublish} />):<p className="text-white margin-center">No Data Found</p> : <p className="text-white margin-center">No projects in your account</p>}
                    </div>
                    {publish.open ? <PublishModal close={closePublish} url={publish.url} /> : null}
                    {confirmDelete.status ? <ConfirmDelete close={handleConfirmClose} confirm={handleConfirmDelete} /> : null}
                </div>

            </section>
            <Footer />
        </body>

    );
}
const mapStateToProps = state => {
    return {
        projects: state.projects,
        notification: state.notification
    }
}
export default connect(mapStateToProps)(Projects);
