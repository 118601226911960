import React, { useEffect, useState } from "react";
import { ReactComponent as Edit } from "../../../assets/images/edit.svg";
import { ReactComponent as Download } from "../../../assets/images/download.svg";
import { ReactComponent as Retry } from "../../../assets/images/retry.svg";
import { ReactComponent as Publish } from "../../../assets/images/publish.svg";
import { ReactComponent as Delete } from "../../../assets/images/delete.svg";
import { NavLink } from "react-router-dom";
import axios from "axios";
import store from "../../../../Store/store";
import { baseURL } from "../../../../Global";
import { displayNotification } from "../../../../Store/actions/notificationAction";
import { projectFetch } from "../../../../Store/actions/projectAction";
import  Voicereelmarksvg from "../../../assets/images/voicereel-mark.svg";
import playbtnsvg from "../../../assets/images/play-button.svg"

function Project(props) {
	const d = new Date(props.project.created);
	const monthNames = ["January", "February", "March", "April", "May", "June",
		"July", "August", "September", "October", "November", "December"
	];
	const [loading, setLoading] = useState(false);
	const dateTime = monthNames[d.getMonth()] + ' ' + d.getDate() + ',' + ' ' + d.getFullYear() + ' ' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();


	const retryRendering = (projectId) => {
		setLoading(true);
		axios.get(baseURL + 'initiate_render/' + projectId).then(res => {
			store.dispatch(displayNotification('success', 'Project Rendering Retry Initiated'));
			const data = 'user_id=' + store.getState().auth.user.user_id;
			store.dispatch(projectFetch(data));
			setLoading(false);
		}).catch(error => { })
	}



	const handlePublish = (url) => {
		props.onPublish(url);
	}

	let projectClass = false;
	let message;
	switch (props.project.status) {
		case '1':
			projectClass = 'transcribing';
			message = 'Transcribing';
			break;
		case '2':
			projectClass = 'success';
			message = 'Success';
			break;
		case '3':
			projectClass = 'rendering-failed';
			message = 'Transcribing Failed';
			break;
		case '4':
			projectClass = 'awaiting-customization';
			message = 'Awaiting Customization';
			break;
		case '5':
			projectClass = 'rendering';
			message = 'Rendering';
			break;
		case '6':
			projectClass = 'rendering';
			message = 'Rendering';
			break;

	}

	return (

		<div className="col colmn-20 col-xl-3 col-lg-6 col-sm-9 col-12 px-2">
			<div className="card-wrapper">
				<div
					className={`status ${projectClass} w-100 d-flex align-items-center justify-content-center mb-3 mr-0`}>
					<div className="progress-wrapper d-none">
						{props.project.status === '5' ? <div className="progress-label mb-2 text-white">Downloading Resources ...</div> :
							<>
								<div className="progress-label mb-2 text-white">Rendering</div>
								<div className="progress w-100">
									<div className="progress-bar" role="progressbar" style={{ width: '100%' }}
										aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"> </div>
								</div>
							</>
						}
					</div>
					<img className={`img-fluid poster ${props.project.status === '2' || props.project.status === '6' ? '' : 'status-img'}`}
						src={props.project.thumbnail !== '' ? props.project.thumbnail : Voicereelmarksvg} />
					{props.project.status === '2' || props.project.status === '6' ? null : <p className="md-txt text-white font-italic mb-0" style={{ fontWeight: '600' }}>
						{message}
					</p>}
					<div className="actions-icon row align-items-center mx-0">
						{props.project.status >= 5 && props.project.status !== 1 ? null : <div className="icon-wrapper">
							<NavLink to={'/editor/' + props.project.id} className="action-icon edit-icon mb-1">
								<svg width="20px" height="20px" viewBox=" 0 0 20 20">
									<Edit />
								</svg>
							</NavLink>
							<p className="sm-txt text-white">Edit</p>
						</div>
						}
						{props.project.status === '2' ? <div className="icon-wrapper">
							<a href={props.project.output_video} target="_blank" className="action-icon download-icon mb-1">
								<svg width="20px" height="20px" viewBox=" 0 0 20 20">
									<Download />
								</svg>
							</a>
							<p className="sm-txt text-white">Download</p>
						</div> : null}

						{props.project.status === '3' ? <div className="icon-wrapper">
							<a href="#" className="action-icon retry-icon mb-1" onClick={() => retryRendering(props.project.id)}>
								<svg width="20px" height="20px" viewBox=" 0 0 20 20">
									<Retry />
								</svg>
							</a>
							<p className="sm-txt text-white">Retry</p>
						</div> : null}

						{props.project.status === '2' ? <div className="icon-wrapper">
							<a href="#" className="action-icon publish-icon mb-1"
								onClick={() => handlePublish(props.project.output_video)}>
								<svg width="20px" height="20px" viewBox=" 0 0 20 20">
									<Publish />
								</svg>
							</a>
							<p className="sm-txt text-white">Publish</p>
						</div> : null
						}
						{loading ? <div className="spinner-grow" style={{ color: '#6b33d3' }}> </div> : null}
						{props.project.status >= 5 ? null : <div className="icon-wrapper">
							<a href="#" className="action-icon delete-icon remove mb-1"
								onClick={() => props.delete(props.project.id)}
							>
								<svg width="20px" height="20px" viewBox=" 0 0 20 20">
									<Delete />
								</svg>
							</a>
							<p className="sm-txt text-white">Delete</p>
						</div>}
					</div>
				</div>
				<div className="details-wrapper row mx-0">
					<a href="#" className="play pr-3 align-self-center">
						<img className="img-fluid" src={playbtnsvg} alt="Icon" />
					</a>
					<div className="meta-wrapper flex-grow-1">
						<div className="date mb-1">
							<span className="sm-txt text-white">{dateTime}</span>
						</div>
						<p className="text-white title" style={{ fontWeight: '600' }}>{props.project.name}</p>
					</div>
					<div className="time">
						<span className="sm-txt text-white">{props.project.length}</span>
					</div>
				</div>
			</div>
		</div>
	)
}
export default Project;