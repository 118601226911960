import { ReactComponent as ImageSearchIcon } from "../../../../../assets/images/search.svg";
import { ReactComponent as ImagePlusIcon } from "../../../../../assets/images/plus_1.svg";
import React, { useContext, useRef, useState } from "react";
import HoverVideoPlayer from "react-hover-video-player";
import videoRequest from "../../../../../../Global/videolibrary";
import CanvasContext from "../../../../../../Global/CanvasContext";
import loadVideoInFabric from "../../../../../../Global/loadVideoInFabric";

let page = 1
function Video(props) {
    const keywordRef = useRef();
    const FabricCanvas = useContext(CanvasContext);
    const [videoResults, setVideoResults] = useState({
        isLoading: false,
        results: []
    })

    const handleSubmit = (e) => {
        e.preventDefault();
        const { value } = keywordRef.current;
        const keyword = value;
        if (keyword !== '') {
            setVideoResults({
                isLoading: true,
                results: []
            });
        }
        // send axios request for the
        const data = 'keyword=' + keyword + '&page=' + page;
        videoRequest(data).then(results => {
            if (results.length > 1) {
                setVideoResults({
                    isLoading: false,
                    results: results
                })
            }
        }).catch(error => {
            setVideoResults({
                isLoading: false,
                results: []
            })
        });
    }
    const handleClick = (url) => {
        loadVideoInFabric(FabricCanvas, props.loader, url).then();
    }
    return (
        <>
            <div id="vid-clips" className="tab-pane fade" role="tabpanel" aria-labelledby="vid-clips-tab">
                <div className="input-group mb-3">
                    <button id="up-media-vid" className="up-vid-btn btn btn-3 mb-3 text-white d-block mx-auto">
                        <input type="file" className="file-type" accept=".mp4, .mov, .mpeg, .WEBM, .MOV" onChange={props.change} />
                        <svg width="12px" height="12px" className="ico v-center" viewBox=" 0 0 12 12"
                            style={{ color: '#d19df8' }}>
                            <ImagePlusIcon />
                        </svg>
                        Upload Media
                    </button>
                    <form onSubmit={handleSubmit} className="w-100">
                        <input id="search-vid" type="search" name="search-vid"
                            className="search-vid-media form-control input-dark" placeholder="Search" ref={keywordRef} />
                        <div className="input-group-prepend v-center cursor-pointer" onClick={handleSubmit}>
                            <svg width="16px" height="16px" viewBox=" 0 0 16 16">
                                <ImageSearchIcon />
                            </svg>
                        </div>
                    </form>
                </div>
                <div className="results-wrapper d-flex flex-wrap">
                    {videoResults.isLoading ? <div className="spinner-grow" style={{ color: '#6b33d3' }}> </div> : null}
                    {videoResults.results.map(result => (
                        <a href="javascript:void(0);" className="add-img" onClick={() => handleClick(result.url)}>
                            <HoverVideoPlayer
                                videoSrc={result.url}
                                pausedOverlay={
                                    <img src={result.thumbnail} className="img-fluid" alt="" />
                                }
                                loadingOverlay={
                                    <div className="spinner-grow" style={{ color: '#6b33d3' }}> </div>
                                }
                            />
                            <span className="badge vid-time">8.2s</span>
                        </a>
                    ))}
                    {props.file ? <a href="javascript:void(0);" className="add-img" onClick={() => handleClick(props.file.remote_path)}>
                        <HoverVideoPlayer
                            videoSrc={props.file.remote_path}
                            pausedOverlay={
                                <img src="" className="img-fluid" alt="" />
                            }
                            loadingOverlay={
                                <div className="spinner-grow" style={{ color: '#6b33d3' }}> </div>
                            }
                        />
                        <span className="badge vid-time">8.2s</span>
                    </a> : null}
                </div>
            </div>
        </>
    )
}

export default Video;