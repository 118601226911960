import React, {useState, useContext, useEffect} from "react";
import './Login.css';
import Footer from "../../Includes/Footer/Footer";
import {loginUser} from "../../../Store/actions/authActions";
import store from "../../../Store/store";
import {AuthenticationContext} from "../../App";
import Dashboard from "../../Core/Dashboard/Dashboard";
import {NavLink, Redirect} from "react-router-dom";
import Notification from "../../Core/Notification/Notification";
import {connect} from "react-redux";
import logo from  "../../assets/images/coursereel.png";

function Login(props) {
    const auth = useContext(AuthenticationContext);
    const [credentials, setCredential] = useState({
        email:null,
        password:null,
        buttonText:'Sign In',
    })
    useEffect(() => {
        setCredential({
            ...credentials,
            buttonText:'Sign In'
        })
        return  () => {}
    },[props.notification.showNotification]);
    const handleOnChange = (event) => {
        setCredential({
            ...credentials,
            [event.target.name]:event.target.value
        })
    }
    const handleOnSubmit = (event) => {
        event.preventDefault();
        setCredential({
            ...credentials,
            buttonText:'Signing In ...'
        })
        const authenticationFormData = {
            'email':credentials.email,
            'password':credentials.password
        };
        store.dispatch(loginUser(authenticationFormData));
    }
    return(
        <>
        {
            auth.isAuthenticated ? <Redirect to={Dashboard} /> :
            <div className="login-page">
                <section className="login-content">
                    <div className="container-fluid">
                        {props.notification.showNotification ? <Notification type={props.notification.notification.type} message={props.notification.notification.message}/> : null}
                        <div className="row mx-sm-auto align-items-center">
                            <div className="col colmn-1 col-lg-6 col-md-9 col-sm-10 col-12 mx-auto pr-lg-5">
                                <div className="content-wrapper text-center">
                                    <div className="img-wrapper mb-4">
                                        <img className="img-fluid" src={logo}
                                             alt="Logo"/>


                                    </div>
                                    <h4 className="text-white font-weight-normal">
                                        Create Video Courses, VSL and more easily using moderner drag-n-drop based timeline editor
                                    </h4>
                                </div>
                            </div>
                            <div className="col colmn-2 col-lg-6 col-md-9 col-sm-10 col-12 mx-auto pl-lg-5">
                                <form className="login-form" method="POST" onSubmit={handleOnSubmit}>
                                    <div className="form-title text-center mb-4">
                                        <h2 className="text-white">Login to Your Account Now</h2>
                                    </div>
                                    <div className="input-group mb-4">
                                        <input id="email" className="form-control" type="email" name="email"
                                               autoComplete="off" required onChange={handleOnChange}/>
                                        <label htmlFor="username" className="text-white">Username</label>
                                        <span className="underline"> </span>
                                    </div>
                                    <div className="input-group mb-4">
                                        <input id="pass" className="form-control" type="password" name="password"
                                               autoComplete="off" required onChange={handleOnChange}/>
                                        <label htmlFor="pass" className="text-white">Password</label>
                                        <span className="underline"> </span>
                                    </div>
                                    <div className="btn-wrapper text-center mb-4">
                                        <button className="btn btn-1 text-white text-uppercase">{credentials.buttonText}</button>
                                    </div>
                                    <div className="form-footer text-center">

                                         <NavLink to="/forget-password"> <p className="text-white sm-txt"> Forgot your account details? </p> </NavLink>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
        }
        </>

    )
}
const mapStateToProps = state => {
    return {
        notification:state.notification
    }
}
export default connect(mapStateToProps)(Login);
