import React, { useEffect, useState } from "react";
import Footer from "../../Includes/Footer/Footer";
import Menu from "../../Includes/Menu/Menu";
import Write from "./Write/Write";
import Upload from "./Upload/Upload";
import Record from "./Record/Record";
import Projects from "./Projects/Projects";
import Webinar from "./Webinar/Webinar";
import { Content } from "./Content/Content";
import store from "../../../Store/store";
import Video from "./Video/Video";
import { connect } from "react-redux";
import { projectFetch } from "../../../Store/actions/projectAction";
import Plyr from "plyr";
import { fetchSocialAccounts } from "../../../Store/actions/accountActions";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { baseURL } from "../../../Global";
import UpgradeCourseReelAl from "./UpgradeCourseReelAl ";

function Dashboard(props) {


	const dispatch = useDispatch()
	const auth = useSelector(state => state.auth);
	// uncomment down line
	const rebrandData = useSelector(state => state.rebrand);
	const [rebrand, setRebrand] = useState(rebrandData);
	const [acceptTerm, setAcceptTerm] = useState(false)
	const [loader, setLoader] = useState({
		acceptLoader: false,
		baseModal: false
	})
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const [show2, setShow2] = useState(false);
	const handleClose2 = () => setShow2(false);
	const handleShow2 = () => setShow2(true)

	// useEffect(() => {
	// 	dispatch(checkTerms(handleClose2, handleShow2, setLoader, loader))
	// }, [])

	useEffect(() => {
		// ankit add
		let id = parseInt(auth.user.user_id)
		axios({
			method: 'POST',
			url: `${baseURL}fetch-wl-rebranding`,
			data: { user_id: id },
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer' + ' ' + auth.token }
		}).then(res => {
			if (res.data.status === true) {
				dispatch({ type: 'ADD_REBRAND', payload: res.data.data });
			} else {
				// dispatch(setAlert(res.data.message,'danger'));
				console.log("err fatch")
			}
		}).catch(error => {
			console.log(error)
			// dispatch(setAlert('Getting Internal Server Error','danger'));
			console.log("err 2 fatch")
		})
	}, [])

	useEffect(() => {
		setRebrand(rebrandData);
	}, [rebrandData])

	// const handleSubmit = (e) => {
	// 	e.preventDefault()
	// 	setLoader({
	// 		...loader,
	// 		acceptLoader: true
	// 	})
	// 	dispatch(updateTerms(acceptTerm, setLoader, loader, handleClose2))
	// }



	// before

	useEffect(() => {
		const bgPlayer = Plyr.setup('#bgPlayer', { volume: 0, autoplay: true });

		const data = 'user_id=' + store.getState().auth.user.user_id;
		store.dispatch(projectFetch(data));
		store.dispatch(fetchSocialAccounts());
		// intervalID = setInterval(async () => store.dispatch(projectFetch(data)), 10000)
		// return () => {
		//     clearTimeout(intervalID);
		// };
	}, [])
	return (
		<div className="dashboard-page">
			<UpgradeCourseReelAl />

			<Menu profile={store.getState().auth.user.profile_pic} location={props.location} />
			<section id="intro-section">
				<div id="bg-video">
					{/*<div dangerouslySetInnerHTML={{*/}
					{/*	__html: "<iframe src=\"https://player.vimeo.com/video/428018128?background=1&autoplay=1&loop=1&byline=0&title=0\"\n" +*/}
					{/*		"frameBorder=\"0\" webkitallowfullscreen mozallowfullscreen allowFullScreen> </iframe>"*/}
					{/*}} />*/}
					<div className="vid-wrapper">
						<div id="bgPlayer" className="plyr__video-embed" data-plyr-provider='vimeo'
							data-plyr-embed-id='428018128'>
						</div>
					</div>
				</div>
				<div className="container-fluid px-4">
					<div className="row">
						<div
							className="col colmn-1 offset-xl-1 col-xl-6 col-lg-7 col-md-8 col-12 mb-lg-0 mb-5 px-xl-2">
							<div className="title-area text-lg-left text-center mb-2">
								<h1 className="big-heading">
									WELCOME
								</h1>
								<h2 className="big-heading font-weight-bold text-white">
									TO {rebrand.data !== false ? rebrand.data.name : 'COURSEREEL'}
								</h2>
							</div>
							<div className="desc text-lg-left text-center w-100 mx-lg-0 mx-auto mb-3 pb-3" style={{ maxWidth: '680px' }}>
								<p className="text-white font-weight-lighter" style={{ lineHeight: "1.75" }}>
									Start converting your voice-over or audio recordings into full content videos.
									Watch how easy it is for
									anyone to create great videos by converting audio or video recordings into a
									fresh new look using AI.
								</p>
							</div>
							<div className="intro-btn text-lg-left text-center">
								<a href="#" className="btn btn-1 md-txt text-white" data-toggle="modal" data-target="#watch-video">
									<i className="fa fa-play pr-1" aria-hidden="true"> </i>
									<span className="pl-2 text-white" style={{ fontWeight: "600" }}>Watch Now</span>
								</a>
							</div>
						</div>
						<div className="col colmn-2 col-lg-5 col-12">
							<div className="action-btn-wrapper">
								<Write />
								<Upload />
								<Record />
							</div>
						</div>
					</div>
				</div>
			</section>
			<Projects projects={props.projects} />
			<Webinar />
			<Content />
			<Video />
			<Footer />


		</div>
	);
}
const mapStateToProps = state => {
	return {
		projects: state.projects,
		notification: state.notification
	}
}
export default connect(mapStateToProps)(Dashboard);