import React from "react";
import Menu from "../../Includes/Menu/Menu";
import store from "../../../Store/store";
import Footer from "../../Includes/Footer/Footer";
import Gdpr from "./Gdpr";
import Personal from "./Personal";
import Password from "./Password";
import Notification from "../Notification/Notification";
import {connect} from "react-redux";
function MyAccount(props) {
	return (
		<body>
			<Menu profile={store.getState().auth.user.profile_pic} location={props.location} />
			<section id="acc-sec-1" className="bg-dark">
				<div className="container">
					<Notification type={props.notification.notification.type} message={props.notification.notification.message}/>
					<Gdpr />
					<Personal />
					<Password />
				</div>
			</section>
			<Footer />
		</body>
	)
}
const mapStateToProps = state => {
	return {
		notification:state.notification
	}
}
export default connect(mapStateToProps)(MyAccount);