import React, { Component, useState } from "react";
import Zoom from "./Zoom/Zoom";
import Ruler from "./Ruler/Ruler";
import Tracks from "./Tracks/Tracks";
import './style.css';
import store from "../../../../../Store/store";
import { add_new_track, needlePointUpdate, timeline_settings } from "../../../../../Store/actions/timelineActions";
import { useDrop } from "react-dnd";
import Needle from "./Needle/Needle";
import ItemFilter from "../../../../../Global/ItemFilter";
const padNumber = (number, size) => {
    let s = String(number);
    while (s.length < (size || 2)) { s = "0" + s }
    return s;
};
const createRulerChunks = (maxLength, zoomValue, chunkLength) => {
    const list = [];
    let second = 0;
    while (second <= maxLength) {
        const currentMinute = Math.floor(second / 60);
        const currentSecond = (second - (currentMinute * 60));
        list.push(`${padNumber(currentMinute, 2)}:${padNumber(currentSecond, 2)}`);
        second = second + chunkLength;
    }
    return list;
}


function Timeline(props) {
    /**
     *  Add new Track in a completely new layer Audio Track
     **/
    const markAsDone = (item) => {
        const data = ItemFilter(item); // Item filters to return the final object
        store.dispatch(add_new_track(data))
    }

    const [trackIsOver, dropTrack] = useDrop({
        accept: 'TRACK',
        drop: (item, monitor) => markAsDone(item),
        collect: monitor => ({
            isOver: !!monitor.isOver(),
            monitor: monitor
        })
    })

    const [timelineComponent, setTimelineComponent] = useState({
        zoomValue: store.getState().timeline.zoomValue,
        chunkLength: store.getState().timeline.measureStick,
        maxLength: 3600,
        ruler: createRulerChunks(3600, store.getState().timeline.zoomValue, store.getState().timeline.measureStick)
    });

    const onZoomChangeHandler = (e) => {
        let newChunkLength = 10 - Math.floor((e.target.value / 100) * 10);
        if (newChunkLength === 0)
            newChunkLength = 1;
        let newTimeline = null;
        const difference = Math.abs(newChunkLength - this.state.chunkLength);
        if (difference >= 1) {
            newTimeline = {
                ...timelineComponent,
                zoomValue: e.target.value,
                chunkLength: newChunkLength,
                ruler: createRulerChunks(timelineComponent.maxLength, e.target.value, newChunkLength)
            };
        }
        else {
            newTimeline = {
                ...timelineComponent,
                zoomValue: e.target.value,
            };
        }
        // Calculate measure stick
        const data = {
            zoomValue: e.target.value,
            measureStick: newChunkLength
        }
        store.dispatch(timeline_settings(data));
        setTimelineComponent(newTimeline);
    }


    const ruler = timelineComponent.ruler.map((element, index) => <Ruler key={index} time={element} uniqueKeys={index} left={60 * index + 8} />)
    return (
        <div id="timeline-row" className="row mx-auto">
            <div className="timeline-wrapper w-100">
                <div className="timeline-body">
                    <div className="timeline-component-main">
                        {/*<div className="timeline-component-track_wrapper" ref={drop} style={isOver.isOver ? { opacity: '0.2' } : { opacity: '1' }}>*/}
                        <div className="timeline-component-track_wrapper">
                            <div className="timeline-header d-flex w-100 ">
                                <div className="timeline-component-zoom">
                                    <Zoom change={onZoomChangeHandler} zoomValue={timelineComponent.zoomValue} />
                                </div>
                                <div className="timeline-component-ruler">
                                    {ruler}
                                    {/*<Needle />*/}
                                </div>
                            </div>
                            <Tracks tracks={props.timeline.tracks} click={props.click} />
                            <div className={`timeline-component-track ${trackIsOver.isOver && 'new-track'}`} ref={dropTrack} >
                                <div className="timeline-track">
                                </div>
                                <div className="timeline-track-layer track-layer-1">
                                    <span className="text-white w-100 text-center">Drop to create a new Track</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Timeline