import Toast from "react-bootstrap/Toast";
import React, {useEffect, useState} from "react";
import store from "../../../Store/store";
import {hideNotification} from "../../../Store/actions/notificationAction";
function Notification(props) {
    const [show,setShow] = useState(false);
    useEffect(() => {
        if(props.type && props.message)
        {
            setShow(true);
        }
    },[props])

    const handleClose = () => {
        setShow(false);
        store.dispatch(hideNotification());
    }
    return(
        <Toast id={`toast-${props.type}`}
               show={show}
               delay={3000}
               onClose={handleClose}
               autohide
               className="toast-container toast toast-top-center"
        >
            <Toast.Body className={`toast-${props.type}`}>
                <div className="toast-message">{props.message}</div>
            </Toast.Body>
        </Toast>
    )
}
export default Notification;