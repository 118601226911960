import {applyMiddleware, createStore, compose } from "redux";
import rootReducers from "./reducers";
import thunk from "redux-thunk";

//const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducers, /* preloadedState, */ composeEnhancers(
    applyMiddleware(thunk)
))

export default store;