import React, {useState} from "react";
import AudioTrack from "./AudioTrack";
function CurrentAudio(props) {
    const [audio, setAudio] = useState({
        title:'',
        url:'',
        showAudio:false
    });
    return(
        <div className="card">
            <div id="cur-audio" className="card-header">
                <button className="label w-100 btn btn-link text-white p-0 collapsed" type="button" data-toggle="collapse" data-target="#cur-audio-collapse" aria-expanded="false" aria-controls="cur-audio-collapse">
                    Current Audio
                </button>
            </div>
            <div id="cur-audio-collapse" className=" collapse" aria-labelledby="cur-audio" data-parent="#audio-pick">
                {/*<div className=" card-body mt-3 p-0">*/}
                {/*    <div className=" btn-wrapper d-flex">*/}
                {/*        <button id=" preview" className=" btn btn-2 mr-1">Preview</button>*/}
                {/*        <button id=" save-use" className=" btn btn-2">Save & Use</button>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className=" card-body mt-3 p-0">
                    {audio.showAudio ? <AudioTrack key="current-audio" index="current-audio" audio={audio}/> : <p className="text-white">No current audio available</p>}
                </div>
            </div>
        </div>
    )
}

export default CurrentAudio;