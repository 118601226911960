import HoverVideoPlayer from "react-hover-video-player";
import React from "react";
import store from "../../../../../../../Store/store";
import {updateCanvasBackgroundProperties} from "../../../../../../../Store/actions/timelineActions";
import {useDrag} from "react-dnd";

function VideoLoad(props) {
    const [isDragging,drag] = useDrag({
        item:{
            type:'TRACK',
            trackType:'graphic',
            graphicType:'video',
            thumbnail:props.result.thumbnail,
            url:props.result.url,
            duration:5
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        })
    });

    const handleClick = (url, thumbnail) => {
        if (props.layer !== false) {
            const selectedLayer = props.layer;
            const trackID = props.trackID;
            const properties = {
                type: 'video',
                value: url,
                thumbnail: thumbnail
            }
            store.dispatch(updateCanvasBackgroundProperties(trackID, selectedLayer.id, properties));
        }
    }
    return(
        <a className="add-img" key={props.index} onClick={() => handleClick(props.result.url, props.result.thumbnail)} style={isDragging.isDragging ? {opacity:'0.2'} : {opacity:'1'}} ref={drag}>
            <HoverVideoPlayer
                videoSrc={props.result.url}
                pausedOverlay={
                    <img src={props.result.thumbnail} className="img-fluid" alt="" />
                }
                loadingOverlay={
                    <div className="spinner-grow" style={{ color: '#6b33d3' }}> </div>
                }
            />
            <span className="badge vid-time">8.2s</span>
        </a>
    )
}
export default VideoLoad;