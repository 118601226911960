import React, { useState } from 'react'
import close from '../../assets/images/modal-close.png'
import logo from '../../assets/images/LOGO.png'

const UpgradeCourseReelAl = () => {
    const [show, setShow] = useState(true)
    
    const handleUpdate = () => {
        window.open("https://coursereel.io/sales/", "_blank")
    }
    
    return (

        <div className='container'>
            <div className='row'>
                <div className='col-12'>
                    <div className='Upgrade-CourseReelAl'>

                        <div id="myModal" className={`modal fade ${show ? 'show' : ''} `}>
                            <div className="modal-dialog">
                                <div className="modal-content CourseReelAl-bg">
                                    <div className="modal-header">
                                        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={() => setShow(false)}>
                                            <img src={close} />
                                        </button>

                                    </div>
                                    <div className="modal-body">
                                        <div className="text-center">
                                            <div className="upgrade-logo  mb-2">
                                                <img src={logo} className="img-fluid" style={{ width: '400px' }} />
                                            </div>

                                            <h1> Upgrade To CourseReelAl 2022</h1>
                                            <p className="mt-3">Special Discount Using Code “coursereellegacy”</p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className="modal-footer mb-5 ">
                                                <div className="text-center ">
                                                    <button type="button" className="btn btn-success" onClick={handleUpdate}>

                                                        Upgrade Here
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default UpgradeCourseReelAl 