import {UPLOAD_RESET, UPLOADED, UPLOADING} from "../actions";

const initialState = {
    uploadedPercentage:0,
    transcribing:false,
    isUploaded:false,
    isUploading:false,
    fileName:false,
    type:''
}

export  default function (state = initialState, action) {
    switch (action.type) {
        case UPLOADING:
            return{
                ...state,
                uploadedPercentage:action.payload.per,
                isUploading:true,
                type: action.payload.type
            }
        case UPLOADED:
            return{
                ...state,
                uploadedPercentage:100,
                isUploaded:true,
                isUploading:false,
                fileName:action.payload.file,
                type:action.payload.type
            }
        case UPLOAD_RESET:
            return{
                uploadedPercentage:0,
                transcribing:false,
                isUploaded:false,
                isUploading:false,
                fileName:false,
                type:''
            }
        default:
            return state;
    }
}