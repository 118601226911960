import React, {useEffect, useRef, useState} from "react";
import {ReactComponent as Script} from "../../../assets/images/ico-script.svg";
import {ReactComponent as Slides} from "../../../assets/images/ico-slides.svg";
import {ReactComponent as BrandingIcon} from "../../../assets/images/ico-branding.svg";
import {ReactComponent as ProjectIcon} from "../../../assets/images/ico-tiles.svg";
import {NavLink, withRouter} from "react-router-dom";
import store from "../../../../Store/store";
import {project_save} from "../../../../Store/actions/projectAction";
import {connect} from "react-redux";
let clickType = false;
function SideBar(props) {
	const [isLoading, setIsLoading] = useState(false);
	if(props.notification.showNotification === true && clickType === true)
	{
		setIsLoading(false);
		setTimeout(() => {
			props.history.push('/projects'); // Redirect to the Project Page after Rendering
		},3000)
		clickType = false;
	}
	const handleRender = () => {
		setIsLoading(true);
		clickType = true;
		store.dispatch(project_save('render'));
	}

    return(
		<div id="editor-nav" className="nav flex-column nav-pills text-center" role="tablist"
			 aria-orientation="vertical">
			<NavLink className="nav-link v-tab-link mb-2" to="/projects">
				<span className="icon d-block mb-2">
					<svg width="21px" height="21px" viewBox="0 0 21 21">
						<ProjectIcon />
					</svg>
				</span>
				Projects
			</NavLink>
			<a id="script-tab" className={`nav-link v-tab-link ${props.type === 'create' ? 'active' : ''} mb-2`} data-toggle="pill"
			   href="#script-tab-cont"
			   role="tab" aria-controls="script-tab-cont" aria-selected="false">
						<span className="icon d-block mb-2">
							<svg width="19px" height="24px" viewBox="0 0 19 24">
								<Script />
							</svg>
						</span>
				Script
			</a>
			{props.type === 'save' ?
				<>
					<a id="editor-tab" className={`nav-link v-tab-link ${props.type === 'save' ? 'active' : ''} mb-2`} data-toggle="pill" href="#editor-tab-cont"
					   role="tab"
					   aria-controls="editor-tab-cont" aria-selected="false">
						<span className="icon d-block mb-2">
							<svg width="24px" height="24px" viewBox="0 0 24 24">
								<Slides />
							</svg>
						</span>
						Editor
					</a>
					<a id="branding-tab" className="nav-link v-tab-link mb-2" data-toggle="pill" href="#branding-tab-cont"
					   role="tab" aria-controls="branding-tab-cont" aria-selected="false" onClick={handleRender}>
							<span className="icon d-block mb-2">
								<svg width="24px" height="21px" viewBox="0 0 24 21">
									<BrandingIcon />
								</svg>
							</span>
						Render
						{isLoading ? <div className="spinner-grow" style={{ color: '#6b33d3' }}> </div> : null}
					</a>
				</> : null
			}

		</div>
    );
}
const mapStateToProps = state => {
	return {
		notification:state.notification
	}
}
export default withRouter(connect(mapStateToProps)(SideBar));