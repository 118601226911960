import { Resizable } from "react-resizable";
import React, { useEffect, useRef, useState } from "react";
import './Resizable.css';
import Draggable from 'react-draggable';
import store from "../../../../../../../Store/store";
import { active_item_duration, active_item_start_time } from "../../../../../../../Store/actions/timelineActions";
import { ReactComponent as TransitionIcon } from "../../../../../../assets/images/transition.svg";
import { ReactComponent as DragIcon } from "../../../../../../assets/images/drag-ico.svg";
import { useDrag } from "react-dnd";

const WaveSurferTimeline = require('wavesurfer.js');
const formWaveSurferOptions = (ref) => ({
    container: ref,
    waveColor: "#229051",
    cursorColor: "#03a9f4",
    progressColor: "#03a9f4",
    barWidth: 1,
    height: 55,
    barRadius: 1,
    responsive: true,
    fillParent: true,
    scrollParent: true,
    autoCenter: true
});
function Track(props) {
    const element = props.element;
    const measureStick = store.getState().timeline.measureStick;
    const waveformRefTimeline = useRef();
    const waveSurferT = useRef(null);
    const [waveState, setWaveState] = useState({
        isLoaded: false
    });

    const [isDragging, drag] = useDrag({
        item: {
            type: 'TRACK',
            trackType: element.type,
            id: element.id,
            layer: element
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        })
    });

    useEffect(() => {
        if (element.type === 'audio') {
            const options = formWaveSurferOptions(waveformRefTimeline.current);
            waveSurferT.current = WaveSurferTimeline.create(options);
            waveSurferT.current.load(element.source.url);
            console.log('fired AUdio');
            waveSurferT.current.on("ready", function () {
                console.log('Audio is ready');
                setWaveState({
                    ...waveState,
                    isLoaded: true
                })
            });
        }
    }, [element.id])

    const [dimension, setDimension] = useState({ width: props.element.duration * 60, height: 62 })
    const handleResize1 = (event, { element, size, handle }) => {
        setDimension({ width: size.width, height: size.height });
    }
    const handleResize = (event, { element, size, handle }) => {
        const trackIDNode = event.target.parentNode.parentNode.parentNode;
        if (trackIDNode.classList.contains('timeline-component-track')) {
            const trackID = trackIDNode.id;
            const trackIDValue = trackID.replace('track_id_', '');
            console.log(size);
            store.dispatch(active_item_duration(trackIDValue, event.target.id, Math.round(size.width / 60)))
            setDimension({ width: Math.round(size.width / 60) * 60, height: size.height });
        }

    };


    const handleDrag = (e, data) => {
        const startTime = Math.round(data.x);
        const trackIDNode = data.node.parentNode.parentNode;
        console.log(startTime, trackIDNode);
        if (trackIDNode.classList.contains('timeline-component-track')) {
            const trackID = trackIDNode.id;
            const trackIDValue = trackID.replace('track_id_', '');
            store.dispatch(active_item_start_time(trackIDValue, data.node.id, startTime))
        }
    }

    const style = {
        width: dimension.width + 'px',
        height: dimension.height + 'px',
        background: (element.source.type === 'video' || element.source.type === 'image') ? 'url(' + element.source.thumbnail + ') repeat-x center center / contain' : element.source.color,
    }
    return (
        <>
            <Draggable
                axis="x"
                handle=".slide-tile"
                defaultPosition={{ x: 0, y: 0 }}
                position={{ x: Math.round(element.startTime), y: 0 }}
                bounds=".timeline-track-layer"
                onStop={handleDrag}

            >

                {element.type === 'graphic' ? (
                    <Resizable
                        className="box"
                        axis='x'
                        width={dimension.width}
                        height={dimension.height}
                        onResizeStop={handleResize}
                        onResize={handleResize1}
                        minConstraints={[60, 60]}
                    >
                        <div id={element.id} className={element.isSelected ? "timeline-slide selected" : "timeline-slide"} key={element.id} onClick={props.click} >
                            <div className="slide-tile graphic" style={style}>
                                <div className="icon-wrapper">
                                    <div className="timeline-drag" ref={drag}>
                                        <DragIcon />
                                    </div>
                                    <div className="timeline-transition">
                                        <svg className="d-block mx-auto" width="10px" height="10px" viewBox="0 0 10 10">
                                            <TransitionIcon />
                                        </svg>
                                    </div>
                                </div>
                                {/*<span className="slide-title">{element.sentence}</span>*/}
                            </div>
                        </div>
                    </Resizable>
                ) : (
                        <div id={element.id} className={element.isSelected ? "timeline-audio-track selected" : "timeline-audio-track"} key={element.id} onClick={props.click}>
                            <div className="slide-tile audio" style={{ width: dimension.width + 'px', height: dimension.height + 'px' }}>
                                <div className="timeline-drag" ref={drag}>
                                    <DragIcon />
                                </div>
                                <div id={"timeline-spectrum" + element.id} ref={waveformRefTimeline}> </div>
                                {waveState.isLoaded ? null : <div className="spinner-grow" style={{ color: '#6b33d3' }}> </div>}
                            </div>
                        </div>
                    )}

            </Draggable>

        </>
    )
}
export default Track;