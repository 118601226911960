import {HIDE_NOTIFICATION, SHOW_NOTIFICATION} from "./index";

export const displayNotification = (type,message) =>  (dispatch,getState)  =>{
    const payload = {
        type:type,
        message:message,
        autoHide:{
            isAutoHide: true,
            hideTime:3000
        }
    }
    dispatch({type:SHOW_NOTIFICATION,payload:payload});
}
export const hideNotification = () =>  (dispatch)  =>{
    const payload = {
        type:null,
        message:false,
        autoHide:{}
    }
    dispatch({type:HIDE_NOTIFICATION,payload:payload});
}