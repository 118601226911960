import React, {useState} from "react";
import Notification from "../Notification/Notification";
import store from "../../../Store/store";
import axios from "axios";
import {baseURL} from "../../../Global";
import {displayNotification} from "../../../Store/actions/notificationAction";
import {connect} from "react-redux";
function ClientAccount(props) {
    const [teamMember, setTeamMember] = useState({
        name:false,
        email:false,
        password:false,
        confirm_password:false,
        buttonText:'Create'
    });
    const [results, setResults] = useState([]);
    const config = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+store.getState().auth.token
    }
    const fetchTeamAccounts = () => {
        const data1 = {
            user_id:store.getState().auth.user.user_id
        }
        axios.post(baseURL+'fetch-client-team-account',data1,{headers:config}).then(res => {
            if(res.data.status === true)
            {
                setResults(res.data.data.team_members);
            }
        }).catch(res => {})
    }

    const handleChange = (e) => {
        setTeamMember({
            ...teamMember,
            [e.target.name]:e.target.value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if(teamMember.password === teamMember.confirm_password)
        {
            setTeamMember({
                ...teamMember,
                buttonText: 'Creating...'
            });

            const data = {
                user_id:store.getState().auth.user.user_id,
                name:teamMember.name,
                email:teamMember.email,
                password:teamMember.password
            };


            axios.post(baseURL+'create-team-account',data,{headers:config}).then(res => {
                if(res.data.status === true)
                {
                    store.dispatch(displayNotification('success',res.data.message))
                    fetchTeamAccounts();
                }
                else
                {
                    store.dispatch(displayNotification('error',res.data.message))
                }
                setTeamMember({
                    ...teamMember,
                    buttonText: 'Create'
                });

            }).catch(error => {})
        }
        else
        {
            store.dispatch(displayNotification('error','Passwords do not match'))
        }

    }
    return (
        <>
            <div className="row row-3">
                <Notification type={props.notification.notification.type} message={props.notification.notification.message}/>
                <div className="col col-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="text-white">Connect your Amazon account</h4>
                        </div>
                        <div className="card-body">
                            <div className="col col-12">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col col-lg-6 col-12 px-2">
                                            <div className="form-group">
                                                <input className="form-control input-dark" type="text" name="name" onChange={handleChange} required />
                                                <label>Name</label>
                                            </div>
                                        </div>
                                        <div className="col col-lg-6 col-12 px-2">
                                            <div className="form-group">
                                                <input className="form-control input-dark" type="email" name="email"  onChange={handleChange} required />
                                                <label>Email</label>
                                            </div>
                                        </div>
                                        <div className="col col-lg-6 col-12 px-2">
                                            <div className="form-group">
                                                <input className="form-control input-dark" type="text" name="password" onChange={handleChange} required />
                                                <label>Password</label>
                                            </div>
                                        </div>
                                        <div className="col col-lg-6 col-12 px-2">
                                            <div className="form-group">
                                                <input className="form-control input-dark" type="text" name="confirm_password" onChange={handleChange} required />
                                                <label>Confirm Password</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col col-md-12">
                                            <button className="btn btn-3 update-pass pull-right">{teamMember.buttonText}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <div className="row row-3">
                <div className="col col-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="text-white">Your Team Members </h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col col-lg-12 col-12 px-2">
                                    {results.length > 0 ?
                                        <div className="table-responsive">
                                            <table className="table text-white">
                                                <thead>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Created</th>
                                                <th>Action</th>
                                                </thead>
                                                <tbody>
                                                {results.map(result => <tr>
                                                    <td>{result.name}</td>
                                                    <td>{result.email}</td>
                                                    <td>{result.created}</td>
                                                    <td><button className="btn btn-3 update-pass"><i className="fa fa-trash-o"> </i></button></td>
                                                </tr> )
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                        : <p className="text-white text-center">No Team Member Created</p>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = state => {
    return {
        notification:state.notification
    }
}
export default connect(mapStateToProps)(ClientAccount);