import React, {useEffect} from "react";
import Background from "./Background/Background";
import TextCustomization from "./TextCustomization/TextCustomization";
import ImageLibrary from "./ImageLibrary/ImageLibrary";
import Audio from "./Audio/Audio";
import Motion from "./Motion/Motion";
import Transition from "./Transition/Transition";
import LazyLoad from 'react-lazyload';
function Left(props) {
    return(
        <div id="inner-tab-cont" className="tab-content">
            <div id="text" className="tab-pane fade show active" role="tabpanel" aria-labelledby="text-tab">
                <TextCustomization />
            </div>
            <div id="bg" className="tab-pane fade" role="tabpanel" aria-labelledby="bg-tab">
                <Background laoder={props.loader} layer={props.layer} trackID={props.trackID}/>
            </div>
            <div id="media" className="tab-pane fade" role="tabpanel" aria-labelledby="media-tab">
                <ImageLibrary layer={props.layer} trackID={props.trackID} loader={props.loader}/>
            </div>
            <div id="audio" className="tab-pane fade h-100" role="tabpanel" aria-labelledby="audio-tab">
                <LazyLoad>
                    <Audio />
                </LazyLoad>
            </div>
            <div id="motion" className="tab-pane fade" role="tabpanel" aria-labelledby="motion-tab">
                <Motion />
            </div>
            <div id="transition" className="tab-pane fade" role="tabpanel" aria-labelledby="transition-tab">
                <LazyLoad>
                    <Transition />
                </LazyLoad>
            </div>
        </div>
    )
}
export default Left