import React, {useRef, useState} from "react";
import Image from "./Image";
import Video from "./Video";
import store from "../../../../../../Store/store";
import axios from "axios";
import {baseURL} from "../../../../../../Global";
import UploadProgress from "../../../../Dashboard/Upload/UploadProgress/UploadProgress";
function ImageLibrary(props) {
    const [fileDetails,setFileDetails] = useState({
        maxSize:200, // size is in MB
        fileFormat:{video:[".mp4",".mp3"],images:['.png','.jpg','.jpeg']},
        isUploaded:false,
        isUploading:false,
        uploadedPercentage:0,
        file:false
    })

    const handleChange = (type,e) => {
        const fileSize = e.target.files[0].size/(1024*1024);
        const fileName = e.target.files[0].name;
        let  allowedFiles = false;
        if(fileSize < fileDetails.maxSize)
        {
            const allowedFiles = type === 'video' ? fileDetails.fileFormat.video.map(fileType => fileType) : fileDetails.fileFormat.images.map(fileType => fileType);
            const regex = new RegExp("([a-zA-Z0-9\s_\\.\-:])+(" + allowedFiles.join('|') + ")$")
            if(regex.test(fileName.toLowerCase()))
            {
                // Send file through Axios
                let data = new FormData();
                data.append('file',e.target.files[0]);
                data.append('file_type',type);
                data.append('upload_type',type);
                data.append('user_id',store.getState().auth.user.user_id);
                setFileDetails({
                    ...fileDetails,
                    isUploading:true,
                });

                // start uploading
                const options = {
                    headers:{
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer '+store.getState().auth.token,
                    },
                    onUploadProgress: (progressEvent) => {
                        const {loaded, total} = progressEvent;
                        let percentage = Math.floor((loaded*100) / total);
                        if(percentage < 99)
                        {
                            setFileDetails({
                                ...fileDetails,
                                uploadedPercentage:percentage
                            });
                        }
                    }
                }
                axios.post(baseURL+'file_upload',data, options)
                    .then(res => {
                        if(res.data.status === true)
                        {
                            setFileDetails({
                                ...fileDetails,
                                uploadedPercentage:100,
                                openProgressPopup:false,
                                isUploading:false,
                                isUploaded:true,
                                file:res.data.message
                            });
                        }
                        else
                            alert('Error');
                    })

            }
            else
            {
                // file format is invalid
                alert('file format is invalid');
            }
        }
        else
        {
            // File size is greater
            alert('File size is Greater');
        }
    }
    return(
        <>
            <ul id="media-tabs" className="nav nav-tabs border-0 justify-content-center mb-4" role="tablist">
                <li className="nav-item">
                    <a id="images-tab" className="nav-link label active" data-toggle="tab" href="#images" role="tab"
                       aria-controls="images" aria-selected="true">Images</a>
                </li>
                <li className="nav-item">
                    <a id="vid-clips-tab" className="nav-link label" data-toggle="tab" href="#vid-clips" role="tab"
                       aria-controls="vid-clips" aria-selected="false">Video Clips</a>
                </li>
            </ul>
            <div id="media-tab-cont" className="tab-content">
                {fileDetails.isUploading ? <UploadProgress show={true} percentage={fileDetails.uploadedPercentage}/> : null}
                {fileDetails.isUploaded ? <Image file={fileDetails.file} change={(e) => handleChange('images',e)}/> : <Image change={(e) => handleChange('images',e)}/> }
                {fileDetails.isUploaded ? <Video loader={props.loader} file={fileDetails.file} change={(e) => handleChange('video',e)}/> : <Video loader={props.loader} change={(e) => handleChange('video',e)} /> }

            </div>
        </>
    );
}
export default ImageLibrary;