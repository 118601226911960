import React, { useEffect, useRef, useState } from "react";
import { update_project, update_transcribe } from "../../../../Store/actions/timelineActions";
import store from "../../../../Store/store";
import { NavLink } from "react-router-dom";
function Script(props) {
	const videoRef = useRef();
	const [projectDetails, setProjectDetails] = useState({
		name: '',
		script: '',
		videoUrl: false,
		fileName: false
	})
	const handleClick = () => {
		videoRef.play();
	}
	const handleChange = (e) => {
		setProjectDetails({
			...projectDetails,
			[e.target.name]: e.target.value
		})
	}
	const handleSubmit = () => {
		const newTranscribe = {
			...store.getState().timeline.transcribe,
			transcript: projectDetails.script
		}
		store.dispatch(update_transcribe(newTranscribe));
	}
	const handleKeyUp = (e) => {
		if (e.ctrlKey && e.keyCode === 13) {
			const [start, end] = [props.scriptRef.current.selectionStart, props.scriptRef.current.selectionEnd];
			props.scriptRef.current.setRangeText(' ¶ ', start, end, 'end');
		}
	}
	useEffect(() => {
		if (props.transcribe.transcript) {
			const fileName = props.transcribe.remote_file ? props.transcribe.remote_file.replace(/^.*[\\\/]/, '') : false;
			const videoUrl = props.transcribe.remote_file ? props.transcribe.remote_file : false;

			setProjectDetails({
				...projectDetails,
				name: props.project.name,
				script: props.transcribe.transcript,
				fileName: fileName,
				videoUrl: videoUrl
			})
		}
	}, [props.project])



	return (
		<div id="script-tab-cont" className={`tab-pane fade ${props.type === 'create' ? 'show active' : ''} `} role="tabpanel" aria-labelledby="script-tab">
			<div className="inner-row d-flex flex-row">
				<div className="col inner-col-1 col-lg-8 col-12">
					<div className="form-wrapper w-100 mx-auto">
						<form id="script-form" method="POST" onSubmit={handleSubmit}>
							<div className="input-group mb-3">
								<input id="vid-name" className="form-control input-dark" type="text" name="name"
									value={projectDetails.name}
									onChange={handleChange}
									ref={props.nameRef}
									required />
								<label htmlFor="proj-title">Title (required)</label>
							</div>
							<div className="input-group ">
								<textarea id="vid-script" className="form-control input-dark" name="script"
									onChange={handleChange}
									onKeyUp={handleKeyUp}
										  ref={props.scriptRef}
									value={projectDetails.script}
								>
								</textarea>
								<label htmlFor="proj-desc">Add your script</label>
							</div>
							<div id="toast-success" className="toast-container toast toast-top-center" role="alert"
								aria-live="assertive"
								aria-atomic="true" className="toast" data-autohide="true" data-animation="true"
								data-delay="3000">
								<div className="toast-body toast-success">
									<div className="toast-message">Your app has been connected successfully</div>
								</div>
							</div>
						</form>
					</div>
				</div>
				<div className={`col inner-col-2 col-lg-4 col-12 ${projectDetails.fileName ? '' : 'd-none'}`}>
					<div className="card">
						<div className="card-header p-0">
							<div className="vid-wrapper">
								{projectDetails.videoUrl && projectDetails.videoUrl !== 'NA' ? <video id="proj-player" className="d-block w-100" playsInline controls ref={videoRef} onClick={handleClick}>
									<source src={projectDetails.videoUrl} type="video/mp4" onClick={handleClick} />
								</video> : null}
							</div>
						</div>
						<div className="card-body pt-2 px-3 pb-3">
							<div className="details-wrapper pt-3">
								<div className="vid-link mb-3">
									<label className="d-block">Video Link</label>
									{projectDetails.videoUrl ? <NavLink className="link" to={projectDetails.videoUrl} target="_blank"
										style={{ color: '#d75348' }}>{projectDetails.videoUrl}
									</NavLink> : null}
								</div>
								<div className="vid-title">
									<label className="d-block">File Name</label>
									<p className="f-name text-white">{projectDetails.fileName}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Script