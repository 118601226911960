import React from "react";
import {ReactComponent as ZoomIn} from "../../../../../assets/images/zoom-in.svg";
import {ReactComponent as ZoomOut} from "../../../../../assets/images/zoom-out.svg";

function Zoom(props) {
    return(
        <div className="input-group align-items-center justify-content-center">
            <div className="input-group-prepend">
                <svg width="8px" height="8px" viewBox="0 0 8 8">
                    <ZoomIn />
                </svg>
            </div>
            <input id="zoom" className="form-control-range" type="range" name="zoomIn-zoomOut" step="1"
                   min="0"
                   max="100"
                   value={props.zoomValue}
                   onChange={props.change}
            />
            <div className="input-group-append">
                <svg width="8px" height="8px" viewBox="0 0 8 8">
                    <ZoomOut />
                </svg>
            </div>
        </div>
    )
}
export default Zoom;