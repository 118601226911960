
import {ReactComponent as ImageSearchIcon} from "../../../../../assets/images/search.svg";
import AudioTrack from "./AudioTrack";
import React, {useEffect, useState} from "react";
import audioLibrary from "../../../../../../Global/audioLibrary";

function BackgroundMusic(props) {
    const data = '?page=1';
    const [results, setResults] = useState({
        isLoading:true,
        results:[]
    })
    useEffect(() => {
        audioLibrary(data).then((res) => {
            setResults({
                isLoading:false,
                results:res
            })
        }).catch((error) => console.log('Error'));
    },[]);
    return(
        <div className="card">
            <div id="bg-music" className="card-header">
                <button className="label w-100 btn btn-link text-white p-0" type="button"
                        data-toggle="collapse"
                        data-target="#bg-music-collapse" aria-expanded="true"
                        aria-controls="bg-music-collapse">
                    Background Music
                </button>
            </div>
            <div id="bg-music-collapse" className="collapse show" aria-labelledby="bg-music"
                 data-parent="#audio-pick">
                <div className="card-body mt-3 p-0">
                    <div className="input-group mb-3">
                        <input id="search-audio" type="search" name="search-audio"
                               className="search-audio-media form-control input-dark" placeholder="Search" />
                        <div className="input-group-prepend v-center">
                            <svg width="16px" height="16px" viewBox=" 0 0 16 16">
                                <ImageSearchIcon />
                            </svg>
                        </div>
                    </div>
                    <div className="results-wrapper d-flex flex-wrap">
                        {results.isLoading ? <div className="spinner-grow" style={{color:'#6b33d3'}}> </div> : results.results.map((audio,number) => <AudioTrack key={number} index={number} audio={audio}/>)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BackgroundMusic;