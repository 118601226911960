import React from "react"
import Menu from "../../Includes/Menu/Menu";
import store from "../../../Store/store";
import SupportHeader from "../Support/SupportHeader";
import Footer from "../../Includes/Footer/Footer";
import AcadeablePDF from "./AcadeablePDF";
import AcadeableTraining from "./AcadeableTraining";
function Acadeable(props){
    return(
        <body>
        <header id="app-header" className="position-fixed w-100">
            <Menu profile={store.getState().auth.user.profile_pic} location={props.location}/>
        </header>
        <div id="rep-content">
            <SupportHeader />
            <AcadeablePDF />
            <AcadeableTraining />
        </div>
        <Footer/>
        </body>
    )
}
export default Acadeable;