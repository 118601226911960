import React, {useEffect, useState} from "react";
import {ReactComponent as DownArrowIcon} from "../../assets/images/down-arrow.svg";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import store from "../../../Store/store";
import axios from "axios";
import {baseURL} from "../../../Global";
import {displayNotification} from "../../../Store/actions/notificationAction";
import liveReel from "../../assets/images/logo-livereel.png";
import scriptreel from "../../assets/images/logo-scriptreel.png";
import syvideo  from "../../assets/images/logo-syvideo.png";
import close from "../../assets/images/close.svg";
import facbooklogo from "../../assets/images/logo-facebook.png"
import youtubelogo from "../../assets/images/logo-youtube.png"

import Trimlogo from "../../assets/images/logo-trim.png"

function PublishModal(props) {
    const [accounts, setAccounts] = useState({
        sa: {
            facebook:[],
            youtube:[],
            trimreel:[],
            syvid:[],
            livereel:[],
            scriptreel:[]
        },
        active:false,
        activeAccounts:[]
    });

    const [selectedList, setSelectedList] = useState({
        id:false,
        name:false,
        title:false,
        description:false,
        url:props.url,
        buttonText:'Publish'
    })

    useEffect(() => {
        let active = false;
        let activeAccounts = [];

        for (const [key, value] of Object.entries(props.social))
        {
            if(props.social[key].length > 0)
            {
                active = key;
                activeAccounts = props.social[key];
                break;
            }
        }

        setAccounts({
            sa: {
                facebook:props.social.facebook,
                youtube:props.social.youtube,
                trimreel:props.social.trimreel,
                syvid:props.social.syvid,
                livereel:props.social.livereel,
                scriptreel:props.social.scriptreel
            },
            active:active,
            activeAccounts:activeAccounts
        })
        return () => {
        };
    }, [props.social]);

    const handleClick = (type) => {
        setAccounts({
            ...accounts,
            active:type,
            activeAccounts:accounts.sa[type]
        })
        setSelectedList({
            ...selectedList,
            id:false,
            name:false
        })
    }

    const handleListClick = (id,name) => {
        setSelectedList({
            ...selectedList,
            id:id,
            name:name
        })
    }

    const handleChange = (e) => {
        setSelectedList({
            ...selectedList,
            [e.target.name]:e.target.value
        })
    }

    const config = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+store.getState().auth.token
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setSelectedList({
            ...selectedList,
            buttonText: 'Publishing...'
        })

        // send to publish the video in Facebook & Youtube
        if(accounts.active === 'facebook' || accounts.active === 'youtube')
        {
            const data = {
                user_id:store.getState().auth.user.user_id,
                title:selectedList.title,
                description:selectedList.description,
                video_url:selectedList.url,
                network_id:selectedList.id,
                network:accounts.active
            };
            axios.post(baseURL+'upload-to-social',data,{headers:config}).then(res => {
                if(res.data.status === true)
                {
                    store.dispatch(displayNotification('success',res.data.message));
                    props.close();
                }
                else
                {
                    store.dispatch(displayNotification('error',res.data.message))
                }
                setSelectedList({
                    ...selectedList,
                    buttonText:'Publish'
                })
            }).catch(error => {})
        }
        else if(accounts.active === 'scriptreel' || accounts.active === 'livereel' || accounts.active === 'syvid' || accounts.active === 'trimreel')
        {
            const data = {
                title:selectedList.title,
                description:selectedList.description,
                video_url:selectedList.url,
                integration_id:selectedList.id,
                app_name:accounts.active
            };
            axios.post(baseURL+'push-to-app',data,{headers:config}).then(res => {
                if(res.data.status === true)
                {
                    store.dispatch(displayNotification('success',res.data.message));
                    props.close();
                }
                else
                {
                    store.dispatch(displayNotification('error',res.data.message))
                }
                setSelectedList({
                    ...selectedList,
                    buttonText:'Publish'
                })
            }).catch(error => {})
        }
        else
        {
            console.log('No Social Network');
        }
    }
    return(

        <div id="publish" className="modal fade show" tabIndex="-1" role="dialog" data-backdrop="static"
             aria-labelledby="modal-title"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered mx-auto" role="document">
                <div className="modal-content">
                    <button type="button" onClick={props.close} className="close mb-3 text-right" style={{opacity: '1'}}>
                        <img className="img-fluid" src={close} alt="Close"/>
                    </button>
                    <div className="modal-body bg-white text-center">
                        <h4 className="modal-title mb-4" style={{lineHeight:'1.33',color:'#000000'}}>
                            Publish This Video
                        </h4>
                        <div className="row inner-row icons-wrapper justify-content-center mb-4 mx-auto">

                            { accounts.sa.facebook.length > 0 && <div className="icon-wrapper text-center mr-3">
                                <button onClick={() => handleClick('facebook')} className={`img-wrapper soc-icon mx-auto mb-2 ${accounts.active === 'facebook' ? 'active' : ''}`}>
                                    <img className="img-fluid" src={facbooklogo} alt="Facebook Logo" />
                                </button>
                                <p className="sm-txt" style={{fontWeight:'600',color:'#000000'}}>Facebook</p>
                            </div> }

                            {accounts.sa.youtube.length > 0 && <div className="icon-wrapper text-center ml-3">
                                <button onClick={() => handleClick('youtube')} className={`img-wrapper soc-icon mx-auto mb-2 ${accounts.active === 'youtube' ? 'active' : ''}`}>
                                    <img className="img-fluid" src={youtubelogo} alt="Youtube Logo" />
                                </button>
                                <p className="sm-txt" style={{fontWeight:'600',color:'#000000'}}>Youtube</p>
                            </div>  }


                            {accounts.sa.trimreel.length > 0 && <div className="icon-wrapper text-center ml-3">
                                <button onClick={() => handleClick('trimreel')} className={`img-wrapper soc-icon mx-auto mb-2 ${accounts.active === 'trimreel' ? 'active' : ''}`}>
                                    <img className="img-fluid" src={Trimlogo} alt="TrimReel Logo" />
                                </button>
                                <p className="sm-txt" style={{fontWeight:'600',color:'#000000'}}>TrimReel</p>
                            </div>  }


                            {accounts.sa.livereel.length > 0 && <div className="icon-wrapper text-center ml-3">
                                <button onClick={() => handleClick('livereel')} className={`img-wrapper soc-icon mx-auto mb-2 ${accounts.active === 'livereel' ? 'active' : ''}`}>
                                    <img className="img-fluid" src={liveReel} alt="LiveReel Logo" />
                                </button>
                                <p className="sm-txt" style={{fontWeight:'600',color:'#000000'}}>LiveReel</p>
                            </div>  }


                            {accounts.sa.scriptreel.length > 0 && <div className="icon-wrapper text-center ml-3">
                                <button onClick={() => handleClick('scriptreel')} className={`img-wrapper soc-icon mx-auto mb-2 ${accounts.active === 'scriptreel' ? 'active' : ''}`}>
                                    <img className="img-fluid" src={scriptreel} alt="ScriptReel Logo" />
                                </button>
                                <p className="sm-txt" style={{fontWeight:'600',color:'#000000'}}>ScriptReel</p>
                            </div>  }

                            {accounts.sa.syvid.length > 0 && <div className="icon-wrapper text-center ml-3">
                                <button onClick={() => handleClick('syvid')} className={`img-wrapper soc-icon mx-auto mb-2 ${accounts.active === 'syvid' ? 'active' : ''}`}>
                                    <img className="img-fluid" src={syvideo} alt="Syvid Logo" />
                                </button>
                                <p className="sm-txt" style={{fontWeight:'600',color:'#000000'}}>Syvid</p>
                            </div>  }

                        </div>
                        <div id="vid-details" className="modal-footer border-top-0 p-0" style={{display:'block'}}>
                            <p className="sm-txt m-0 mb-3">Video Details</p>
                            <form id="pub-form" method="POST" className="my-0 mx-auto" style={{maxWidth:'420px'}} onSubmit={handleSubmit}>
                                <label className="form-input d-block w-100 mb-2">
                                    <input id="proj_title" type="text" name="title"
                                           className="input d-block mt-0 w-100 mx-auto"
                                           required onChange={handleChange}/>
                                        <span className="label">Title</span>
                                </label>
                                <label className="form-input d-block w-100 mb-2">
                                    <textarea id="proj_desc" className="input form-control" name="description" onChange={handleChange}
                                              > </textarea>
                                    <span className="label">Description</span>
                                </label>
                                <label className="form-input d-block w-100 mb-4">
                                    <a id="sel-acc" className="btn dropdown-toggle w-100 has-value" data-toggle="dropdown"
                                       href="#"> {selectedList.name}
                                    </a>
                                    <span className="icon">
                                        <svg width="14px" height="14px" viewBox="0 0 14 14">
                                            <DownArrowIcon />
                                        </svg>
								    </span>
                                    <ul id="sel-opts-wrapper" className="dropdown-menu collapse rounded-0 w-100 m-0">
                                        {accounts.activeAccounts.map((element,index) =>
                                            <li key={index} className="d-flex align-items-center" onClick={() => handleListClick(element.id,element.title)}>
                                                {/*<img className="img-fluid" src="" alt="Social Icon" />*/}
                                                <a href="#" className="option-item flex-grow-1">{element.title}</a>
                                            </li>
                                        )}
                                        <li className="d-flex align-items-center">
                                            <NavLink id="add-acc" to="/integrations" className="flex-grow-1">Add Account</NavLink>
                                        </li>
                                    </ul>
                                    <span className="label">Account</span>
                                </label>
                                <button id="pub-proj" type="submit" className="btn btn-3">{selectedList.buttonText}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        social:state.social
    }
}
export default connect(mapStateToProps)(PublishModal);