import React, {useState} from "react";
import GoogleLogin from 'react-google-login';
import store from "../../../../Store/store";
import axios from "axios";
import {baseURL} from "../../../../Global";
import {displayNotification} from "../../../../Store/actions/notificationAction";
import {fetchSocialAccounts} from "../../../../Store/actions/accountActions";
import ConfirmDelete from "../../ConfirmDelete/ConfirmDelete";
import YouTubeSvg from "../../../assets/images/logo-youtube.svg";
import YouTubeNew from "../../../assets/images/youtube_new.png";
import YoutubeGreySvg from "../../../assets/images/logo-youtube-grey.svg"
function Youtube(props) {
    const [confirmDelete, setConfirmDelete] = useState({
        status:false,
        id:0
    });

    const handleDelete = (id) => {
        setConfirmDelete({
            status:true,
            id:id
        })
    }

    const handleConfirmClose = () => {
        setConfirmDelete({
            status:false,
            id:0
        })
    }

    const config = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+store.getState().auth.token
    }

    const handleConfirmDelete = () => {
        const data = {
            user_id:store.getState().auth.user.user_id,
            network_id:confirmDelete.id
        }
        axios.post(baseURL+'delete-social-network',data,{headers:config}).then(res => {
            if(res.data.status === true)
            {
                store.dispatch(displayNotification('success',res.data.message));
                // Fetch the updated Social Accounts
                store.dispatch(fetchSocialAccounts());
            }
            else
            {
                store.dispatch(displayNotification('error',res.data.message));
            }
            handleConfirmClose(); // close the confirm delete popup
        }).catch(error => {console.log(error)})
    }

    const responseGoogle = (response) => {
        console.log('Youtube Data',response);
        if(response.code)
        {
            // send code with user id to the backend to generate the Access and Refresh token
            const data = {
                user_id:store.getState().auth.user.user_id,
                code:response.code
            }
            axios.post(baseURL+'save-youtube',data,{headers:config}).then(res => {
                if(res.data.status === true)
                {
                    store.dispatch(displayNotification('success',res.data.message));
                    // Fetch the updated Social Accounts
                    store.dispatch(fetchSocialAccounts());
                }
                else
                {
                    store.dispatch(displayNotification('error',res.data.message));
                }
            }).catch(error => {console.log(error)})
        }
    }
    return(
        <>
        <div id="yt-account"
             className={`acc-details-wrapper row mx-auto p-4 mb-3 bg-white ${props.accounts.length > 0 ? '' : 'not-connected'}`}>
            <div className="img-wrapper pr-2">
                {
                    props.accounts.length <= 0 ? <img className="img-fluid" src={YoutubeGreySvg} alt="YouTube Logo" /> :
                    <img className="img-fluid" src={YouTubeSvg} alt="YouTube Logo" />
                }
            </div>
            <div className="inner-row-wrapper flex-grow-1">
                {
                    props.accounts.length <= 0 ?
                        <div className="inner-row d-flex flex-row align-items-center">
                            <div className="acc-details flex-grow-1 px-2">
                                <h6 className="mb-1">
                                    <span className="md-txt">YouTube </span>
                                </h6>
                                <p className="status">
                                    Not Connected
                                </p>
                            </div>
                            <div className="btns-wrapper d-flex pl-lg-3">

                                <GoogleLogin
                                    clientId="963174672270-i9uudhi5nl77nbb8c7qtmi6edigv09eq.apps.googleusercontent.com" //  385524880993-kb22h3hvlooun9i0908bsm29bkeafhio.apps.googleusercontent.com
                                    render={renderProps => (
                                        <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="btn btn-2 w-100 mr-2 connect" >Connect</button>
                                    )}
                                    onSuccess={responseGoogle}
                                    onFailure={responseGoogle}
                                    scope="https://www.googleapis.com/auth/youtube"
                                    responseType="code"
                                    accessType="offline"
                                    prompt="consent"
                                    cookiePolicy={'single_host_origin'}
                                />
                            </div>
                        </div> :
                        <>
                            <div className="inner-row d-flex flex-row align-items-center">

                                {props.accounts.map(account =>
                                    <>
                                    <div className="acc-details flex-grow-1 px-2">
                                        <h6 className="mb-1">
                                            <span className="md-txt">YouTube </span><span
                                            className="usr-email md-txt">{account.email}</span>
                                        </h6>
                                        <p className="status">
                                                <span
                                                    className="security-number sm-txt">{account.title} - {account.channel_id}</span> added
                                            <span className="date-added sm-txt">{account.created}</span>
                                        </p>
                                    </div>
                                    <div className="btns-wrapper d-flex pl-lg-3">
                                        {/*<a href="#" className="btn btn-2 reconnect w-100 mr-2">Reconnect</a>*/}
                                        <button  className="btn delt btn-2 w-100 ml-2" onClick={() => handleDelete(account.id)}>
                                            Delete
                                        </button>
                                    </div>
                                    </>
                                )}

                            </div>
                            <div className="conn-btn-wrapper text-center">
                                <GoogleLogin
                                    clientId="385524880993-kb22h3hvlooun9i0908bsm29bkeafhio.apps.googleusercontent.com"
                                    render={renderProps => (
                                        <button style={{padding: "0px", border: "none"}} onClick={renderProps.onClick} disabled={renderProps.disabled} className="btn btn-2 w-100 mr-2 connect" ><img className="img-fluid" src={YouTubeNew} alt="YouTube Logo" /></button>
                                    )}
                                    onSuccess={responseGoogle}
                                    onFailure={responseGoogle}
                                    scope="https://www.googleapis.com/auth/youtube"
                                    responseType="code"
                                    accessType="offline"
                                    prompt="consent"
                                    cookiePolicy={'single_host_origin'}
                                />
                            </div>
                        </>

                }
            </div>
        </div>
        {confirmDelete.status ? <ConfirmDelete close={handleConfirmClose} confirm={handleConfirmDelete}/> : null }
        </>
    )
}
export default Youtube;