import {ReactComponent as PlayIcon} from "../../../../../assets/images/play_1.svg";
import {ReactComponent as PauseIcon} from "../../../../../assets/images/pause.svg";
import React, {useEffect, useRef, useState} from "react";
import {useDrag} from "react-dnd";
const WaveSurfer = require('wavesurfer.js');
const formWaveSurferOptions = (ref) => ({
    container:ref,
    waveColor:"#03a9f4",
    cursorColor:"#03a9f4",
    progressColor:"#03a9f4",
    barWidth: 1,
    barRadius: 1,
    responsive: true,
});
function AudioTrack(props) {
    const waveformRef = useRef();
    const wavesurfer = useRef(null);

    const [isDragging,drag] = useDrag({
        item:{
            type:'TRACK',
            trackType:'audio',
            url:props.audio.url,
            duration:10
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        })
    });
    const [playing,setPlaying] = useState({
        isLoaded:false,
        isPlaying:false
    });


    useEffect(() => {
        const options = formWaveSurferOptions(waveformRef.current);
        wavesurfer.current = WaveSurfer.create(options);
        wavesurfer.current.load(props.audio.url);
        wavesurfer.current.on("ready", function() {
            setPlaying({
                ...playing,
                isLoaded:true
            });
        });
    },[])

    const handlePlay = () => {
        setPlaying({
            ...playing,
            isPlaying:true
        });
        wavesurfer.current.play();
    }
    const handlePause = () =>{
        setPlaying({
            ...playing,
            isPlaying:false
        });
        wavesurfer.current.pause();
    }
    return(
        <div className="aud-wrapper" style={isDragging.isDragging ? {opacity:'0.2'} : {opacity:'1'}}
             ref={drag}
        >
            <div className="aud-ctrls">
                <div id={"audio-spectrum"+props.index} ref={waveformRef}> </div>
                <button className="aud-ctrl-btn btn" >
                    {playing.isLoaded ? null : <div className="spinner-grow" style={{color:'#6b33d3'}}> </div>}
                    {playing.isPlaying && playing.isLoaded ? <svg width="8px" height="10px" className="pause-aud" viewBox="0 0 8 10" onClick={handlePause}>
                        <PauseIcon />
                    </svg> : null}
                    {(playing.isPlaying === false && playing.isLoaded === true) ? <svg width="12px" height="12px" className="play-aud"
                                                                                       viewBox="0 0 12 12" onClick={handlePlay}>
                        <PlayIcon />
                    </svg> : null}
                </button>
                <span className="white">{props.audio.title}</span>
            </div>
        </div>
    )
}
export default AudioTrack;
