import React, {useContext} from "react";
import {NavLink} from "react-router-dom";
import './style.css';
import {AuthenticationContext} from "../../App";
function NotFound() {
    const auth = useContext(AuthenticationContext);
    return(
        <div id="notfound">
            <div className="notfound-bg">
                <div> </div>
                <div> </div>
                <div> </div>
                <div> </div>
            </div>
            <div className="notfound">
                <div className="notfound-404">
                    <h1 className="grad-txt">404</h1>
                    <p>404</p>
                </div>
                <h2>Page Not Found</h2>
                <p>The page you are looking for might have been removed had its name changed or is temporarily
                    unavailable.</p>
                {auth.isAuthenticated ? <NavLink to="/dashboard">Dashboard</NavLink> : <NavLink to="/login">Login</NavLink>}
            </div>
        </div>
    )
}
export default NotFound;