let initialState = {
    data: false
}

export default function (state = initialState, action) {
    switch (action.type) {

        case 'ADD_REBRAND':
            return {
                ...state,
                data: action.payload
            }

        case 'REMOVE_ADD_REBRAND':
            return {
                data: false
            }

        default:
            return state

    }
}