import Menu from "../../Includes/Menu/Menu";
import store from "../../../Store/store";
import SupportHeader from "../Support/SupportHeader";
import Footer from "../../Includes/Footer/Footer";
import React from "react";

function Business(props) {
    return (
        <body>
        <header id="app-header" className="position-fixed w-100">
            <Menu profile={store.getState().auth.user.profile_pic} location={props.location}/>
        </header>
        <div id="rep-content">
            <SupportHeader />
            <section id="vid-tuts" className="section bg-dark min-vh-100">
                <div className="container px-4">
                    <div className="row">
                        <div className="col colmn-1 col-12 mb-4">
                            <div className="title-area text-center">
                                <h3 className="text-white">Business Page</h3>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="btn-wrapper">
                                <a href="https://support.vineasx.com" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Request Reseller Details</a>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="btn-wrapper">
                                <a href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Agency_Demo.wpress" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download Agency Website</a>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="btn-wrapper">
                                <a href="https://support.vineasx.com" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Request Web Hosting</a>
                            </div>
                        </div>
                        <div className="col-md-6">
                            {/*<p className="text-white">Client Meeting Setup Template</p>*/}
                            <div className="btn-wrapper">
                                <a href="https://app.viralreel.io/assets/client_contract/Meeting-Doc.doc" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download Meeting Setup Template</a>
                            </div>
                            {/*<p className="text-white">Client Service Agreement Template</p>*/}
                            <div className="btn-wrapper">
                                <a href="https://app.viralreel.io/assets/client_contract/Services%20Agreement.doc" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download Service Agreement Template</a>
                            </div>
                            {/*<p className="text-white">Client Brief Pitch Template</p>*/}
                            <div className="btn-wrapper">
                                <a href="https://app.viralreel.io/assets/client_contract/Client-Brief-Form.doc" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download Brief Pitch Template</a>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="btn-wrapper">
                                <a href="https://s3-us-west-2.amazonaws.com/adsviser3/library/adpack1.zip" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download Facebook Ad Template</a>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="btn-wrapper">
                                <a href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/Sendiio/100.zip" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download DFY Lead Magnets</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <Footer/>
        </body>
    )
}
export default Business;