import {CLEAR_PROJECT, PROJECT} from "../actions";

const initialState = {
    projects:[],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case PROJECT:
            return{
                ...state,
                projects:action.payload.projects,
            }
        case CLEAR_PROJECT:
            return{
                ...state,
                projects:[],
            }
        default:
            return state;
    }
}