import {
    ACTIVE_ITEM, AUTH_ERROR,
    NEEDLE_POINT,
    PROJECT,
    PROJECT_DETAILS, PROJECT_UNMOUNT,
    PROJECT_UPDATE, SHOW_NOTIFICATION,
    TIMELINE_SETTINGS,
    TRANSCRIBE_UPDATE, USER_LOADED
} from "./index";
import store from "../store";
import axios from "axios";
import {baseURL} from "../../Global";
import {UpdateBackground} from "../../Global/UpdateBackground";

export const fetch_project_details = (data) => (dispatch,getState) => {
    const options = {
        headers:{
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+store.getState().auth.token,
        }
    }
    axios.get(baseURL+'project_single_fetch?'+data, options)
        .then(res => {
            if(res.data.status === true)
            {
                dispatch({type:PROJECT_DETAILS,payload:{details: res.data.data}});
            }
            else{
                console.log('Error',res);
            }
        }).catch(error => console.log('Error',error))
}

export const unmount_project_details = () => (dispatch,getState) => {
    dispatch({type:PROJECT_UNMOUNT});
}



export const update_project = (data) => (dispatch,getState) => {
    dispatch({type:PROJECT_UPDATE,payload:{project:data}});
}
export const update_transcribe = (data) => (dispatch,getState) => {
    dispatch({type:TRANSCRIBE_UPDATE,payload:{transcribe:data}});
}

export const timeline_settings = (data) => (dispatch,getState) => {
    dispatch({type:TIMELINE_SETTINGS,payload:{zoomValue:data.zoomValue,measureStick:data.measureStick}});
}

// selects the current layer when clicked on it
export const active_layer_selection = (trackID,activeID) => (dispatch,getState) => {
    const originalTrackList = JSON.parse(JSON.stringify(getState().timeline.tracks));
    for(const [key,value] of Object.entries(originalTrackList))
    {
        originalTrackList[key] = originalTrackList[key].map(element => {
                   element.isSelected = element.id === activeID;
                   return element;
                })
    }

    // originalTrackList[trackID] = originalTrackList[trackID].map(element => {
    //        element.isSelected = element.id === activeID;
    //        return element;
    //     });
    dispatch({type:ACTIVE_ITEM,payload:{tracks:originalTrackList}});
}

// updates the canvas layers when moved from one layer to another
export const update_layer = (trackID,activeID,canvasJsonData) => (dispatch,getState) => {
    const originalTrackList = JSON.parse(JSON.stringify(getState().timeline.tracks));
    originalTrackList[trackID] = originalTrackList[trackID].map((element,index) => {
        if(element.isSelected === true && element.id === activeID)
        {
            element.layers = canvasJsonData;
        }
        return element;
    });
    dispatch({type:ACTIVE_ITEM,payload:{tracks:originalTrackList}});
}

export const updateCanvasBackgroundProperties = (trackID,activeID,properties) => (dispatch,getState) => {
    const originalTrackList = JSON.parse(JSON.stringify(getState().timeline.tracks));
    originalTrackList[trackID] = originalTrackList[trackID].map((element,index) => {
        if(element.isSelected === true && element.id === activeID)
        {
            UpdateBackground(element,properties);
        }
        return element
    });
    dispatch({type:ACTIVE_ITEM,payload:{tracks:originalTrackList}});
}


// Update the start time of the layers when dragged
export const active_item_start_time = (trackID,activeID,startTime) => (dispatch,getState) => {
    const originalTrackList = JSON.parse(JSON.stringify(getState().timeline.tracks));
    originalTrackList[trackID] = originalTrackList[trackID].map((element,index) => {
        if(element.id === activeID)
            element.startTime = startTime;

        return element;
    });
    dispatch({type:ACTIVE_ITEM,payload:{tracks:originalTrackList}});
}

// update the duration of the active layer when resized
export const active_item_duration = (trackID,activeID,duration) => (dispatch,getState) => {
    const originalTrackList = JSON.parse(JSON.stringify(getState().timeline.tracks));
    originalTrackList[trackID] = originalTrackList[trackID].map((element,index) => {
        if(element.id === activeID)
            element.duration = duration;

        return element;
    });
    dispatch({type:ACTIVE_ITEM,payload:{tracks:originalTrackList}});
}


export const needlePointUpdate = (newPoint) => (dispatch,getState) => {
    dispatch({type:NEEDLE_POINT,payload:{needlePoint:newPoint}});
}


// add new Audio track in the timeline
/*
 *
 */
export const add_new_track = (data) => (dispatch,getState) => {
    console.log(data,'tts add');
    const originalTrackList = JSON.parse(JSON.stringify(getState().timeline.tracks));
    // check if the track already exists in the timeline, if yes then remove it.
    for(const [key,value] of Object.entries(originalTrackList))
    {
        originalTrackList[key] = originalTrackList[key].filter(element => element.id !== data.id)
    }

    // create the new track
    const trackID = Math.random().toString(36).substr(2, 9);
    originalTrackList[trackID] = [data]
    //console.log(originalTrackList);
    dispatch({type:ACTIVE_ITEM,payload:{tracks:originalTrackList}});
}

export const add_new_track_reorder = (trackId,data) => (dispatch,getState) => {
    const originalTrackList = JSON.parse(JSON.stringify(getState().timeline.tracks));
    for(const [key,value] of Object.entries(originalTrackList))
    {
        originalTrackList[key] = originalTrackList[key].filter(element => {return element.id !== data.id });
        // console.log(originalTrackList[key]);
    }
    originalTrackList[trackId].push(data);
    console.log(originalTrackList);
    dispatch({type:ACTIVE_ITEM,payload:{tracks:originalTrackList}});
}



// Make the first item active
export const first_layer_selection = () => (dispatch,getState) => {
    const originalTrackList = JSON.parse(JSON.stringify(getState().timeline.tracks));
    for(const [key,value] of Object.entries(originalTrackList))
    {
        originalTrackList[key] = originalTrackList[key].map((element,index) => {
            index === 0 ? element.isSelected = true : element.isSelected = false
            return element;
        });
        break;
    }
    dispatch({type:ACTIVE_ITEM,payload:{tracks:originalTrackList}});
}

export const last_layer_selection = () => (dispatch,getState) => {
    const originalTrackList = JSON.parse(JSON.stringify(getState().timeline.tracks));
    for (const [key, value] of Object.entries(originalTrackList)) {
        originalTrackList[key] = originalTrackList[key].map((element, index) => {
            originalTrackList[key].length === index + 1 ? element.isSelected = true : element.isSelected = false
            return element;
        });
        break;
    }
    dispatch({type: ACTIVE_ITEM, payload: {tracks: originalTrackList}})
}



export const previousElement = () => (dispatch,getState) => {
    const originalTrackList = JSON.parse(JSON.stringify(getState().timeline.tracks));
    let currentSelectedIndex = false;
    let currentSelectedTrackIndex = false;
    for (const [key, value] of Object.entries(originalTrackList)) {
        currentSelectedIndex = originalTrackList[key].findIndex((element) => element.isSelected === true);
        if(currentSelectedIndex){
            currentSelectedTrackIndex = key;
            break;
        }
    }
    if(currentSelectedIndex && currentSelectedTrackIndex)
    {
        if(currentSelectedIndex > 0)
        {
            originalTrackList[currentSelectedTrackIndex][currentSelectedIndex].isSelected = false;
            originalTrackList[currentSelectedTrackIndex][currentSelectedIndex-1].isSelected = true;
            dispatch({type:ACTIVE_ITEM,payload:{tracks:originalTrackList}});
        }
    }
}

export const nextElement = () => (dispatch,getState) => {
    const originalTrackList = JSON.parse(JSON.stringify(getState().timeline.tracks));
    let currentSelectedIndex = false;
    let currentSelectedTrackIndex = false;
    for (const [key, value] of Object.entries(originalTrackList)) {
        currentSelectedIndex = originalTrackList[key].findIndex((element) => element.isSelected === true);
        if(currentSelectedIndex){
            currentSelectedTrackIndex = key;
            break;
        }
    }
    if(currentSelectedIndex && currentSelectedTrackIndex)
    {
        console.log(currentSelectedIndex,originalTrackList[currentSelectedTrackIndex].length)
        if(currentSelectedIndex < (originalTrackList[currentSelectedTrackIndex].length)-1)
        {
            originalTrackList[currentSelectedTrackIndex][currentSelectedIndex].isSelected = false;
            originalTrackList[currentSelectedTrackIndex][currentSelectedIndex+1].isSelected = true;
            dispatch({type:ACTIVE_ITEM,payload:{tracks:originalTrackList}});
        }

    }
}

export const applyToAllSlideText = (activeObject) => (dispatch,getState) => {
    const originalTrackList = JSON.parse(JSON.stringify(getState().timeline.tracks));
    for(const [key,value] of Object.entries(originalTrackList))
    {
        originalTrackList[key] = originalTrackList[key].map((element,index) => {
           if(element.type === 'graphic')
           {
               element.layers.objects.map(layer => {
                   if(layer.type === 'textbox')
                   {
                       layer.fontFamily = activeObject.fontFamily;
                       layer.fontSize = activeObject.fontSize;
                       layer.fontWeight = activeObject.fontWeight;
                       layer.fontStyle = activeObject.fontStyle;
                       layer.underline = activeObject.underline;
                       layer.strikethrough = activeObject.strikethrough;
                       layer.textAlign = activeObject.textAlign;
                       layer.fill = activeObject.fill;
                       layer.textBackgroundColor = activeObject.textBackgroundColor;
                   }
               })
           }
           return element;
        });
    }
    dispatch({type:ACTIVE_ITEM,payload:{tracks:originalTrackList}});
}

export const applyToAllSlideBackground = (layer,trackId) => (dispatch,getState) => {
    const originalTrackList = JSON.parse(JSON.stringify(getState().timeline.tracks));

    // Fetch selected Slide Background Property
    const object = layer.layers['objects'].find(layerObject => layerObject.hasOwnProperty('background_slide_type'));


    const properties = {};
    if(object !== undefined)
    {
        // property is either image or video
        properties.type = object.background_slide_type;
        properties.value = object.background_slide_type === 'video '? object.video_src : object.src;
        properties.thumbnail = layer.source.thumbnail;
    }
    else
    {
        // property is color
        properties.type = 'color';
        properties.value = layer.source.color;
        properties.thumbnail = layer.source.thumbnail;
    }
    for(const [key,value] of Object.entries(originalTrackList))
    {
        originalTrackList[key] = originalTrackList[key].map((element,index) => {
            if(element.type === 'graphic')
            {
                UpdateBackground(element,properties);
            }
            return element
        });
    }
    //console.log(originalTrackList);
    dispatch({type:ACTIVE_ITEM,payload:{tracks:originalTrackList}});
}

export const deleteSelectedTrack = () => (dispatch,getState) => {
    const originalTrackList = JSON.parse(JSON.stringify(getState().timeline.tracks));
    for(const [key,value] of Object.entries(originalTrackList))
    {
        originalTrackList[key] = originalTrackList[key].filter((element) => element.isSelected !== true);
    }
    if(originalTrackList[Object.keys(originalTrackList)[0]][0] !== undefined)
    {
        originalTrackList[Object.keys(originalTrackList)[0]][0].isSelected = true;
    }
    dispatch({type:ACTIVE_ITEM,payload:{tracks:originalTrackList}});
}