import React from "react";
import pdfImg from "../../assets/images/pdf-icon.jpg"

function AcadeableTraining() {
    const trainingPdf = [
        {id:'1',image:'',title:'Installation with Apache',link:'https://misctrainingvideos.s3.amazonaws.com/Acadeable/Installation+with+Apache.pdf'},
        {id:'2',image:'',title:'Installing Acedeable',link:'https://misctrainingvideos.s3.amazonaws.com/Acadeable/Installing+Acedeable.pdf'},
        {id:'3',image:'',title:'PWA Setup Acedeable',link:'https://misctrainingvideos.s3.amazonaws.com/Acadeable/PWA+Setup.pdf'},
        {id:'4',image:'',title:'Server Requirements',link:'https://misctrainingvideos.s3.amazonaws.com/Acadeable/Server+Requirements.pdf'},
        {id:'5',image:'',title:'Shared Hosting Install',link:'https://misctrainingvideos.s3.amazonaws.com/Acadeable/Shared+Hosting+Install.pdf'}
    ]
    return(
        <section id="tpc-section" className="section">
            <div className="container-fluid px-4">
                <div className="row">
                    <div className="col colmn-1 col-12 mb-4">
                        <div className="title-area text-center">
                            <h3 className="text-white">Training</h3>
                        </div>
                    </div>
                    <div className="card-row d-flex flex-row flex-wrap w-100 mx-auto tpc-section-wrap">
                        {trainingPdf.map(training =>
                            <a href={training.link} target="_blank">
                                <div className="tpc-card card border-0 text-center w-100 mr-4 mb-4" key={training.id}>
                                    <img className="card-img-top img-icon mb-3" src={pdfImg}
                                         alt="Icon" />
                                    <div className="card-body p-0">
                                        <h5 className="card-title mb-0">{training.title}</h5>
                                    </div>
                                </div>
                            </a>
                        )}
                    </div>
                </div>
            </div>
        </section>
    )
}
export default AcadeableTraining;