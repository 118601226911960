import Menu from "../../Includes/Menu/Menu";
import store from "../../../Store/store";
import React from "react";
import TrainingSupport from "./TrainingSupport";
import TrainingPDF from "./TrainingPDF";
import TrainingVideo from "./TrainingVideo";
import Footer from "../../Includes/Footer/Footer";
import "./style.css";
import SupportHeader from "./SupportHeader";

function Support(props) {
    return(
        <body>
            <header id="app-header" className="position-fixed w-100">
                <Menu profile={store.getState().auth.user.profile_pic} location={props.location}/>
            </header>
            <div id="rep-content">
                <SupportHeader />
                <TrainingPDF />
                <TrainingVideo />
                <TrainingSupport />
            </div>
            <Footer/>
        </body>
    )
}
export default Support;