import React from "react";
import PlayBtnSvg from "../../assets/images/play-button.svg"
function TrainingVideo() {
    const videos = [{ id: '1', title: 'Walkthrough', link: 'https://player.vimeo.com/video/448063223' },
    { id: '1', title: 'Social Accounts', link: 'https://player.vimeo.com/video/448069051' },
    { id: '2', title: 'Amazon Account', link: 'https://player.vimeo.com/video/451142363' },
    { id: '3', title: 'Start New Project', link: 'https://player.vimeo.com/video/448073683' },
    { id: '4', title: 'Customize Project', link: 'https://player.vimeo.com/video/448070209' }
    ]
    return (
        <section id="vid-tuts" className="section bg-dark pb-4">
            <div className="container-fluid container-fluid-alt px-4">
                <div className="row">
                    <div className="col colmn-1 col-12 mb-4">
                        <div className="title-area text-center">
                            <h3 className="text-white">Video Tutorials</h3>
                        </div>
                    </div>
                    {videos.map(video =>
                        <div className="col colmn-2 col-xl-3 col-lg-4 col-sm-6 col-12 px-2 mb-5">
                            <div className="card-wrapper">
                                <div className="img-wrapper mb-3">
                                    {/*<img className="img-fluid poster w-100" src={require('../../assets/images/vid-tuts-1.png')}*/}
                                    {/*     alt="Tutorials Image" />*/}
                                    <div dangerouslySetInnerHTML={{
                                        __html: "<iframe src='" + video.link + "' webkitallowfullscreen mozallowfullscreen allowFullScreen> </iframe>"
                                    }} />
                                </div>
                                <div className="details-wrapper row mx-0">
                                    <a href="#" className="play pr-3 align-self-center">
                                        <img className="img-fluid" src={PlayBtnSvg} alt="Icon" />
                                    </a>
                                    <div className="meta-wrapper flex-grow-1">
                                        <div className="date mb-1">
                                            <span className="sm-txt text-white">August 16, 2020</span>
                                        </div>
                                        <p className="text-white title" style={{ fontWeight: '600' }}>{video.title}
                                            01</p>
                                    </div>
                                    {/*<div className="time">*/}
                                    {/*    <span className="sm-txt text-white">2:22</span>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    )}


                </div>
            </div>
        </section>
    )
}

export default TrainingVideo;