import React, { useContext, useEffect, useRef, useState } from "react";
import { ReactComponent as DownArrow } from "../../../../../assets/images/down-arrow.svg";
import { ReactComponent as TextBoldIcon } from "../../../../../assets/images/text-bold.svg";
import { ReactComponent as TextItalicIcon } from "../../../../../assets/images/text-italic.svg";
import { ReactComponent as TextUppercaseIcon } from "../../../../../assets/images/text-upper.svg";
import { ReactComponent as TextLowercaseIcon } from "../../../../../assets/images/text-lower.svg";
import { ReactComponent as TextUnderlineIcon } from "../../../../../assets/images/text-underline.svg";
import { ReactComponent as TextStrikeIcon } from "../../../../../assets/images/text-strike.svg";
import { ReactComponent as TextLeftIcon } from "../../../../../assets/images/text-left.svg";
import { ReactComponent as TextCenterIcon } from "../../../../../assets/images/text-center.svg";
import { ReactComponent as TextRightIcon } from "../../../../../assets/images/text-right.svg";
import { ReactComponent as TextJustifyIcon } from "../../../../../assets/images/text-justify.svg";
import { ReactComponent as TextLineHeightIcon } from "../../../../../assets/images/ico-line-height.svg";
import CanvasContext from "../../../../../../Global/CanvasContext";
import store from "../../../../../../Store/store";
import FontColor from "./FontColor";
import FontBackgroundColor from "./FontBackgroundColor";
import {applyToAllSlideText} from "../../../../../../Store/actions/timelineActions";


function TextCustomization() {
    const FabricCanvas = useContext(CanvasContext);
    const [buttonActive, setButtonActive] = useState({
        1: [
            { type: 'bold', tooltip: 'Text Bold', svg: <TextBoldIcon />, active: false },
            { type: 'italic', tooltip: 'Text Italic', svg: <TextItalicIcon />, active: false },
            { type: 'uppercase', tooltip: 'Text Uppercase', svg: <TextUppercaseIcon />, active: false },
            { type: 'lowercase', tooltip: 'Text Lowercase', svg: <TextLowercaseIcon />, active: false },
            { type: 'underline', tooltip: 'Text Underline', svg: <TextUnderlineIcon />, active: false },
            { type: 'strikethrough', tooltip: 'Text Strikethrough', svg: <TextStrikeIcon />, active: false }],
        2: [{ type: 'left', tooltip: 'Align Text Left', svg: <TextLeftIcon />, active: false },
        { type: 'center', tooltip: 'Align Text Center', svg: <TextCenterIcon />, active: false },
        { type: 'right', tooltip: 'Align Text Right', svg: <TextRightIcon />, active: false },
        { type: 'justify', tooltip: 'Text Align Justify', svg: <TextJustifyIcon />, active: false },
        { type: 'lineHeight', tooltip: 'Text Align Justify', svg: <TextLineHeightIcon /> }
        ]
    })
    const fonts = [
        {id:'1',name:'Roboto',value:'Roboto'},
        {id:'2',name:'Poppins',value:'Poppins'},
        {id:'3',name:'Alata',value:'Alata'},
        {id:'4',name:'Open Sans',value:'Open Sans'},
        {id:'5',name:'Noto Sans',value:'Noto Sans'},
        {id:'6',name:'Russo One',value:'Roboto'},
        {id:'7',name:'Noto Sans',value:'Noto Sans'},
        {id:'8',name:'Montserrat',value:'Roboto'},
        {id:'9',name:'Do Hyeon',value:'Do Hyeon'},
        {id:'10',name:'Roboto Condensed',value:'Roboto Condensed'},
        {id:'11',name:'Source Sans',value:'Source Sans'},
        {id:'12',name:'Oswald',value:'Oswald'},
        {id:'13',name:'Mukta',value:'Mukta'},
        {id:'14',name:'Baloo Tamma 2',value:'Baloo Tamma 2'},
        {id:'15',name:'Roboto Mon',value:'Raleway'},
        {id:'16',name:'PT Sans',value:'PT Sans'},
        {id:'17',name:'Roboto Slab',value:'Merriweather'},
        {id:'18',name:'Ubuntu',value:'Ubuntu'},
        {id:'19',name:'Playfair',value:'Playfair'},
        {id:'20',name:'Open Sans Condensed',value:'Open Sans Condensed'},
        {id:'21',name:'Hind',value:'Hind'},
        {id:'22',name:'Nunito',value:'Nunito'},
        {id:'23',name:'Lora',value:'Lora'},
        {id:'24',name:'PT Serif',value:'PT Serif'},
        {id:'25',name:'Noto Sans KR',value:'=Noto Sans KR'},
        {id:'26',name:'Work Sans',value:'Work Sans'},
        {id:'27',name:'Rubik',value:'Balsamiq Sans'},
        {id:'28',name:'Noto Serif',value:'Noto Serif'},
        {id:'29',name:'Fira Sans',value:'Fira Sans'},
        {id:'30',name:'Nanum Gothic',value:'Nanum Gothic'},
        {id:'31',name:'Titillium Web',value:'Titillium Web'},
        {id:'21',name:'Nunito Sans',value:'Nunito Sans'},
        {id:'33',name:'Teko',value:'Teko'},
        {id:'34',name:'Quicksand',value:'Quicksand'},
        {id:'35',name:'Noto Sans TC',value:'Noto Sans TC'},
        {id:'36',name:'Londrina Solid',value:'Londrina Solid'},
        {id:'37',name:'Yanone Kaffeesatz',value:'Yanone Kaffeesatz'},
        {id:'38',name:'Heebo',value:'Heebo'},
        {id:'39',name:'Inconsolata',value:'Inconsolata'},
        {id:'40',name:'Anton',value:'Anton'},
        {id:'41',name:'Barlow',value:'Barlow'},
        {id:'42',name:'Oxygen',value:'Oxygen'},
        {id:'43',name:'PT Sans Narrow',value:'PT Sans Narrow'},
        {id:'44',name:'Hind Siliguri',value:'Hind Siliguri'},
        {id:'45',name:'Dosis',value:'Dosis'},
        {id:'46',name:'Libre Caslon Display',value:'Libre Caslon Display'},
        {id:'47',name:'Karla',value:'Karla'}
    ]


    const [applySlide, setApplySlide] = useState(false)
    const [lineHeight, setLineHeight] = useState(1)
    const handleSize = (e) => {
        if (FabricCanvas.getActiveObject()) {
            FabricCanvas.getActiveObject().set({
                fontSize: e.target.value
            });
            FabricCanvas.renderAll();
        }
    }
    const clickHandle = (type) => {
        if (FabricCanvas.getActiveObject()) {
            const updatedButtons = {}
            for (const indexes in buttonActive) {
                updatedButtons[indexes] = buttonActive[indexes].map((element, index) => {
                    if (element.type === type) {
                        element.active = !buttonActive[indexes][index].active;
                        // switch statement
                        switch (type) {
                            case 'bold':
                                FabricCanvas.getActiveObject().set({
                                    fontWeight: element.active ? 'Bold' : ''
                                });
                                break;
                            case 'italic':
                                FabricCanvas.getActiveObject().set({
                                    fontStyle: element.active ? 'Italic' : ''
                                });
                                break;
                            case 'underline':
                                FabricCanvas.getActiveObject().set({
                                    underline: element.active,
                                });
                                break;
                            case 'strikethrough':
                                FabricCanvas.getActiveObject().set({
                                    linethrough: element.active
                                });
                                break;
                            case 'left':
                                FabricCanvas.getActiveObject().set({
                                    textAlign: element.active ? 'left' : ''
                                });
                                break;
                            case 'right':
                                FabricCanvas.getActiveObject().set({
                                    textAlign: element.active ? 'right' : ''
                                });
                                break;
                            case 'center':
                                FabricCanvas.getActiveObject().set({
                                    textAlign: element.active ? 'center' : ''
                                });
                                break;
                            case 'justify':
                                FabricCanvas.getActiveObject().set({
                                    textAlign: element.active ? 'justify' : ''
                                });
                                break;
                            case 'uppercase':
                                FabricCanvas.getActiveObject().set('text', FabricCanvas.getActiveObject().text.toUpperCase());
                                break;
                            case 'lowercase':
                                FabricCanvas.getActiveObject().set('text', FabricCanvas.getActiveObject().text.toLowerCase());
                                break;
                            default:
                                break;
                        }
                    }
                    return element;
                })
            }
            setButtonActive(updatedButtons);
            FabricCanvas.renderAll();
        }
        else {
            alert('Please select any text first');
        }

    }

    const handleLineHeight = (e) => {
        if (FabricCanvas.getActiveObject()) {
            const lineHeight = e.target.value;
            FabricCanvas.getActiveObject().set({
                lineHeight: lineHeight
            });
            setLineHeight(lineHeight);
            FabricCanvas.renderAll();
        }
        else {
            alert('Please select any text first');
        }

    }
    const but = []
    for (const indexes in buttonActive) {
        const item = buttonActive[indexes].map((element, index) => {
            let i = false
            if (element.type === 'lineHeight') {
                i = <div key={index} className="input-group-prepend mr-0">
                    <span className="icon">
                        <svg width="14px" height="15px" viewBox="0 0 14 15">{element.svg}</svg>
                    </span>
                    <input id="t-lineheight" className="form-control input-dark text-white" type="number" value={lineHeight} name="Lineheight" onChange={handleLineHeight} />
                </div>
            }
            else {
                i = <button key={index} className={`txt-edit-btn text-white input-dark ml-0 ${element.active ? 'active' : ''}`} data-toggle="tooltip" title={element.tooltip} onClick={() => clickHandle(element.type)}>
                    <svg width="22px" height="12px" viewBox="0 0 22 12">
                        {element.svg}
                    </svg>
                </button>
            }
            return i;
        }
        )
        but.push(<div key={indexes} className="text-alignment form-group mb-2 d-flex flex-xl-nowrap flex-wrap">{item}</div>)
    }

    const handleApplySlides = (e) => {
        if (FabricCanvas.getActiveObject()) {
            store.dispatch(applyToAllSlideText(FabricCanvas.getActiveObject()));
            setApplySlide(!applySlide);
        }
        else
        {
            alert('Please select any text first');
        }
    }

    const applyFont = (e) => {
        if (FabricCanvas.getActiveObject()) {
            FabricCanvas.getActiveObject().set({
                fontFamily:e.target.value
            });
            FabricCanvas.renderAll();
        }
        else
        {
            alert('Please select any text first');
        }
    }

    const applyWeight = (e) => {
        if (FabricCanvas.getActiveObject()) {
            FabricCanvas.getActiveObject().set({
                fontWeight:e.target.value
            });
            FabricCanvas.renderAll();
        }
        else
        {
            alert('Please select any text first');
        }
    }
    return (
        <div className="text-customization">
            <span className="label text-white mb-2" style={{fontFamily:'Roboto',fontWeight:'100'}}>Font</span>
            <div className="text-family form-group mb-2">
                <select id="sel-font" className="form-control input-dark" name="f-family" onChange={applyFont}>
                    {fonts.map(element => <option value={element.value} key={element.id}>{element.name}</option>)}
                </select>
                <span className="icon">
                    <svg width="12px" height="12px" viewBox="0 0 12 12">
                        <DownArrow />
                    </svg>
                </span>
            </div>
            <div className="text-weight-size form-group mb-2 d-flex flex-xl-nowrap flex-wrap">
                <div className="sel-wrapper w-100 mr-2">
                    <select id="sel-font-weight" className="form-control input-dark" name="f-weight" onChange={applyWeight}>
                        <option value="100">ExtraLight</option>
                        <option value="200">Light</option>
                        <option value="300">Regular</option>
                        <option value="400">SemiBold</option>
                        <option value="600">Bold</option>
                        <option value="800" >ExtraBold</option>
                        <option value="900">Black</option>
                    </select>
                    <span className="icon">
                        <svg width="12px" height="12px" viewBox="0 0 12 12">
                            <DownArrow />
                        </svg>
                    </span>
                </div>
                <div className="input-wrapper w-100">
                    <select id="f-size" className="form-control input-dark" name="f-size" onChange={handleSize}>
                        <option>10</option>
                        <option>11</option>
                        <option>12</option>
                        <option>14</option>
                        <option>16</option>
                        <option>18</option>
                        <option>20</option>
                        <option selected>24</option>
                        <option>28</option>
                        <option>32</option>
                        <option>36</option>
                        <option>48</option>
                        <option>64</option>
                        <option>72</option>
                        <option>100</option>
                    </select>
                    <span className="icon">
                        <svg width="12px" height="12px" viewBox="0 0 12 12">
                            <DownArrow />
                        </svg>
                    </span>
                </div>
            </div>
            {but}
            <hr className="editor_hr" />
            <span className="label text-white mb-2">Font Color</span>
            <FontColor />
            <hr className="editor_hr" />
            <span className="label text-white mb-2">Font Background</span>
            <FontBackgroundColor />
            <hr className="editor_hr" />
            <div className="switch-wrapper d-flex align-items-center mb-4">
                <span className="label text-white flex-grow-1">Apply to all slides</span>
                <div className="custom-control custom-switch">
                    <input id="apply-tColor" type="checkbox" className="custom-control-input apply-to-all" value={applySlide} onChange={handleApplySlides} checked={applySlide}/>
                    <label className="custom-control-label" htmlFor="apply-tColor" style={{ cursor: "pointer" }}> </label>
                </div>
            </div>
        </div>
    )
}

export default TextCustomization