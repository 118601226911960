import React, {createContext, useEffect, useRef, useState} from "react";
import { DndProvider } from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import "./style.css";
import Right from "./Right/Right";
import Left from "./Left/Left";
import Timeline from "./Timeline/Timeline";
import {fabric} from "fabric";
import store from "../../../../Store/store";
import { active_layer_selection,
    needlePointUpdate, update_layer
} from "../../../../Store/actions/timelineActions";
import CanvasContext from "../../../../Global/CanvasContext";
import getVideoElement from "../../../../Global/GetElement";
import loadVideoInFabric from "../../../../Global/loadVideoInFabric";

let FabricCanvas = false;
const FabricInit = (node) => {
    const FabricCanvas = new fabric.Canvas(node.current, {
        containerClass: 'canvas-wrapper',
        backgroundColor: '#fff',
        preserveObjectStacking: true,
        renderOnAddRemove: false
    });
    FabricCanvas.setDimensions({
        width: 480,
        height: 270,
    });

    // object selection color
    fabric.Object.prototype.set({
        borderColor: '#747474',
        cornerColor: '#8133db',
        cornerStyle: 'circle',
        cornerSize: 8,
        transparentCorners: false,
        padding: 5,
        rotatingPointOffset: 0,
        borderDashArray: [12, 6],
        objectCaching: true,
        hoverCursor: 'pointer'
    });

    fabric.Object.prototype.toObject = (function (toObject) {
        return function (propertiesToInclude) {
            propertiesToInclude = (propertiesToInclude || []).concat(
                ['video_src','background_slide_type']);
            return toObject.apply(this, [propertiesToInclude]);
        };
    })(fabric.Object.prototype.toObject);

    return FabricCanvas;
}


const canvasLoad = (loader,jsonData) => {
    const canvasObjects = jsonData['objects'];
    let set = false;
    for(let i=0; i< canvasObjects.length; i++){
        if(canvasObjects[i].hasOwnProperty('background_slide_type'))
        {
            set = true;
            if(canvasObjects[i]['background_slide_type'] === 'video')
            {
                if(canvasObjects[i].hasOwnProperty('video_src') && canvasObjects[i]['video_src'] !== '') {
                    loadVideoInFabric(FabricCanvas,loader,canvasObjects[i]['video_src']).then(FabricVideoElement => {
                        FabricVideoElement.set('background_slide_type','video');
                        FabricCanvas.sendToBack(FabricVideoElement);
                        FabricVideoElement.selectable = false;
                    });
                }
            }
            if(canvasObjects[i]['background_slide_type'] === 'image')
            {
                if(canvasObjects[i].hasOwnProperty('src') && canvasObjects[i]['src'] !== '')
                {
                    loader.current.style.display='block';
                    const imageObject = FabricCanvas.getObjects().find(obj => obj.src === canvasObjects[i]['src']);
                    imageObject.selectable = false;
                    imageObject.set('background_slide_type','image');
                    imageObject.scaleToHeight(FabricCanvas.height);
                    imageObject.center();
                    FabricCanvas.sendToBack(imageObject);
                    loader.current.style.display='none';
                }
            }
        }
        else
        {
            if(canvasObjects[i].hasOwnProperty('video_src') && canvasObjects[i]['video_src'] !== '') {
                loadVideoInFabric(FabricCanvas,loader,canvasObjects[i]['video_src']).then(fabVideo => {});
            }
        }
    }

    if(!set)
    {
        loader.current.style.display='none'; // Make the loader display none if there is no background Property to take account for background Color
    }
}
let itemLayer = {
    layer:{
        id:'',
        type:'',
        startTime:'',
        layers:{},
        source:{thumbnail:'',color:''}
    },
    trackId:false
}
function MainEditor(props) {
    const loaderRef = useRef();
    const myCanvas = useRef();
    const [canvasReady, setCanvasReady] = useState(false)
    const trackListArray = JSON.parse(JSON.stringify(props.timeline.tracks));
    if(trackListArray)
    {
        for(const [key, value] of Object.entries(trackListArray))
        {
            const itemSelected = value.find((item) => item.isSelected === true)
            if(itemSelected !== undefined)
            {
                itemLayer = {
                    layer:itemSelected,
                    trackId:key
                };
                break;
            }
        }
    }

    useEffect(() => {
        if(itemLayer.layer.id !== '' && itemLayer.layer.layers && itemLayer.layer.type === 'graphic')
        {
            console.log(myCanvas,myCanvas.current.classList.contains('lower-canvas'));

            // if(FabricCanvas === false){
            //     FabricCanvas = FabricInit(myCanvas);
            // }
            if(myCanvas.current.classList.contains('lower-canvas') === false){
                FabricCanvas = FabricInit(myCanvas);
                setCanvasReady(FabricCanvas)
            }

            FabricCanvas.clear();
            FabricCanvas.loadFromJSON(itemLayer.layer.layers, function() {
                canvasLoad(loaderRef,itemLayer.layer.layers)
                // FabricCanvas.renderAll();
                // FabricCanvas.renderAll.bind(FabricCanvas)
            },function(o,object){
                //console.log(o,object)
            })
        }
        else
        {
            if(FabricCanvas === false){
                FabricCanvas = FabricInit(myCanvas);
            }
            FabricCanvas.clear();
            FabricCanvas.renderAll();
            FabricCanvas.renderAll.bind(FabricCanvas)
        }

        return () => {
            // FabricCanvas = false;
        }

    },[itemLayer.layer.id,itemLayer.layer.source.thumbnail,itemLayer.layer.source.color])
    /*
        Dependencies are
            Layer ID => for Selecting other layer
            Layer -> Source -> thumbnail => for changing Background Images and Videos
            Layer -> Source -> color => for  changing Background Color
     */

    const handleClickItem = (e) => {
        // Update the already active item Layer
        const canvasDataJSON = FabricCanvas.toJSON();

        // current Clicked Item and Track
        const trackIDNode = e.target.parentNode.parentNode.parentNode;
        let currentTrackIDValue = false;
        let currentItemID = false;
        if(trackIDNode.classList.contains('timeline-component-track'))
        {
            const trackID = trackIDNode.id;
            currentTrackIDValue = trackID.replace('track_id_','');
            currentItemID = e.currentTarget.id;
        }

        // check if the clicked Item and Existing Item is same ... if yes then do not update and if different then do update
        if(currentItemID === itemLayer.layer.id && itemLayer.trackId === currentTrackIDValue)
        {
            // do nothing
            console.log('Doing nothing ');
        }
        else
        {
            // check if this canvas object has same background properties as that in source
            // **************** IMPORTANT PATCH STARTS ************************
            let haveBg = false;
            canvasDataJSON.objects = canvasDataJSON.objects.map((object) => {
                if(object.hasOwnProperty('background_slide_type') && object['background_slide_type'] === 'video')
                {
                    object.src = 'https://coursereelserver.reelapps.io/assets/images/logo.png';
                }
                if(object.hasOwnProperty('background_slide_type') && object['background_slide_type'] === 'video' && object['video_src'] === '')
                {
                    object.video_src = itemLayer.layer.source.url;
                    haveBg = true;
                }
                if(object.hasOwnProperty('background_slide_type') && object['background_slide_type'] === 'image' && object['src'] === '')
                {
                    object.video_src = itemLayer.layer.source.url;
                    haveBg = true;
                }
                return object;
            })

            if(!haveBg)
            {
                //either the slide is colored or the background slide is missing
                if(itemLayer.layer.source.type === 'video')
                {
                    const newLayer = {"type":"image","version":"3.4.0","originX":"left","originY":"top","left":0,"top":0,"width":1920,"height":1080,"fill":"rgb(0,0,0)","stroke":null,"strokeWidth":0,"strokeDashArray":null,"strokeLineCap":"butt","strokeDashOffset":0,"strokeLineJoin":"miter","strokeMiterLimit":4,"scaleX":0.43,"scaleY":0.43,"angle":0,"flipX":false,"flipY":false,"opacity":1,"shadow":null,"visible":true,"clipTo":null,"backgroundColor":"","fillRule":"nonzero","paintFirst":"fill","globalCompositeOperation":"source-over","transformMatrix":null,"skewX":0,"skewY":0,"crossOrigin":"","cropX":0,"cropY":0,"video_src":"","src":"","filters":[]}
                    newLayer.src = 'https://coursereelserver.reelapps.io/assets/images/logo.png';
                    newLayer.background_slide_type = 'video';
                    newLayer.video_src = itemLayer.layer.source.url;
                    canvasDataJSON.objects.push(newLayer);
                    haveBg = true;
                }
                else if(itemLayer.layer.source.type === 'image')
                {
                    // add the background slide in the canvas object
                    const newLayer = {"type":"image","version":"3.4.0","originX":"left","originY":"top","left":0,"top":0,"width":1920,"height":1080,"fill":"rgb(0,0,0)","stroke":null,"strokeWidth":0,"strokeDashArray":null,"strokeLineCap":"butt","strokeDashOffset":0,"strokeLineJoin":"miter","strokeMiterLimit":4,"scaleX":0.43,"scaleY":0.43,"angle":0,"flipX":false,"flipY":false,"opacity":1,"shadow":null,"visible":true,"clipTo":null,"backgroundColor":"","fillRule":"nonzero","paintFirst":"fill","globalCompositeOperation":"source-over","transformMatrix":null,"skewX":0,"skewY":0,"crossOrigin":"","cropX":0,"cropY":0,"src":"","filters":[]}
                    newLayer.src = 'https://coursereelserver.reelapps.io/assets/images/logo.png';
                    newLayer.background_slide_type = 'image';
                    canvasDataJSON.objects.push(newLayer);
                    haveBg = true;
                }
                else
                {
                    haveBg = true;
                }
            }

            // **************** IMPORTANT PATCH ENDS ************************


            if(haveBg)
            {
                FabricCanvas.clear();
                FabricCanvas.renderAll();
                FabricCanvas.renderAll.bind(FabricCanvas)
                loaderRef.current.style.display='block';
                // canvasDataJSON.objects = canvasDataJSON.objects.map((object) => {
                //     if(object.hasOwnProperty('background_slide_type') && object['background_slide_type'] === 'video')
                //     {
                //         object.src = 'https://coursereelserver.reelapps.io/assets/images/logo.png';
                //     }
                //     return object;
                // })

                // ^^ The above shifted to the IMPORTANT PATCH SECTION
                if(canvasDataJSON.objects.length > 0)
                {
                    store.dispatch(update_layer(itemLayer.trackId,itemLayer.layer.id,canvasDataJSON));
                }
                // Update the newly clicked Layer
                store.dispatch(active_layer_selection(currentTrackIDValue,currentItemID));
            }

        }
    }

    return(
        <div id="editor-tab-cont" className={`tab-pane fade ${props.type === 'save' ? 'show active' : ''} `} role="tabpanel" aria-labelledby="editor-tab" >
            {props.hide ? <div><h5 className="text-white">Editor would be available after Transcript Generation</h5></div> :
                <CanvasContext.Provider value={canvasReady}>
                    <DndProvider backend={HTML5Backend}>
                        <Right loader={loaderRef} node={myCanvas} slayer={{layer:itemLayer.layer,track:itemLayer.trackId}}/>
                        <Left loader={loaderRef} layer={itemLayer.layer} trackID={itemLayer.trackId}/>
                        <Timeline timeline={props.timeline} click={handleClickItem}/>
                    </DndProvider>
                </CanvasContext.Provider>
            }
        </div>
    )
}
export default MainEditor;