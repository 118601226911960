import Menu from "../../Includes/Menu/Menu";
import './style.css';
import store from "../../../Store/store";
import React, {useEffect, useState} from "react";
import Facebook from "./Facebook/Facebook";
import Youtube from "./Youtube/Youtube";
import Syvid from "./Syvid/Syvid";
import ScriptReel from "./ScriptReel/ScriptReel";
import TrimReel from "./TrimReel/TrimReel";
import LiveReel from "./LiveReel/LiveReel";
import Webinar from "../Dashboard/Webinar/Webinar";
import Footer from "../../Includes/Footer/Footer";
import Notification from "../Notification/Notification";
import {connect} from "react-redux";
import {fetchSocialAccounts} from "../../../Store/actions/accountActions";
import CommonModal from "./CommonModal";
import axios from "axios";
import {baseURL} from "../../../Global";
import {displayNotification} from "../../../Store/actions/notificationAction";
import ConfirmDelete from "../ConfirmDelete/ConfirmDelete";
import Coursereelsvg from "../../assets/images/coursereel.svg";
import Plussvg from "../../assets/images/ico-plus.svg";
import facebookgreysvg from "../../assets/images/logo-facebook-grey.svg";
import logoFacebook from "../../assets/images/logo-facebook.svg";
import YoutubeGreySvg from "../../assets/images/logo-youtube-grey.svg";
import YoutubeLogoSvg from "../../assets/images/logo-youtube.svg"
import SyvidGrey from "../../assets/images/logo-syvideo-grey.png"
import SyvidLogo from "../../assets/images/logo-syvideo.svg"
import ScriptReelGreySvg from "../../assets/images/logo-scriptreel-grey.png"
import ScriptReeLogoSvg from "../../assets/images/logo-scriptreel.svg"
import TrimLogoGreyPng from "../../assets/images/logo-trim-grey.png"
import TrimLogoSvg from  "../../assets/images/logo-trim.svg"
import LiveReelGrey from "../../assets/images/logo-livereel-grey.png"
import LiveReelSvg from "../../assets/images/logo-livereel.svg"
 

function Integrations(props) {
    const [modal,setModal] = useState({
        open:false,
        appName:false
    });
    useEffect(() => {
        store.dispatch(fetchSocialAccounts());
    },[])

    const handleClick = (type) => {
        setModal({
            ...modal,
            open:true,
            appName: type
        })
    }

    const handleClose = () => {
        setModal({
            ...modal,
            open:false,
            appName: false
        })
    }


    const [confirmDelete, setConfirmDelete] = useState({
        status:false,
        id:0
    });

    const handleDelete = (id) => {
        setConfirmDelete({
            status:true,
            id:id
        })
    }

    const handleConfirmClose = () => {
        setConfirmDelete({
            status:false,
            id:0
        })
    }

    const config = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+store.getState().auth.token
    }

    const handleConfirmDelete = () => {
        console.log('Delete',confirmDelete.id);
        const data = {
            user_id:store.getState().auth.user.user_id,
            integration_id:confirmDelete.id
        }
        axios.post(baseURL+'delete-integration',data,{headers:config}).then(res => {
            if(res.data.status === true)
            {
                store.dispatch(displayNotification('success',res.data.message));
                // Fetch the updated Social Accounts
                store.dispatch(fetchSocialAccounts());
            }
            else
            {
                store.dispatch(displayNotification('error',res.data.message));
            }
            handleConfirmClose(); // close the confirm delete popup
        }).catch(error => {console.log(error)})
    }


    return(
        <body>
        <Menu profile={store.getState().auth.user.profile_pic} location={props.location}/>
        <Notification type={props.notification.notification.type} message={props.notification.notification.message}/>
        <section id="int-sec-1">
            <div className="container">
                <div className="row">
                    <div className="col colmn-1 col-12">
                        <div className="title-area row mx-0 justify-content-center align-items-center mb-2">
                            <div className="img-wrapper pr-2">
                                <img className="img-fluid" src={Coursereelsvg} alt="App Logo"
                                     width="317" />
                            </div>
                            <h1 className="text-white pl-2">Integrations</h1>
                        </div>
                        <div className="add-acc-wrapper text-center mb-3">
                            <img className="img-fluid" src={Plussvg} alt="Add Icon" />
                        </div>
                    </div>
                    <div className="col colmn-2 col-12">
                        <div className="acc-list row mx-auto w-100 justify-content-center" style={{maxWidth:'840px'}}>
                            <a href="#" className="acc-wrapper text-center bg-white ml-0">
                                <div className="img-wrapper mb-2">
                                    {
                                        props.social.facebook.length <= 0 ?
                                            <img className="img-fluid" src={facebookgreysvg} alt="Facebook Logo" width="56" />
                                            :
                                            <img className="img-fluid" src={logoFacebook} alt="Facebook Logo" width="56" />

                                    }

                                </div>
                                <p className="label">Facebook</p>
                            </a>
                            <a href="#yt-account" className="acc-wrapper not-connected text-center bg-white">
                                <div className="img-wrapper mb-2">
                                    {
                                        props.social.youtube.length <= 0 ?
                                            <img className="img-fluid" src={YoutubeGreySvg} alt="Youtube Logo" width="56" />
                                            :
                                            <img className="img-fluid" src={YoutubeLogoSvg} alt="Youtube Logo"  width="56" />
                                    }

                                </div>
                                <p className="label">YouTube</p>
                            </a>
                            <a href="#sv-account" className="acc-wrapper text-center bg-white">
                                <div className="img-wrapper mb-2">
                                    {
                                        props.social.syvid.length <= 0 ?
                                            <img className="img-fluid" src={SyvidGrey} alt="Youtube Logo" width="56" />
                                            :
                                            <img className="img-fluid" src={SyvidLogo} alt="Youtube Logo"  width="56" />
                                    }
                                </div>
                                <p className="label">SyVID</p>
                            </a>
                            <a href="#scr-account" className="acc-wrapper not-connected text-center bg-white">
                                <div className="img-wrapper mb-2">
                                    {
                                        props.social.scriptreel.length <= 0 ?
                                            <img className="img-fluid" src={ScriptReelGreySvg} alt="Youtube Logo" width="56" />
                                            :
                                            <img className="img-fluid" src={ScriptReeLogoSvg} alt="Youtube Logo"  width="56" />

                                    }

                                </div>
                                <p className="label">ScriptReel</p>
                            </a>
                            <a href="#tr-account" className="acc-wrapper not-connected text-center bg-white">
                                <div className="img-wrapper mb-2">
                                    {
                                        props.social.trimreel.length <= 0 ?
                                            <img className="img-fluid" src={TrimLogoGreyPng} alt="Youtube Logo" width="56" />
                                            :
                                            <img className="img-fluid" src={TrimLogoSvg} alt="Youtube Logo"  width="56" />
                                    }
                                </div>
                                <p className="label">TrimReel</p>
                            </a>
                            <a href="#lr-account" className="acc-wrapper text-center bg-white mr-0">
                                <div className="img-wrapper mb-2">
                                    {
                                        props.social.livereel.length <= 0 ?
                                            <img className="img-fluid" src={LiveReelGrey} alt="Youtube Logo" width="56" />
                                            :
                                            <img className="img-fluid" src={LiveReelSvg} alt="Youtube Logo"  width="56" />
                                    }
                                </div>
                                <p className="label">LiveReel</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="int-sec-2" className="bg-dark">
            <div className="container custom-wrapper">
                <div className="row">
                    <div className="col colmn-1 col-12 mb-4">
                        <div className="title-area">
                            <h4 className="text-white" style={{fontWeight:'500'}}>My Connections</h4>
                        </div>
                    </div>

                    {
                        props.social ?
                        <div className="col colmn-2 col-12">
                            <Facebook accounts={props.social.facebook}/>
                            <Youtube accounts={props.social.youtube} />
                            <Syvid accounts={props.social.syvid} click={handleClick} delete={handleDelete}/>
                            <ScriptReel accounts={props.social.scriptreel} click={handleClick} delete={handleDelete} />
                            <TrimReel accounts={props.social.trimreel} click={handleClick} delete={handleDelete} />
                            <LiveReel accounts={props.social.livereel} click={handleClick} delete={handleDelete} />
                            {modal.open ? <CommonModal appName={modal.appName} close={handleClose}/> : null }

                        </div>
                        : null
                    }

                </div>
                {confirmDelete.status ? <ConfirmDelete close={handleConfirmClose} confirm={handleConfirmDelete}/> : null }
            </div>
        </section>
        <Webinar />

        <Footer/>
        </body>
    )
}
const mapStateToProps = state => {
    return {
        notification:state.notification,
        social:state.social
    }
}
export default connect(mapStateToProps)(Integrations);
