import React, { useContext, useEffect, useRef } from "react";
import Navigation from "./Navigation/Navigation";
import Controls from "./Controls/Controls";
import PreviewControls from "./PreviewControls/PreviewControls";
import {fabric} from "fabric";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import windowSize from 'react-window-size';
import CanvasContext from "../../../../../Global/CanvasContext";
import "./style.css";

function Right(props) {
    const FabricCanvas = useContext(CanvasContext);
    const windowWidth = props.windowWidth;
    const windowHeight = props.windowHeight;
    let canvasHeight = windowHeight - 490;
    let canvasWidth = canvasHeight * (16 / 9);

    if (windowWidth <= 1366 && windowHeight <= 768) {
        canvasHeight = windowHeight - 390;
        canvasWidth = canvasHeight * (16 / 9);
    }

    if (windowWidth <= 991) {
        canvasWidth = (windowWidth * .88) - 100;
        canvasHeight = 9 * canvasWidth / 16;
    }

    if (windowWidth <= 767) {
        canvasWidth = (windowWidth * .92);
        canvasHeight = 9 * canvasWidth / 16;
    }
    if (FabricCanvas !== false) {

        FabricCanvas.setDimensions({
            width: canvasWidth,
            height: canvasHeight
        })
    }
    const handleClick = () => {
        const addText = new fabric.Textbox('Edit this text', {
            fill: '#ffffff',
            fontSize: 18,
            font:'Arial',
            fontWeight: 'normal',
            width: 100,
            textAlign: 'center',
        });
        addText.id = 'textbox';
        FabricCanvas.add(addText);
        addText.center();
        FabricCanvas.renderAll();
    }
    return (
        <div className="nav-wrapper w-100">
            <Navigation />
            <div className="preview-wrapper">
                <div className="controls-wrapper">
                    <Controls slayer={props.slayer} loader={props.loader} />
                    {/*<canvas id="prev-canvas" width="768" height="432" ref={props.node} />*/}
                    <div className="object-prev">
                        <ContextMenuTrigger id="rightClickMenu">
                            <canvas id="prev-canvas" ref={props.node} />
                        </ContextMenuTrigger>
                        <ContextMenu id="rightClickMenu">
                            <MenuItem data={{ foo: 'bar' }} onClick={handleClick}>
                                Add a Text Box
                            </MenuItem>
                        </ContextMenu>
                        <div className="loader-wrapper" ref={props.loader}>
                            <div className="preloader"> </div>
                        </div>
                    </div>
                    <PreviewControls slayer={props.slayer} loader={props.loader} canvasRef={props.node} />
                </div>
            </div>
        </div>
    )
}

export default windowSize(Right);