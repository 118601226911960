import {FETCH_ACCOUNTS, HIDE_SOCIAL_ACCOUNTS} from "../actions";
const initialState = {
    youtube:[],
    facebook:[],
    scriptreel:[],
    livereel:[],
    trimreel:[],
    syvid:[]
}

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_ACCOUNTS:
            return{
                youtube:action.payload.youtube,
                facebook:action.payload.facebook,
                scriptreel:action.payload.scriptreel,
                livereel:action.payload.livereel,
                trimreel:action.payload.trimreel,
                syvid:action.payload.syvid
            }
        case HIDE_SOCIAL_ACCOUNTS:
             return {
                 ...initialState
             }
        default:
            return {
                ...state
            }
    }
}