import { baseURL } from "../../../src/Global";
import axios from "axios";

export const fetchRebrandData = (token) => (dispatch, getState) => {
    let id = parseInt(getState().auth.user.user_id)
    axios({
        method: 'POST',
        url: `${baseURL}fetch-wl-rebranding`,
        data: { user_id: id },
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer' + ' ' + token }
    }).then(res => {
        if (res.data.status === true) {
            dispatch({ type: 'ADD_REBRAND', payload: res.data.data });
        } else {
            console.log("err fatch")
        }
    }).catch(error => {
        console.log(error)
        console.log("err 2 fatch")
    })
}


export const addRebrandData = (data, setLoader) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: `${baseURL}wl-rebranding`,
        data: data,
        headers: { 'Authorization': 'Bearer' + ' ' + getState().auth.token }
    }).then(res => {
        if (res.data.status === true) {
            console.log("succesfully add")
            dispatch(fetchRebrandData(getState().auth.token));
        } else {
            console.log("err add")
        }
        setLoader(false);
    }).catch(error => {
        setLoader(false);
        console.log("not add")
    })
}

export const removeRebrandData = (token) => (dispatch) => {
    dispatch({ type: 'REMOVE_ADD_REBRAND' });
}