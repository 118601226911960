import React, {useEffect} from "react";
import {ReactComponent as ImagePlusIcon} from "../../../../../assets/images/plus_1.svg";
import {ReactComponent as DownArrowIcon} from "../../../../../assets/images/down-arrow.svg";
import BackgroundMusic from "./BackgroundMusic";
import TextToSpeech from "./TextToSpeech";
import CurrentAudio from "./CurrentAudio";
import AudioRecord from "./AudioRecord";
import AudioUpload from "./AudioUpload";
import {baseURL} from "../../../../../../Global";
import store from "../../../../../../Store/store";
import {add_new_track} from "../../../../../../Store/actions/timelineActions";

function Audio() {
    return(
        <div id="audio-pick" className="accordion">
            <div className="btn-wrapper d-flex">
               <AudioRecord />
               <AudioUpload />
            </div>
            <hr className="editor_hr" />
            <BackgroundMusic />
            <hr className="editor_hr" />
            <TextToSpeech />
            <hr className="editor_hr" />
            <CurrentAudio />

        </div>
    );
}
export default Audio;