import React, {useEffect, useState} from "react";
import axios from "axios";
import {ReactComponent as  Waves} from "../../../assets/images/ico-audio-waves.svg";
import {ReactComponent as Right_arrow} from "../../../assets/images/right-arrow.svg";
import RecordingModal from "./RecordingModal/RecordingModal";
import error from "../../../../Store/reducers/error";
import store from "../../../../Store/store";
import {initiateUploadFile, uploadFile} from "../../../../Store/actions/uploadActions";
import UploadProgress from "../Upload/UploadProgress/UploadProgress";
import UploadComplete from "../Upload/UploadComplete/UploadComplete";
import {connect} from "react-redux";

function Record(props){
    const [record,setRecord] = useState({
        showModal:false,
        showProgress:false,
        showComplete:false
    })
    const {uploadedPercentage,isUploaded,type} = props.uploading;
    const openModel = () => {
        setRecord({
            showModal:true,
            showProgress:false,
            showComplete:false
        })
    }
    const closeModal = () => {
        setRecord({
            showModal:false,
            showProgress:false,
            showComplete:false
        })
    }
    const doneRecord = (blob) => {
        let data = new FormData();
        const file = new File([blob.blob], 'recording', { type: blob.type })
        data.append('file',file);
        data.append('file_type','recording');
        data.append('upload_type','transcribe');
        data.append('user_id',store.getState().auth.user.user_id);
        store.dispatch(initiateUploadFile());
        setRecord({
            showModal:false,
            showProgress:true,
            showComplete:false
        })
        store.dispatch(uploadFile(data,'record'));
    }

    const handleProgressClose = () => {
        setRecord({
            showModal:false,
            showProgress:false,
            showComplete:true
        })
    }
    const handleCompleteClose = () => {
        setRecord({
            showModal:false,
            showProgress:false,
            showComplete:false
        })
    }

    useEffect(() => {
        if(isUploaded && type === 'record')
        {
            setRecord({
                showModal:false,
                showProgress:false,
                showComplete:true
            })
        }
        return () => {
            setRecord({
                ...record,
                showModal:false,
                showProgress:false,
                showComplete:false
            })
        }
    },[isUploaded])
    return(
            <>
                <div id="record-audio" className="action-btn sub-txt d-flex align-items-center ml-auto mr-lg-0 mr-auto"
                     data-toggle="modal" data-target="#rec-aud" onClick={openModel}>
                    <span className="icon text-white pr-3">
                        <svg width="41px" height="42px" viewBox=" 0 0 41 42">
                            <Waves />
                        </svg>
                    </span>
                    <span className="flex-grow-1 text-white pl-3">Record Audio</span>
                    <span className="icon arrow" style={{color:'#424344'}}>
                        <svg width="28px" height="28px" viewBox="0 0 28 28">
                            <Right_arrow />
                        </svg>
                    </span>
                </div>
                {record.showModal ? <RecordingModal submitCall={doneRecord} close={closeModal} /> : null }
                {record.showProgress ? <UploadProgress  percentage={uploadedPercentage} onClose={handleProgressClose}/> : null }
                {record.showComplete ? <UploadComplete type="audio"  onClose={handleCompleteClose}/> : null }
            </>
    )
}
const mapStateToProps = state => {
    return {
        uploading: state.upload,
        notification: state.notification
    }
}

export default connect(mapStateToProps)(Record);
