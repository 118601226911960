
export default function getVideoElement(url){
    const videoE = document.createElement('video');
    videoE.muted = true;
    videoE.loop = true;
    videoE.crossOrigin = "anonymous";
    const source = document.createElement('source');
    source.src = url;
    source.type = 'video/mp4';
    videoE.appendChild(source);
    return new Promise(resolve => {
        videoE.addEventListener('canplay', (event) => {
            videoE.width = videoE.videoWidth;
            videoE.height = videoE.videoHeight;
             //console.log(videoE,'Can Play');
            resolve(videoE);
        });
    });
}