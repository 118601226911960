import {libraryURL, TOKEN} from "./index";
import axios from "axios";

export default function imageLibrary(data){
    const options = {
        headers:{
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+TOKEN,
        }
    }
    return axios.get(libraryURL+'get-photo/search?'+data, options)
        .then(res => {
            if(res.data.status === true)
            {
                return res.data.data;
            }
            else{
                return false;
            }
        })
}