import React from "react";

function AcadeablePDF(props) {
    return(
        <section id="tpc-section" className="section">
            <div className="container-fluid px-4">
                <div className="row">
                    <div className="col colmn-1 col-12 mb-4">
                        <div className="title-area text-center">
                            <h3 className="text-white">Acadeable Bonus</h3>
                        </div>
                    </div>
                    <div className="card-row d-flex flex-row flex-wrap w-100 mx-auto text-center tpc-section-wrap">
                        <a href="https://acadeable.s3.amazonaws.com/Acadeable_files.zip" target="_blank" style={{margin:'0 auto'}}>
                            <button className="btn btn-1 text-white">Download Acadeable</button>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default AcadeablePDF;