import * as React from "react";
import Footer from "../../Includes/Footer/Footer";
import {NavLink} from "react-router-dom";
import {useState} from "react";
import store from "../../../Store/store";
import axios from "axios";
import {baseURL} from "../../../Global";
import {displayNotification} from "../../../Store/actions/notificationAction";
import Notification from "../../Core/Notification/Notification";
import {connect} from "react-redux";
function ForgetPassword(props){
    const urlParams = new URLSearchParams(props.location.search);
    const token = urlParams.get('token');

    const [loading,setLoading] = useState({
        loading:false,
        buttonName:'Reset'
    });
    const [password,setPassword] = useState({
        password:false,
        confirm_password:false
    });

    const handleChange = (e) => {
        setPassword({
            ...password,
            [e.target.name] : e.target.value
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(password.password === password.confirm_password)
        {
            const config = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+store.getState().auth.token
            }
            setLoading({
                loading: true,
                buttonName: 'Sending...'
            });
            const data = {
                password:password.password,
                confirm_password:password.password,
                token:token
            }
            axios.post(baseURL+'reset-password',data,{headers:config}).then(res => {
                if(res.data.status === true)
                {
                    store.dispatch(displayNotification('success',res.data.message))
                }
                else
                {
                    store.dispatch(displayNotification('error',res.data.message))
                }
                setLoading({
                    loading: false,
                    buttonName: 'Reset'
                });
            }).catch(error => {})
        }
        else
        {
            store.dispatch(displayNotification('error','Passwords do not match'))
        }
    }

    return(
        <div className="login-page">
            <section className="login-content">
                <div className="container-fluid">
                    {props.notification.showNotification ? <Notification type={props.notification.notification.type} message={props.notification.notification.message}/> : null}
                    <div className="row mx-sm-auto align-items-center">
                        <div className="col colmn-1 col-lg-6 col-md-9 col-sm-10 col-12 mx-auto pr-lg-5">
                            <div className="content-wrapper text-center">
                                <div className="img-wrapper mb-4">
                                    <img className="img-fluid" src='../../assets/images/coursereel.png' alt="Logo" />
                                </div>
                                <h4 className="text-white font-weight-normal">
                                    Create Video Courses, VSL and more easily using moderner drag-n-drop based timeline editor
                                </h4>
                            </div>
                        </div>
                        <div className="col colmn-2 col-lg-6 col-md-9 col-sm-10 col-12 mx-auto pl-lg-5">
                            <form className="login-form" method="post" onSubmit={handleSubmit}>
                                <div className="form-title text-center mb-4">
                                    <h2 className="text-white">Reset Your Password</h2>
                                </div>
                                <div className="input-group mb-4">
                                    <input id="password" className="form-control" type="password" name="password"
                                           autoComplete="off" required onChange={handleChange}/>
                                    <label htmlFor="pass" className="text-white">Password</label>
                                    <span className="underline"> </span>
                                </div>
                                <div className="input-group mb-4">
                                    <input id="password" className="form-control" type="password" name="confirm_password"
                                           autoComplete="off" required onChange={handleChange}/>
                                    <label htmlFor="pass" className="text-white">Confirm Password</label>
                                    <span className="underline"> </span>
                                </div>
                                <div className="btn-wrapper text-center mb-4">
                                    <button className="btn btn-1 text-white text-uppercase" type="submit">{loading.buttonName}</button>
                                </div>
                                <div className="form-footer text-center">

                                    <NavLink to="/login"> <p className="text-white sm-txt"> Have Login ? </p> </NavLink>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
const mapStateToProps = state => {
    return {
        notification:state.notification
    }
}
export default connect(mapStateToProps)(ForgetPassword);