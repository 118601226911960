import React, {useEffect} from "react";
import Plyr from "plyr";
import 'plyr/dist/plyr.css';
import {ReactComponent as Close} from '../../../assets/images/close.svg';
let playerPlyr = false;
function Video(props){

    const destroyPlayer = () => {
        console.log('asdasd',playerPlyr);
        if (playerPlyr.length > 0) {
            playerPlyr[0].pause();
            // for (const playerEl of player) {
            //     playerEl.pause();
            // }
        }
    }
    useEffect(() => {
        playerPlyr = Plyr.setup('#player');
        return () => {
            if (playerPlyr.length > 0) {
                playerPlyr[0].pause();
                // for (const playerEl of player) {
                //     playerEl.pause();
                // }
            }
        }
    },[])

    return(
        <div id="watch-video" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered mx-auto" role="document">
                <div className="modal-content mx-auto">
                    <button onClick={destroyPlayer} type=" button" className="close mb-3 text-right" data-dismiss="modal"
                            aria-label="Close"
                            style={{opacity:'1'}}>

                        <Close />
                    </button>
                    <div className="modal-body border-0 p-0" style={{boxShadow:"none"}}>
                        <div className="vid-wrapper">
                            <div id="player" className="plyr__video-embed" data-plyr-provider='vimeo'
                                 data-plyr-embed-id='448070209'>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Video