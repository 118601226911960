import React, { createContext, useEffect, useState } from 'react';
import Login from "./Pages/Login/Login";
import './App.css';
import './responsive.css';
import ForgetPassword from "./Pages/ForgotPassword/ForgetPassword";
import Dashboard from "./Core/Dashboard/Dashboard";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import store from "../Store/store";
import { verifyToken } from "../Store/actions/authActions";
import { connect } from "react-redux";
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import Projects from "./Core/Projects/Projects";
import Editor from "./Core/Editor/Editor";
import NotFound from "./Pages/NotFound/NotFound";
import Integrations from "./Core/Integrations/Integrations";
import Billing from "./Core/Billing/Billing";
import MyAccount from "./Core/MyAccount/MyAccount";
import Logout from "./Pages/Logout/Logout";
import Account from "./Core/Accounts/Account";
import Support from "./Core/Support/Support";
import DfyCourse from "./Core/DfyCourse/DfyCourse";
import DfyCourseLite from "./Core/DfyCourse/DfyCourseLite";
import Business from "./Core/Business/Business";
import Acadeable from "./Core/Acadeable/Acadeable";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import TeamMember from "./Core/Team/TeamMember";
import ClientAccount from "./Core/Team/ClientAccount";
import Rebranding from './Core/Upgrade/Rebranding';

export const AuthenticationContext = createContext({})
function App(props) {
   
    const token = localStorage.getItem('token');

    // use Effect is used to call the authentication only once . Otherwise if the user fails it will create an infinite loop
    useEffect(() => {
        if (token !== null && props.auth.isAuthenticated === false) {
            const isAuth = !store.getState().auth.isAuthenticated ? store.dispatch(verifyToken()) : null

        }
    }, [token])
    return (
        <>
            {props.auth.isAuthenticated ?
                <BrowserRouter>
                    <AuthenticationContext.Provider value={props.auth}>
                        {console.log('Hello Authenticated ')}
                        <Switch>
                            <Route exact path="/team-member" component={TeamMember} />
                            <Route exact path="/acadeable" component={Acadeable} />
                            <Route exact path="/business" component={Business} />
                            <Route exact path="/dfy-course-lite" component={DfyCourseLite} />
                            <Route exact path="/dfy-course" component={DfyCourse} />
                            <Route exact path="/support" component={Support} />
                            <Route exact path="/account" component={Account} />
                            <Route exact path="/sign-out" component={Logout} />
                            <Route exact path="/my-account" component={MyAccount} />
                            <Route exact path="/integrations" component={Integrations} />
                            <Route exact path="/dashboard" component={Dashboard} />
                            <Route exact path="/projects" component={Projects} />
                            <Route exact path="/editor/:id?" component={Editor} />
                            <Route exact path="/" component={Dashboard} />
                            <Route exact path="/rebranding" component={Rebranding} />
                            <Route path="*" component={Login} />
                        </Switch>
                    </AuthenticationContext.Provider>
                </BrowserRouter> :
                <BrowserRouter>
                    <AuthenticationContext.Provider value={false}>
                        {console.log('Hello Not Authenticated ')}
                        <Switch>
                            <Route exact path="/account" component={Login} />
                            <Route exact path="/sign-out" component={Login} />
                            <Route exact path="/my-account" component={Login} />

                            <Route exact path="/integrations" component={Login} />
                            <Route exact path="/dashboard" component={Login} />
                            <Route exact path="/projects" component={Login} />
                            <Route exact path="/editor/:id?" component={Login} />
                            <Route exact path="/" component={Login} />

                            <Route exact path="/reset-password" component={ResetPassword} />
                            <Route exact path="/forget-password" component={ForgetPassword} />
                            <Route exact path="/" component={Login} />
                            <Route exact path="/login" component={Login} />
                            <Route path="*" component={NotFound} />
                        </Switch>
                    </AuthenticationContext.Provider>
                </BrowserRouter>
            }
        </>
    )
}
const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}
export default connect(mapStateToProps)(App);
