import React from "react";
import Menu from "../../Includes/Menu/Menu";
import store from "../../../Store/store";
import SupportHeader from "../Support/SupportHeader";

import Footer from "../../Includes/Footer/Footer";
import "../Support/style.css";
function DfyCourse(props) {
    return(
        <body>
        <header id="app-header" className="position-fixed w-100">
            <Menu profile={store.getState().auth.user.profile_pic} location={props.location}/>
        </header>
        <div id="rep-content">
            <SupportHeader />
            <section id="vid-tuts" className="section bg-dark min-vh-100">
                <div className="container px-4">
                    <div className="row">
                        <div className="col colmn-1 col-12 mb-4">
                            <div className="title-area text-center">
                                <h3 className="text-white">Done For You Courses </h3>
                            </div>
                        </div>
                        <div className="col-md-6 text-center">
                            <div className="btn-wrapper">
                                <a href="https://coursereel-app.s3-us-west-2.amazonaws.com/CourseEngagementHacks.zip" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download DFY Course 1</a>
                            </div>
                            <div className="btn-wrapper">
                                <a href="https://coursereel-app.s3-us-west-2.amazonaws.com/EcommerceWithWoocommerce.zip" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download DFY Course 2</a>
                            </div>
                            <div className="btn-wrapper">
                                <a href="https://coursereel-app.s3-us-west-2.amazonaws.com/GoogleAdsMastery.zip" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download DFY Course 3</a>
                            </div>
                            <div className="btn-wrapper">
                                <a href="https://coursereel-app.s3-us-west-2.amazonaws.com/HowToUseWebinarsForYourBusiness.zip" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download DFY Course 4</a>
                            </div>
                            <div className="btn-wrapper">
                                <a href="https://coursereel-app.s3-us-west-2.amazonaws.com/IMForNewbiesDeluxe.zip" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download DFY Course 5</a>
                            </div>

                            <div className="btn-wrapper">
                                <a href="https://coursereel-app.s3-us-west-2.amazonaws.com/ModernPodcastingUp.zip" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download DFY Course 6</a>
                            </div>
                            <div className="btn-wrapper">
                                <a href="https://coursereel-app.s3-us-west-2.amazonaws.com/ProvenAffiliateMarketingConcepts.zip" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download DFY Course 7</a>
                            </div>
                            <div className="btn-wrapper">
                                <a href="https://coursereel-app.s3-us-west-2.amazonaws.com/SimpleSocialMediaContent.zip" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download DFY Course 8</a>
                            </div>
                            <div className="btn-wrapper">
                                <a href="https://coursereel-app.s3-us-west-2.amazonaws.com/TIKTOKADSMASTERY.zip" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download DFY Course 9</a>
                            </div>
                            <div className="btn-wrapper">
                                <a href="https://coursereel-app.s3-us-west-2.amazonaws.com/UdemyForRecurringIncome.zip" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download DFY Course 10</a>
                            </div>
                        </div>
                        <div className="col-md-6 text-center">
                         

              

                      
                       <div className="btn-wrapper">
                                <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/HealthyBoundaries_GOLD.zip" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download DFY Course 11</a>
                            </div>

                            <div className="btn-wrapper">
                                <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/GOLD-WorkFromHome.zip" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download DFY Course 12</a>
                            </div>

                            <div className="btn-wrapper">
                                <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/GOLD-PersonalBrandingBlueprint.zip" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download DFY Course 13</a>
                            </div>

                            <div className="btn-wrapper">
                                <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/FacebookAdSecrets.zip" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download DFY Course 14</a>
                            </div>

                            <div className="btn-wrapper">
                                <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/EmailMarketingBasicsVideo.zip" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download DFY Course 15</a>
                            </div>

                            <div className="btn-wrapper">
                                <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/EcommerceWithWoocommerce.zip" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download DFY Course 16</a>
                            </div>
                            <div className="btn-wrapper">
                                <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/DealClosingSecrets.zip" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download DFY Course 17</a>
                            </div>

                            <div className="btn-wrapper">
                                <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/CourseEngagementHacks.zip" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download DFY Course 18</a>
                            </div>

                            <div className="btn-wrapper">
                                <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/ClicksAndTraffic.zip" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download DFY Course 19</a>
                            </div>

                            
                            <div className="btn-wrapper">
                                <a href="https://acadeable.s3.amazonaws.com/dfycoursex196/AntiAnexietyFormulaVideoUp.zip" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download DFY Course 20</a>
                            </div>
                       </div>



                        <div className="col-md-12 text-center mt-5">
                            <div className="btn-wrapper">
                                <a href="https://support.vineasx.com" target="_blank" className="btn btn-3 pricing-btn" style={{maxWidth:'300px'}}>Request Custom Course</a>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
        <Footer/>
        </body>
    )
}
export default DfyCourse;






