import React from "react";
import Menu from "../../Includes/Menu/Menu";
import store from "../../../Store/store";
import SupportHeader from "../Support/SupportHeader";

import Footer from "../../Includes/Footer/Footer";
import "../Support/style.css";
function DfyCourseLite(props) {
    return(
        <body>
        <header id="app-header" className="position-fixed w-100">
            <Menu profile={store.getState().auth.user.profile_pic} location={props.location}/>
        </header>
        <div id="rep-content">
            <SupportHeader />
            <section id="vid-tuts" className="section bg-dark min-vh-100">
                <div className="container px-4">
                    <div className="row">
                        <div className="col colmn-1 col-12 mb-4">
                            <div className="title-area text-center">
                                <h3 className="text-white">Done For You Courses Lite</h3>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="btn-wrapper">
                                <a href="https://coursereel-app.s3-us-west-2.amazonaws.com/CourseEngagementHacks.zip" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download DFY Course 1</a>
                            </div>
                            <div className="btn-wrapper">
                                <a href="https://coursereel-app.s3-us-west-2.amazonaws.com/EcommerceWithWoocommerce.zip" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download DFY Course 2</a>
                            </div>
                            <div className="btn-wrapper">
                                <a href="https://coursereel-app.s3-us-west-2.amazonaws.com/GoogleAdsMastery.zip" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download DFY Course 3</a>
                            </div>
                            <div className="btn-wrapper">
                                <a href="https://coursereel-app.s3-us-west-2.amazonaws.com/HowToUseWebinarsForYourBusiness.zip" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download DFY Course 4</a>
                            </div>
                            <div className="btn-wrapper">
                                <a href="https://coursereel-app.s3-us-west-2.amazonaws.com/IMForNewbiesDeluxe.zip" target="_blank" className="btn btn-2 w-100 mb-2" style={{maxWidth:'300px'}}>Download DFY Course 5</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <Footer/>
        </body>
    )
}
export default DfyCourseLite;