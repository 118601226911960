import React, {useState} from "react";
import store from "../../../Store/store";
import axios from "axios";
import {baseURL} from "../../../Global";
import {displayNotification} from "../../../Store/actions/notificationAction";
import Closesvg from "../../assets/images/close.svg"
import SyvidLogo from "../../assets/images/logo-syvideo.svg"
import LogoTrimSvg from "../../assets/images/logo-trim.svg"
import LiveReelSvg from "../../assets/images/logo-livereel.svg"
import ScriptReelSvg from "../../assets/images/logo-scriptreel.svg"
 
function CommonModal(props) {
    const [details, setDetails] = useState({
        email:false,
        password:false,
        isLoading:false,
        buttonText:'Connect'
    })
    const [notify,setNotify] = useState({
        type:false,
        message:false
    })
    const handleChange = (e) => {
        setDetails({
            ...details,
            [e.target.name] : e.target.value
        })
    }
    const config = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+store.getState().auth.token
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setDetails({
            ...details,
            isLoading: true,
            buttonText:'Connecting...'
        })
        const data = {
            user_id:store.getState().auth.user.user_id,
            email:details.email,
            password:details.password,
            app_name:props.appName
        };
        axios.post(baseURL+'connect-to-app',data,{headers:config}).then(res => {
            if(res.data.status === true)
            {
                store.dispatch(displayNotification('success',res.data.message));
                props.close();
            }
            else
            {
                setNotify({
                    type:'danger',
                    message:res.data.message,
                })
                //store.dispatch(displayNotification('error',res.data.message))
            }
            setDetails({
                ...details,
                isLoading: false,
                buttonText:'Connect'
            })
        }).catch(error => {})
    }
    let image = false;
    switch (props.appName) {
        case 'syvid':
            image = SyvidLogo;
            break;
        case 'trimreel':
            image = LogoTrimSvg;
            break;
        case 'livereel':
            image = LiveReelSvg;
            break;
        case 'scriptreel':
            image =ScriptReelSvg;
            break;

    }
    return(
        <div id="publish" className="modal fade show" tabIndex="-1" role="dialog" data-backdrop="static"
             aria-labelledby="modal-title"
             aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered mx-auto" role="document">
                <div className="modal-content">
                    <button onClick={props.close} type="button" className="close mb-3 text-right" data-dismiss="modal" aria-label="Close"
                            style={{opacity:'1'}}>
                        <img className="img-fluid" src={Closesvg} alt="Close" />
                    </button>
                    <div className="modal-body bg-white text-center">
                        <div className="row inner-row icons-wrapper justify-content-center mb-4 mx-auto">
                            <div className="icon-wrapper text-center mr-3">
                                <div  className="img-wrapper soc-icon mx-auto mb-2">
                                    <img className="img-fluid" src={image}
                                         alt="Logo" />
                                </div>
                            </div>
                        </div>
                        <h4 className="modal-title mb-4" style={{lineHeight:'1.33',color:'#000000'}}>Connect your {props.appName} account </h4>
                        {notify.type ? <div className={`alert alert-${notify.type}`}>{notify.message}</div> :null}
                        <div className="mb-2">
                            <form method="POST" onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <input type="email" name="email"
                                           className="form-control" placeholder="Email" autoComplete
                                           required onChange={handleChange}/>
                                </div>
                                <div className="form-group">
                                    <input type="password" name="password"
                                           className="form-control" placeholder="Password" autoComplete
                                           required onChange={handleChange}/>
                                </div>
                                <button id="pub-proj" type="submit" className="btn btn-3">{details.buttonText}</button>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default CommonModal;