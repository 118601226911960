import React from "react";
import { ReactComponent as SearchIcon } from "../../../assets/images/search.svg";
import { NavLink } from "react-router-dom";
import plussvg from "../../../assets/images/plus.svg"
function Search(props) {
    return (
        <section id="proj-title-bar">
            <div className="container-fluid px-4">
                <div className="row align-items-center">
                    <div className="col colmn-1 col-sm-6 col-12 mb-sm-0 mb-4">
                        <div className="title-area text-left d-flex align-items-center">
                            <h1 className="md-txt text-white">All Projects</h1>
                            <span>|</span>
                            <p className="no-of-proj sm-txt">{props.count === 0 ? '0 Project' : props.count === 1 ? '1 Project' : props.count + ' Projects'}</p>
                        </div>
                    </div>
                    <div className="col colmn-2 col-sm-6 col-12 text-right">
                        <div className="row inner-row align-items-center justify-content-sm-end flex-nowrap mx-auto">
                            <form action="#" method="post" className="d-block w-100 mr-2" style={{ maxWidth: '270px' }}>
                                <div className="input-group">
                                    <input id="search" type="search" name="search" className="form-control"
                                        placeholder="Search" 
                                        onChange={(e) => props.setQ(e.target.value)}
                                        value={props.q}
                                        />
                                    <div className="input-group-prepend">
                                        <svg width="18px" height="18px" viewBox=" 0 0 18 18">
                                            <SearchIcon />
                                        </svg>
                                    </div>
                                </div>
                            </form>
                            <NavLink to="/editor">
                                <button id="add-proj" className="btn btn-3 sm-txt ml-1" type="button">
                                    <img className="img-fluid" src={plussvg} alt="Plus"
                                        style={{ margin: 'margin: 0 5px 1px 0' }} /> New Project
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Search;