import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addRebrandData, removeRebrandData } from '../../../Store/actions/rebrandAction'
import swal from "sweetalert";
import axios from "axios";
import { baseURL } from '../../../Global';
import Menu from '../../Includes/Menu/Menu';
import Footer from '../../Includes/Footer/Footer'
import SweetAlert from "react-bootstrap-sweetalert";



const Rebranding = (props) => {

    const [location, setlocation] = useState({ pathname: 'upgrade' });
    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);
    const rebrandData = useSelector(state => state.rebrand);
    const [rebrand, setRebrand] = useState(true); // assign rebrandData here
    const [loader, setLoader] = useState(false);

    const [fileData, setFileData] = useState('');
    const [name, setName] = useState('');

    // Sweetalert
    const [button, setButton] = useState('Delete');
    const [deleteData, setDeletedData] = useState({
        user_id: auth.user.user_id,
        id: ''
    });
    const [showDelete, setShowDelete] = useState(false);

    const onConfirm = async () => {
        setButton('Deleting...')
        let options = {
            headers: {
                'Authorization': "Bearer" + " " + auth.token
            }
        }

        await axios.post(`${baseURL}delete-wl`, deleteData, options).then(res => {
            if (res.data.status === true) {
                dispatch(removeRebrandData(auth.token))
            } else {
                console.log("user false")
            }
            setLoader(false);
        }).catch(err => {
            setLoader(false);
        })

        setShowDelete(false);
        setButton('Delete')
    }

    const onCancel = () => {
        setShowDelete(false);
    }

    const removeWl = (id) => {
        setDeletedData({ ...deleteData, id: id });
        setShowDelete(true);
    }

    const onInputFile = (e) => {
        setFileData(e.target.files[0]);
    }

    const onInputName = (e) => {
        setName(e.target.value);
    }

    const onFormSubmit = (e) => {
        e.preventDefault();

        if (fileData.type === 'image/jpg' || fileData.type === 'image/png' || fileData.type === 'image/jpeg' || fileData === 'image/JPEG') {
            if (fileData.size < 5000000) {
                setLoader(true);
                let formData = new FormData();
                formData.append('name', name);
                formData.append('logo', fileData);
                formData.append('user_id', parseInt(auth.user.user_id));
                dispatch(addRebrandData(formData, setLoader));

            } else {
                swal("Oops!", "Max Uploaded Size for Image 5MB");
            }
        } else {
            swal("Oops!", "You have Selected Invalid File Type");
        }
    }

    useEffect(() => {
        setRebrand(rebrandData);
    }, [rebrandData])

    return (
        <>
            <body style={{ backgroundColor: "#171717" }}>
                <Menu profile={auth.user.profile_pic} location={location} />
                <div className="upgrade-content my-5">
                    <h3>Rebranding</h3>
                    <form className="row  mt-5 bg-table justify-content-center align-items-center" method="post" onSubmit={(e) => onFormSubmit(e)}>
                        <div className="col-11 p-0" style={{ height: "200px", backgroundColor: "#000000", border: "2px dashed #fff" }}>
                            <div style={{ height: "100%", width: "100%", cursor: "pointer" }} >
                                <div className="upload-btn-wrapper">
                                    <div className="d-flex mt-4 justify-content-center align-items-center flex-column">
                                        <button
                                            style={{
                                                backgroundColor: "#FFF",
                                                color: "#BE56B0",
                                                border: "none",
                                                fontSize: "1rem"
                                            }}
                                        >
                                            Upload your brand logo (JPG, PNG, JPEG upto 5MB is allowed) <br /> (210x34) Pixels recommended
                                        </button>
                                        <i
                                            className="fas fa-cloud-upload-alt"
                                            aria-hidden="true"
                                            style={{
                                                display: "block",
                                                color: "#BE56B0",
                                                fontSize: "1.7rem",
                                                paddingTop: 10
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <input
                                type="file"
                                className="custom-file-input"
                                id="customFile"
                                name="file"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    padding: 0,
                                    cursor: "pointer"
                                }}
                                onChange={(e) => onInputFile(e)}
                            />
                        </div>
                        <div className="col col-lg-6 col-12 mt-4 p-1">
                            <div className="form-group form-group-mb">
                                <label className="input-label" style={{ color: 'white' }}>Brand Name </label>
                                <input
                                    style={{ backgroundColor: "#FFF" }}
                                    className="form-control input-dark-accnt brandonfocus personal-info  w-100"
                                    type="text"
                                    name="firstName"
                                    placeholder="Enter your brand Name"
                                    required
                                    onChange={(e) => onInputName(e)}
                                />
                            </div>
                        </div>
                        <div className="col col-md-12 text-center my-3">
                            <button
                                type="submit"
                                className="demoLink mt-0"
                                disabled={loader}
                            >
                                {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Save
                            </button>
                        </div>
                        {
                            rebrand.data !== false ?
                                <button
                                    type="button" onClick={(e) => removeWl(rebrand.data.id)}
                                    className="demoLink mt-0">
                                    Remove Rebranding
                                </button>
                                : ''
                        }
                    </form>
                </div>


                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText={button}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="light"
                    title="Are you sure?"
                    onConfirm={(e) => onConfirm(e)}
                    onCancel={(e) => onCancel(e)}
                    focusCancelBtn
                    show={showDelete}
                >
                    Do you want to delete this Rebranding
                </SweetAlert>
                <Footer />
            </body>
        </>
    )
}

export default Rebranding