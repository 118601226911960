import React from "react";
import ScriptReelGreyPng from "../../../assets/images/logo-scriptreel-grey.png"
import ScriptReelLogoSvg from "../../../assets/images/logo-scriptreel.svg"


function ScriptReel(props) {
    return(
        <div id="scr-account"
             className={`acc-details-wrapper row mx-auto p-4 mb-3 bg-white ${props.accounts.length > 0 ? '' : 'not-connected'}`}>
            <div className="img-wrapper pr-2">
                {
                    props.accounts.length <= 0 ?
                        <img className="img-fluid" src={ScriptReelGreyPng} alt="ScriptReel Logo" />
                        :
                        <img className="img-fluid" src={ScriptReelLogoSvg} alt="ScriptReel Logo" />
                }

            </div>
            <div className="inner-row-wrapper flex-grow-1">
                {
                    props.accounts.length <= 0 ?
                        <div className="inner-row d-flex flex-row align-items-center">
                            <div className="acc-details flex-grow-1 px-2">
                                <h6 className="mb-1">
                                    <span className="md-txt">ScriptReel</span>
                                </h6>
                                <p className="status">
                                    Not Connected
                                </p>
                            </div>
                            <div className="btns-wrapper d-flex pl-lg-3">
                                <button onClick={() => props.click('scriptreel')} className="btn btn-2 w-100 mr-2 connect">Connect</button>
                            </div>
                        </div>
                    :
                        <>
                            {props.accounts.map(account =>
                                <div className="inner-row d-flex flex-row align-items-center">
                                    <div className="acc-details flex-grow-1 px-2">
                                        <h6 className="mb-1">
                                            <span className="md-txt">ScriptReel </span><span
                                            className="usr-email md-txt">{account.email}</span>
                                        </h6>
                                        <p className="status">
                                            {/*<span className="security-number sm-txt">Security Number 1234-5678-9098-7654</span> */}
                                            added
                                            <span className="date-added sm-txt">{account.created}</span>
                                        </p>
                                    </div>
                                    <div className="btns-wrapper d-flex pl-lg-3">
                                        {/*<a href="#" className="btn btn-2 reconnect w-100 mr-2">Reconnect</a>*/}
                                        <button className="btn btn-2 delt w-100 ml-2" onClick={() => props.delete(account.id)}>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            )}
                            <div className="conn-btn-wrapper text-center">
                                <button onClick={() => props.click('scriptreel')} className="btn btn-2 w-100 mr-2 connect">Connect New Account </button>
                            </div>
                        </>

                }


            </div>
        </div>
    )
}

export default ScriptReel