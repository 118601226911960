import React from "react";
function Gdpr() {
    return(
        <div className="row row-1 mb-lg-5 mb-4 d-none">
            <div className="col col-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="text-white">Manage Your Data</h4>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col col-lg-3 col-md-6 col-12 p-2">
                                <button className="btn btn-3">Change Email</button>
                            </div>
                            <div className="col col-lg-3 col-md-6 col-12 p-2">
                                <button className="btn btn-3">Download my data</button>
                            </div>
                            <div className="col col-lg-3 col-md-6 col-12 p-2">
                                <button className="btn btn-3">Stop processing my data</button>
                            </div>
                            <div className="col col-lg-3 col-md-6 col-12 p-2">
                                <button className="btn btn-3">Delete my account</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Gdpr;