import React, {useState} from "react";
import Closesvg from "../../assets/images/close.svg";
import Warningsvg from "../../assets/images/warning.svg";

function ConfirmDelete(props) {
    const [buttonText, setButtonText] = useState('Yes, Delete Now');
    const handleConfirm = () => {
        setButtonText('Deleting...');
        props.confirm();
    }
    return (
        <div id="confirm-del" className="modal fade show" tabIndex="-1" role="dialog" data-backdrop="static"
             aria-labelledby="delete-warn" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered mx-auto" role="document">
                <div className="modal-content">
                    <button type="button" className="close mb-3 text-right" onClick={props.close}
                            style={{opacity:'1'}}>
                        <img className="img-fluid" src={Closesvg} alt="Close" />
                    </button>
                    <div className="modal-body bg-white text-center">
                        <div className="img-wrapper mb-3 pb-1">
                            <img className="img-fluid" src={Warningsvg} alt="Warning" />
                        </div>
                        <h4 className="modal-title mb-2" id="delete-warn" style={{color:'#000000'}}>Delete Warning</h4>
                        <p className="w-100 mx-auto mb-3 pb-3">
                            Are you sure you want to delete this ? This action is permanent.
                        </p>
                        <div className="modal-footer border-top-0 p-0 justify-content-center">
                            <button type="button" className="btn btn-2" onClick={props.close}>Cancel</button>
                            <button id="confirm" type="button" className="btn btn-3 text-white" onClick={handleConfirm}>{buttonText}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ConfirmDelete;