import React, {useState} from 'react';
import { ReactComponent as RightArrow } from "../../../assets/images/right-arrow.svg";
import Project from "../../Projects/Project/Project";
import { NavLink } from "react-router-dom";
import PublishModal from "../../PublishModal/PublishModal";
import store from "../../../../Store/store";
import axios from "axios";
import {baseURL} from "../../../../Global";
import {projectFetch} from "../../../../Store/actions/projectAction";
import {displayNotification} from "../../../../Store/actions/notificationAction";
import ConfirmDelete from "../../ConfirmDelete/ConfirmDelete";
function Projects(props) {
    const projects = props.projects.projects.slice(0, 4);

    const [publish,setPublish] = useState({
        url:false,
        open:false
    });

    const openPublish = (url) => {
        setPublish({
            url:url,
            open: true
        });
    }

    const closePublish = () => {
        setPublish({
            url:false,
            open: false
        });
    }
    const [confirmDelete, setConfirmDelete] = useState({
        status:false,
        id:0
    });

    const handleDelete = (id) => {
        setConfirmDelete({
            status:true,
            id:id
        })
    }

    const handleConfirmClose = () => {
        setConfirmDelete({
            status:false,
            id:0
        })
    }

    const handleConfirmDelete = () => {
        const data = {
            user_id: store.getState().auth.user.user_id,
            project_id: confirmDelete.id
        };
        const config = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + store.getState().auth.token
        }
        axios.post(baseURL + 'project-delete', data, { headers: config }).then(res => {
            if (res.data.status === true) {
                const data = 'user_id=' + store.getState().auth.user.user_id;
                store.dispatch(projectFetch(data));
                store.dispatch(displayNotification('success', res.data.message));
            }
            else {
                store.dispatch(displayNotification('error', res.data.message))
            }
            handleConfirmClose();
        }).catch(error => { })
    }

    return (
        <section id="project-section" className="bg-dark">
            <div className="container-fluid px-4">
                <div className="row row-1 align-items-end mb-3 pb-3">
                    <div className="col colmn-1">
                        <div className="title-area">
                            <h3 className="text-white" style={{ fontWeight: '500' }}>Video Projects</h3>
                        </div>
                    </div>
                    <div className="col colmn-2 text-right">
                        <NavLink to="/projects" className="view-all flex-elem justify-content-end font-weight-bold" href="#" style={{ color: "#d75348" }}>
                            View All
                            <span className="icon arrow pl-2" style={{ color: "#d75348" }}>
                                <svg width="18px" height="18px" viewBox="0 0 28 28">
                                    <RightArrow />
                                </svg>
                            </span>
                        </NavLink>
                    </div>
                </div>
                <div className="row row-2">
                    {/*
                        Maximum 4 Items
                        Status = 0 => Initialization
                        Status = 1 => Transcribing
                        Status = 2 => Success
                        Status = 3 => Error
                        Status = 4 => Awaiting Customization
                        Status = 5 => Rendering
                    */
                    }
                    {projects.length > 0 ? projects.map((element,index) => <Project key={index} delete={handleDelete}  project={element} onPublish={openPublish}/>) : <p className="text-white margin-center">No projects in your account</p>}
                </div>

                {publish.open ? <PublishModal close={closePublish} url={publish.url}/> : null }
                {confirmDelete.status ? <ConfirmDelete close={handleConfirmClose} confirm={handleConfirmDelete}/> : null }
            </div>
        </section>
    );
}
export default Projects;