import React from "react";
function SupportHeader() {
    return(
        <section id="sup-banner" className="section">
            <div className="container-fluid px-4">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-6 col-12">
                       <div className="title-area text-center mb-2 pb-1">
                           <h3 className="">What do you want to know?</h3>
                       </div>
                       <form method="GET" action="#">
                           <div className="input-groupv">
                               <input id="search-tpc" className="form-control" type="search" name="search"
                                     placeholder="Search" />
                                   <div className="input-group-append">
                                       {/* <svg width="24px" height="24px" viewBox=" 0 0 24 24">
                                           <use xlink:href="./assets/images/search.svg#search-ico"></use>
                                       </svg> */}
                                   </div>
                           </div>
                       </form>
                    </div>
                    {/* <div className="col colmn-2 col-lg-6 col-12">
                       <div className="img-wrapper text-lg-left text-center">
                           <img className="img-fluid" src="./assets/images/illo-create-platform.svg" alt="Graphics" />
                       </div>
                    </div> */}
                </div>
            </div>
        </section>
    )
}
export default SupportHeader;