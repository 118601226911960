import React, {useEffect, useState} from "react";
import {ReactComponent as Right_arrow} from "../../../assets/images/right-arrow.svg";
import {ReactComponent as Video_icon} from "../../../assets/images/ico-video.svg";
import UploadProgress from "./UploadProgress/UploadProgress";
import store from "../../../../Store/store";
import {initiateUploadFile, uploadFile} from "../../../../Store/actions/uploadActions";
import UploadComplete from "./UploadComplete/UploadComplete";
import Toast from "react-bootstrap/Toast";
import {connect} from "react-redux";
import Notification from "../../Notification/Notification";
import notification from "../../../../Store/reducers/notification";

function Upload (props){
    const [uploadDetails, setUploadDetails] = useState({
        maxSize:200,
        type:'video',
        fileFormat:[".mp4",".mp3"],
        openProgressPopup:false,
        openCompletePopup:false,
    })
    const handleChange = (e) =>{
        const fileSize = e.target.files[0].size/(1024*1024);
        const fileName = e.target.files[0].name;
        if(fileSize < uploadDetails.maxSize)
        {
            const allowedFiles = uploadDetails.fileFormat.map(fileType => fileType);
            const fileExtensionAr = fileName.split('.');
            const fileExtension = fileExtensionAr[fileExtensionAr.length - 1];
            //const regex = new RegExp("([a-zA-Z0-9\s_\\.\-:])+(" + allowedFiles.join('|') + ")$")
            //regex.test(fileName.toLowerCase()
            //console.log(fileExtension);
            if(fileExtension === 'mp4' || fileExtension === 'mp3')
            {
                setUploadDetails({
                    ...uploadDetails,
                    type:fileExtension === 'mp4' ? 'video' : 'audio',
                    openProgressPopup:true
                });
                // Send file through Axios
               let data = new FormData();
               data.append('file',e.target.files[0]);
               data.append('file_type',uploadDetails.type);
               data.append('upload_type','transcribe');
               data.append('user_id',store.getState().auth.user.user_id);

               store.dispatch(initiateUploadFile());
               store.dispatch(uploadFile(data,'video'));
            }
            else
            {
                // file format is invalid
                alert('file format is invalid');
            }
        }
        else
        {
            // File size is greater
            alert('File size is Greater');
        }

    }
    const handleCompleteClose = () => {
        setUploadDetails({
            ...uploadDetails,
            openCompletePopup: false
        })
    }
    const handleProgressClose = () => {
        setUploadDetails({
            ...uploadDetails,
            openProgressPopup:false
        })
    }
    const {uploadedPercentage,isUploaded,type} = props.uploading;
    useEffect(() => {
        if(isUploaded && type === 'video')
        {
            setUploadDetails({
                ...uploadDetails,
                openProgressPopup:false,
                openCompletePopup:true
            });
        }
    }, [isUploaded]);


    const {openProgressPopup,openCompletePopup} = uploadDetails;

    return(
        <>
            {props.notification.showNotification ? <Notification type={props.notification.notification.type} message={props.notification.notification.message}/> : null}

            <div id="upload-video" className="action-btn sub-txt d-flex align-items-center ml-auto mr-lg-0 mr-auto mb-2">
                <input type="file" name="file" id="up-video" className="file-type"  accept="video/mp4,video/x-m4v,video/*"  onChange={handleChange}/>
                <span className="icon text-white pr-3">
                    <svg width="40px" height="36px" viewBox="0 0 40 36">
                        <Video_icon />
                    </svg>
                </span>
                <span className="flex-grow-1 text-white pl-3">Upload Video / Audio</span>
                <span className="icon arrow" style={{color:'#424344'}}>
                    <svg width="28px" height="28px" viewBox="0 0 28 28">
                        <Right_arrow />
                    </svg>
                </span>
            </div>
            {openProgressPopup ? <UploadProgress  percentage={uploadedPercentage} onClose={handleProgressClose}/> : null}
            {openCompletePopup ? <UploadComplete type={uploadDetails.type} onClose={handleCompleteClose}/> : null}
        </>
    );
}
const mapStateToProps = state => {
    return {
        uploading: state.upload,
        notification: state.notification
    }
}

export default connect(mapStateToProps)(Upload);