import React, { useEffect, useState } from "react";
import { ReactMic } from 'react-mic';
import { ReactComponent as MicIcon } from "../../../../assets/images/mic.svg";
import { ReactComponent as PauseIcon } from "../../../../assets/images/pause.svg";
import Closesvg from "../../../../assets/images/close.svg"


let intervalReal = false;
function RecordingModal(props) {
    const [recording, setRecording] = useState({
        record: false,
        playButton: false,
        doneButton: false,
        audioBlob: false,
        time: '00:00:00'
    });
    //const cName = props.show ? 'show' : 'hide';
    const cName = 'show';
    const startRecording = () => {
        let seconds = 0;
        intervalReal = setInterval(() => {
            setRecording({
                ...recording,
                record: true,
                time:new Date(seconds * 1000).toISOString().substr(11, 8)
            });
            seconds = seconds+1;
        },1000);

    }

    const stopRecording = () => {
        setRecording({
            ...recording,
            record: false
        });
        console.log(recording,'Recorded 1')
    }

    const onStop = (recordedBlob) => {
        if(intervalReal)
        {
            //console.log(intervalReal);
            clearInterval(intervalReal)
        }

        setRecording({
            ...recording,
            audioBlob: recordedBlob,
            playButton: true,
            doneButton: true
        });

        console.log(recording,'Recorded ')
    }
    const handlePlay = () => {
        if (recording.playButton && recording.audioBlob) {
            const audioElement = new Audio(recording.audioBlob.blobURL);
            audioElement.play().then(res => { }).catch(error => { });
        }
    }

    return (
        <div
            id="rec-aud"
            style={{ display: 'block' }}
            className={`modal fade ${cName}`}
            tabIndex="-1"
            role="dialog"
            data-backdrop="static"
            aria-labelledby="rec-title"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered mx-auto" role="document">
                <div className="modal-content">
                    <button type="button" onClick={props.close} className="close mb-2 pb-1 text-right" data-dismiss="modal" aria-label="Close"
                        style={{ opacity: '1' }}>
                        <img className="img-fluid" src={Closesvg} alt="Close" />
                    </button>
                    <div className="modal-body border-0 text-center">
                        <h4 id="rec-title" className="modal-title text-white mb-2">Record Audio</h4>
                        <span id="time-display" className="mb-2 text-white">{recording.time}</span>
                        <span id="wave">
                            <ReactMic
                                record={recording.record}
                                className=""
                                onStop={onStop}
                                visualSetting="frequencyBars"
                                strokeColor="#fff"
                                backgroundColor="rgba(0,0,0,0 )" />
                        </span>
                        {recording.record ?
                            <button id="record" className="bg-white d-flex w-100 mx-auto rounded-circle" onClick={stopRecording}>
                                <svg id="pause" width="14px" height="19px" viewBox=" 0 0 14 19">
                                    <PauseIcon />
                                </svg>
                            </button> :
                            <button id="record" className="bg-white d-flex w-100 mx-auto rounded-circle" onClick={startRecording}>
                                <svg id="mic" width="31px" height="31px" viewBox=" 0 0 31 31">
                                    <MicIcon />
                                </svg>
                            </button>}
                    </div>
                    <div className="modal-footer border-top-0 justify-content-center bg-white">
                        {recording.playButton ? <button id="clear-rec" type="button" className="btn btn-2 mr-2" onClick={handlePlay}>Play</button> : <button id="clear-rec" type="button" className="btn btn-2 mr-2" onClick={handlePlay} disabled>Play</button>}
                        {recording.doneButton ? <button id="done-rec" type="button" className="btn btn-3 ml-2" onClick={() => props.submitCall(recording.audioBlob)}>Done</button> : <button id="done-rec" type="button" className="btn btn-3 ml-2" disabled>Done</button>}

                    </div>
                </div>
            </div>
        </div>
    )
}
export default RecordingModal;