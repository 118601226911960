import React, {useEffect, useState} from "react";
import {project_create, project_save} from "../../../../Store/actions/projectAction";
import Toast from 'react-bootstrap/Toast'
import {connect} from "react-redux";
import store from '../../../../Store/store';
import {NavLink} from "react-router-dom";
import {displayNotification} from "../../../../Store/actions/notificationAction";
import Notification from "../../Notification/Notification";
import Coursereelsvg from "../../../assets/images/coursereel.svg"


function SaveHeader(props) {
    const [buttonText,setButtonText] = useState({
        text:props.type === 'create' ? 'Create' : 'Save',
        loading:false
    });
    useEffect(() => {
        setButtonText({
            ...buttonText,
            text:props.type === 'create' ? 'Create' : 'Save',
            loading:false
        });
    },[props.notification.showNotification])
    const submitForm = () => {
        const updatedScript = props.scriptRef.current.value;
        const projectName = props.nameRef.current.value;
        if(projectName !== '' && updatedScript !== '')
        {
            const data = {
                projectName:projectName,
                script:updatedScript,
                user_id:store.getState().auth.user.user_id
            }
            store.dispatch(project_create(data))
        }
        else
        {
            store.dispatch(displayNotification('error','Please give a name to your project'));
        }
    }
    const handleProjectSave = (type) =>{
        setButtonText({
            text: props.type === 'create' ? 'Creating...' : 'Saving',
            loading:true
        })
        const handlePr = type === 'save' ? store.dispatch(project_save('save')) : type === 'create' ? submitForm() : null
    }

    return(
        <header id="app-editor-header">
            <Notification type={props.notification.notification.type} message={props.notification.notification.message}/>
            <div className="container-fluid px-4">
                <div className="row align-items-center">
                    <div className="col colmn-1 col-sm-6 col-12 text-sm-left text-center mb-sm-0 mb-3">
                        <NavLink className="navbar-brand p-0 m-0" to="/dashboard">
                            <img className="img-fluid" src={Coursereelsvg} alt="App Logo" width="172" />
                        </NavLink>
                    </div>
                    <div className="col colmn-2 col-sm-6 col-12">
                        <div className="btn-wrapper d-flex justify-content-sm-end justify-content-center">
                            {buttonText.loading ?
                                <>
                                    <button id="save" className="btn btn-2 mr-2" onClick={() => handleProjectSave(props.type === 'create' ? 'create' : 'save')} disabled>{buttonText.text} </button>
                                    <div className="spinner-grow" style={{ color: '#6b33d3' }}> </div>
                                </> :
                                <button id="save" className="btn btn-2 mr-2" onClick={() => handleProjectSave(props.type === 'create' ? 'create' : 'save')}>{buttonText.text}</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

const mapStateToProps = state => {
    return {
        notification:state.notification
    }
}
export default connect(mapStateToProps)(SaveHeader);