import {ReactComponent as ImagePlusIcon} from "../../../../../assets/images/plus_1.svg";
import React, {useEffect, useState} from "react";
import store from "../../../../../../Store/store";
import {initiateUploadFile, uploadFile} from "../../../../../../Store/actions/uploadActions";
import UploadProgress from "../../../../Dashboard/Upload/UploadProgress/UploadProgress";
import {baseURL} from "../../../../../../Global";
import {add_new_track} from "../../../../../../Store/actions/timelineActions";
import {connect} from "react-redux";
import ItemFilter from "../../../../../../Global/ItemFilter";
function AudioUpload(props) {
    const [uploadDetails, setUploadDetails] = useState({
        maxSize:200,
        type:'audio'
    })

    const [showProgressModal, setShowProgressModal] = useState(false);

    const {uploadedPercentage,isUploaded,type,fileName} = props.uploading;

    const handleChange = (e) =>{
        const fileSize = e.target.files[0].size/(1024*1024);
        const fileName = e.target.files[0].name;
        if(fileSize < uploadDetails.maxSize)
        {
            const fileExtensionAr = fileName.split('.');
            const fileExtension = fileExtensionAr[fileExtensionAr.length - 1];
            if(fileExtension === 'mp3')
            {
                setShowProgressModal(true);
                // Send file through Axios
                let data = new FormData();
                data.append('file',e.target.files[0]);
                data.append('file_type',uploadDetails.type);
                data.append('upload_type','music');
                data.append('user_id',store.getState().auth.user.user_id);
                store.dispatch(initiateUploadFile());
                store.dispatch(uploadFile(data,'audioUpload'));
            }
            else
            {
                // file format is invalid
                alert('file format is invalid');
            }
        }
        else
        {
            // File size is greater
            alert('File size is Greater');
        }

    }
    const handleProgressClose = () => {
        setShowProgressModal(false);
    }


    useEffect(() => {
        if(isUploaded && type === 'audioUpload')
        {
            const fileUrl = baseURL+'uploads/'+store.getState().auth.user.user_id+'/music/'+fileName.file_name;
            const audioData = {
                type:'TRACK',
                trackType:'audio',
                duration:10,
                title:'Your Recorded Audio File',
                url: fileUrl
            }
            setShowProgressModal(false);
            const tts_data = ItemFilter(audioData)
            store.dispatch(add_new_track(tts_data));
        }
        return () => {}
    },[isUploaded])

    return(
        <>
            {console.log('Progress Modal ',showProgressModal)}
            <button id="upload-audio" className="btn btn-3">
                <input type="file" name="file" id="up-video" className="file-type"  accept="audio/mp3"  onChange={handleChange}/>
                <svg width="12px" height="12px" className="ico v-center" viewBox=" 0 0 12 12">
                    <ImagePlusIcon />
                </svg>
                Upload Audio
            </button>
            {showProgressModal ? <UploadProgress percentage={uploadedPercentage} onClose={handleProgressClose}/> : null }
            </>
    )
}
const mapStateToProps = state => {
    return {
        uploading: state.upload
    }
}
export default  connect(mapStateToProps)(AudioUpload);