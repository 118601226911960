import store from "../store";
import {CLEAR_PROJECT, PROJECT, SHOW_NOTIFICATION} from "./index";
import axios from "axios";
import {baseURL} from "../../Global";
import {fetch_project_details} from "./timelineActions";

export const projectFetch = (data) => (dispatch,getState) => {
    // fetch project details
    const options = {
        headers:{
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+store.getState().auth.token,
        }
    }
    axios.get(baseURL+'project-fetch?'+data, options)
        .then(res => {
            if(res.data.status === true)
            {
                dispatch({type:PROJECT,payload:{projects: res.data.data}});
            }
            else{
                console.log('Error',res);
            }
        })
}

// fires when save and renders has been clicked
export const project_save = (data) => (dispatch,getState) => {
    const timeline = getState().timeline;
    const config = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+getState().auth.token
    }
    const sendData = {
        timeline:timeline,
        type:data,
        user_id:getState().auth.user.user_id
    }
    axios.post(baseURL+'project-save',sendData,{headers:config})
        .then(res => {
            if(res.data.status === true){
                const message = {
                    type:'success',
                    message:res.data.message,
                    autoHide:{
                        isAutoHide:true,
                        hideTime:3000,
                        saveType:data
                    }
                }
                dispatch({type:SHOW_NOTIFICATION,payload:message});
            }
            else{
                const message = {
                    type:'error',
                    message:res.data.message,
                    autoHide:{
                        isAutoHide: true,
                        hideTime:3000
                    }
                }
                dispatch({type:SHOW_NOTIFICATION,payload:message});
            }
        })
        .catch(error => {
            console.log('Error',error);
        });
}

export const project_create = (data) => (dispatch,getState) => {
    const config_header = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+getState().auth.token
    }
    axios.post(baseURL+'project-create-new',data,{headers:config_header}).then(res => {
        if(res.data.status === true)
        {
            const data = 'user_id='+getState().auth.user.user_id+'&project_id='+res.data.project_id;
            store.dispatch(fetch_project_details(data));
            const message = {
                type:'success',
                message:res.data.message,
                autoHide:{
                    isAutoHide:true,
                    hideTime:3000,
                    saveType:data
                }
            }
            dispatch({type:SHOW_NOTIFICATION,payload:message});
        }
        else
        {
            const message = {
                type:'error',
                message:res.data.message,
                autoHide:{
                    isAutoHide: true,
                    hideTime:3000
                }
            }
            dispatch({type:SHOW_NOTIFICATION,payload:message});
        }
    }).catch(error => {
        console.log('Error',error);
    })
}

export const clearProjects = () => (dispatch,getState) => {
    dispatch({type:CLEAR_PROJECT});
}