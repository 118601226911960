import React, {useEffect, useRef, useState} from "react";
import SaveHeader from "./SaveHeader/SaveHeader";
import SideBar from "./SideBar/SideBar";
import Script from "./Script/Script";
import MainEditor from "./MainEditor/MainEditor";
import './style.css';
import store from "../../../Store/store";
import {fetch_project_details, unmount_project_details} from "../../../Store/actions/timelineActions";
import {connect} from "react-redux";
function Editor(props) {
    const scriptRef = useRef();
    const nameRef = useRef();
    const projectId = props.match.params.id ? props.match.params.id : false
    useEffect(() => {
        if(projectId)
        {
            const data = 'user_id='+store.getState().auth.user.user_id+'&project_id='+projectId;
            store.dispatch(fetch_project_details(data));
        }
        return () => {
            store.dispatch(unmount_project_details());
        }
    },[])
    return(
        <div className="editor bg-dark">
            <div id="editor-wrapper" className="wrapper">
                <SaveHeader key={props.timeline.project.id} type={props.timeline.project.id ? 'save' : 'create'} nameRef={nameRef} scriptRef={scriptRef} />
                <SideBar type={props.timeline.project.id ? 'save' : 'create'} />
                <div id="editor-tab-wrap" className="tab-content">
                    <Script type={props.timeline.project.id ? 'save' : 'create'} project={props.timeline.project} transcribe={props.timeline.transcribe} nameRef={nameRef} scriptRef={scriptRef} />
                    {props.timeline.project.id ? <MainEditor type={props.timeline.project.id ? 'save' : 'create'} key={props.timeline.project.id} timeline={props.timeline} /> : null }
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        timeline:state.timeline
    }
}
export default connect(mapStateToProps)(Editor);