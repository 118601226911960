import React, {useState} from "react";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import axios from "axios";
import store from "../../../../Store/store";
import {baseURL} from "../../../../Global";
import {displayNotification} from "../../../../Store/actions/notificationAction";
import {fetchSocialAccounts} from "../../../../Store/actions/accountActions";
import ConfirmDelete from "../../ConfirmDelete/ConfirmDelete";
import FacebookGreySvg from "../../../assets/images/logo-facebook-grey.svg"
import LogoFacebook from "../../../assets/images/logo-facebook.svg"

function Facebook(props) {
    const [details,setDetails] = useState({
        name:false,
        userId:false,
        accessToken:false,
        openPopup:false,
        buttonText:'Connect',
        email:false,
        pages:[],
    });
    const [groups,setGroups] = useState([]);
    const [selection,setSelection] = useState({
        type:false,
        id:false,
        accessToken:false,
        name:false
    })

    const [confirmDelete, setConfirmDelete] = useState({
        status:false,
        id:0
    });

    const handleDelete = (id) => {
        setConfirmDelete({
            status:true,
            id:id
        })
    }

    const handleConfirmClose = () => {
        setConfirmDelete({
            status:false,
            id:0
        })
    }

    const config = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+store.getState().auth.token
    }

    const handleConfirmDelete = () => {
        console.log('Delete',confirmDelete.id);
        const data = {
            user_id:store.getState().auth.user.user_id,
            network_id:confirmDelete.id
        }
        axios.post(baseURL+'delete-social-network',data,{headers:config}).then(res => {
            if(res.data.status === true)
            {
                store.dispatch(displayNotification('success',res.data.message));
                // Fetch the updated Social Accounts
                store.dispatch(fetchSocialAccounts());
            }
            else
            {
                store.dispatch(displayNotification('error',res.data.message));
            }
            handleConfirmClose(); // close the confirm delete popup
        }).catch(error => {console.log(error)})
    }




    const responseFacebook = (response) => {
        //console.log(response);
        // Fetch the Pages List
        axios.get('https://graph.facebook.com/me/accounts?type=page&access_token='+response.accessToken).then(res => {
            //console.log(res.data);
            setDetails({
                ...details,
                name: response.name,
                userId: response.userID,
                openPopup:true,
                buttonText:'Connect',
                accessToken: response.accessToken,
                pages:res.data.data
            })
        }).catch(error => console.log(error))

        // Fetch the Groups List
        axios.get(' https://graph.facebook.com/me/groups?access_token='+response.accessToken).then(res => {
            //console.log(res.data);
            setGroups(res.data.data)
        }).catch(error => console.log(error))
    }


    const handleChange = (e) => {
        const selectedValue = e.target.value.split("___CR___");
        //console.log(selectedValue);
        setSelection({
            type:selectedValue[2],
            id:selectedValue[3],
            accessToken:selectedValue[1],
            name:selectedValue[0]
        })
    }

    const handleClick = () => {
        setDetails({
           ...details,
            buttonText:'Connecting...'
        });
        const data = {
            user_id:store.getState().auth.user.user_id,
            title:selection.name,
            token:selection.accessToken,
            fb_id:selection.id,
            category:selection.type,
            email:details.email
        }
        axios.post(baseURL+'save-facebook',data,{headers:config}).then(res => {
            if(res.data.status === true)
            {
                store.dispatch(fetchSocialAccounts());
                store.dispatch(displayNotification('success',res.data.message));
            }
            else
            {
                store.dispatch(displayNotification('error',res.data.message))
            }
            setDetails({
                ...details,
                openPopup: false
            })
        }).catch(res => {})
    }


    return(
        <>
            <div id="fb-account" className={`acc-details-wrapper row mx-auto p-4 mb-3 bg-white ${props.accounts.length > 0 ? '' : 'not-connected'}`}>
                <div className="img-wrapper pr-2">
                    {
                        props.accounts.length <= 0 ?
                        <img className="img-fluid" src={FacebookGreySvg} alt="Facebook Logo" />
                        :
                        <img className="img-fluid" src={LogoFacebook} alt="Facebook Logo" />
                    }
                </div>
                <div className="inner-row-wrapper flex-grow-1">
                    {
                        props.accounts.length <= 0 ?
                            <div className="inner-row d-flex flex-row align-items-center">
                                <div className="acc-details flex-grow-1 px-2">
                                    <h6 className="mb-1">
                                        <span className="md-txt">Facebook </span>
                                    </h6>
                                    <p className="status">
                                        Not Connected
                                    </p>
                                </div>
                                <div className="btns-wrapper d-flex pl-lg-3">
                                    <FacebookLogin
                                        appId="1216019755530202" //APP ID NOT CREATED YET 780210132546000
                                        fields="name,email"
                                        scope="pages_manage_posts,publish_to_groups,publish_video"
                                        callback={responseFacebook}
                                        render={renderProps => (
                                            <button onClick={renderProps.onClick} className="btn btn-2 w-100 mr-2 connect">Connect</button>
                                        )}
                                    />
                                </div>
                            </div> :
                            <>
                                {props.accounts.map(account => <div className="inner-row d-flex flex-row  align-items-center">
                                    <div className="acc-details flex-grow-1 px-2">
                                        <h6 className="mb-1">
                                            <span className="md-txt">Facebook </span><span
                                            className="usr-email md-txt">{account.email}</span>
                                        </h6>
                                        <p className="status">
                                            <span className="security-number sm-txt">{account.title} - {account.fb_id}</span>
                                             added on
                                            <span className="date-added sm-txt">{account.created}</span>
                                        </p>
                                    </div>
                                    <div className="btns-wrapper d-flex pl-lg-3">
                                        {/*<a href="#" className="btn btn-2 reconnect w-100 mr-2">Reconnect</a>*/}
                                        <button  className="btn delt btn-2 w-100 ml-2" onClick={() => handleDelete(account.id)}>
                                            Delete
                                        </button>
                                    </div>
                                </div> )}



                                <div className="conn-btn-wrapper text-center">
                                    <FacebookLogin
                                        appId="780210132546000" //APP ID NOT CREATED YET
                                        fields="name,email"
                                        scope="pages_show_list,pages_manage_posts,publish_to_groups"
                                        callback={responseFacebook}
                                        render={renderProps => (
                                            <button onClick={renderProps.onClick} className="btn btn-2 w-100 mr-2 connect">Connect New Account</button>
                                        )}
                                    />
                                </div>
                            </>

                    }
                </div>
            </div>

            <div id="publish" className={`modal  ${details.openPopup ? 'fade show' : ''}`} tabIndex="-1" role="dialog" data-backdrop="static"
                 aria-labelledby="modal-title"
                 aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered mx-auto" role="document">
                    <div className="modal-content">
                        <button type="button" className="close mb-3 text-right" data-dismiss="modal" aria-label="Close"
                                style={{opacity:'1'}}>
                            <img className="img-fluid" src='../../../assets/images/close.svg' alt="Close" />
                        </button>
                        <div className="modal-body bg-white text-center">
                            <h4 className="modal-title mb-4" style={{lineHeight:'1.33',color:'#000000'}}>Select an account</h4>

                            <div className="mb-2">
                                <select className="form-control" onChange={handleChange}>
                                    <optgroup label="Your Facebook Pages">
                                        {details.pages.map(page => <option value={page.name+'___CR___'+page.access_token+'___CR___page___CR___'+page.id}>{page.name}</option>)}
                                    </optgroup>
                                    <optgroup label="Your Facebook Groups">
                                        {groups.map(group => <option value={group.name+'___CR___'+details.accessToken+'___CR___group___CR___'+group.id}>{group.name}</option>)}
                                    </optgroup>
                                </select>
                            </div>
                            <button id="pub-proj" type="button" className="btn btn-3" onClick={handleClick}>{details.buttonText}</button>
                        </div>
                    </div>
                </div>
            </div>

            {confirmDelete.status ? <ConfirmDelete close={handleConfirmClose} confirm={handleConfirmDelete}/> : null }
        </>
    )
}

export default Facebook