import {ReactComponent as DownArrowIcon} from "../../../../../assets/images/down-arrow.svg";
import React, {useEffect, useRef, useState} from "react";
import store from "../../../../../../Store/store";
import axios from "axios";
import {baseURL} from "../../../../../../Global";
import {add_new_track} from "../../../../../../Store/actions/timelineActions";
import ItemFilter from "../../../../../../Global/ItemFilter";

function TextToSpeech(props) {
    const [tts,setTts] = useState({
        loading:false,
        results:[],
        language:'en-Us',
        voices:[],
        currentVoice:'',
        text:'',
        slide:'1'
    })

    const [graphicItems, setGraphicItems] = useState([]);
    const [buttons, setButtons] = useState({
        prevButton:'Preview',
        saveButton:'Save & Use'
    })

    useEffect(() => {

        const originalTrackList = JSON.parse(JSON.stringify(store.getState().timeline.tracks));
        let graphItem = [];
        for(const [key,value] of Object.entries(originalTrackList))
        {
            const graphArray = originalTrackList[key].map(element => {
                if(element.type === 'graphic')
                {
                    graphItem.push(element);
                }
            });
        }
        setGraphicItems(graphItem);

        const options = {
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+store.getState().auth.token,
            }
        }
        axios.get(baseURL+'polly-languages', options)
            .then(res => {
                if(res.data.status === true)
                {
                    setTts({
                        loading:false,
                        results:res.data.data,
                        language:res.data.data[0].language_code,
                        voices:res.data.data[0].voices,
                        currentVoice:res.data.data[0].voices[0].voice_id
                    });
                }
                else{
                    console.log('Error',res);
                }
            })
        return () => {}
    },[])

    const handleChange = (e) => {
        if(e.target.name === 'language')
        {
            const langAll = tts.results.find(lang => lang.language_code === e.target.value);
            setTts({
                ...tts,
                language:e.target.value,
                voices:langAll.voices,
                currentVoice:langAll.voices[0].voice_id
            })
        }
        else
        {
            setTts({
                ...tts,
                [e.target.name]:e.target.value,
            })
        }

    }
    const handlePreview = (type) => {
        if(type === 'preview')
        {
            setButtons({
                ...buttons,
                prevButton:'Loading...'
            })
        }
        else if(type === 'save')
        {
            setButtons({
                ...buttons,
                saveButton: 'Saving...'
            })
        }

        const language = tts.language;
        const voice = tts.currentVoice;
        const text = tts.text

        // send this to the backend to generate the Audio
        const data = new FormData();
        data.append('user_id',store.getState().auth.user.user_id);
        data.append('language',language);
        data.append('voice',voice);
        data.append('text',text);
        const options = {
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+store.getState().auth.token,
            }
        }
        axios.post(baseURL+'generate-tts', data,options).then(res => {
            if(res.data.status === true)
            {
                if(type === 'preview')
                {
                    const prevAud = new Audio(res.data.url);
                    prevAud.play().then(ad =>{
                        setButtons({
                            ...buttons,
                            prevButton:'Preview'
                        })
                    }).catch(error => console.log(error));
                }
                else if(type === 'save')
                {
                    if(tts.slide === '1')
                    {
                        const audio = {
                            duration:graphicItems[0].duration,
                            title:text,
                            type:'TRACK',
                            trackType:'audio',
                            url:res.data.url
                        }
                        const tts_data = ItemFilter(audio)
                        store.dispatch(add_new_track(tts_data));
                    }
                    else
                    {
                        const audio = {
                            duration:graphicItems[0].duration,
                            title:text,
                            type:'TRACK',
                            trackType:'audio',
                            url:res.data.url
                        }
                        const tts_data = ItemFilter(audio)
                        store.dispatch(add_new_track(tts_data));
                    }

                    setButtons({
                        ...buttons,
                        saveButton:'Save & Use'
                    })
                }
            }
            else
            {

            }
        }).catch(error => {})

    }
    return(
        <div className="card">
            <div id="txt-spch" className="card-header">
                <button className="label w-100 btn btn-link text-white p-0 collapsed" type="button"
                        data-toggle="collapse" data-target="#txt-spch-collapse" aria-expanded="false"
                        aria-controls="txt-spch-collapse">
                    Text to Speech
                </button>
            </div>
            <div id="txt-spch-collapse" className="collapse" aria-labelledby="txt-spch"
                 data-parent="#audio-pick">
                <div className="card-body mt-4 p-0">

                        <div className="text-to-spch form-group mb-3 d-flex flex-xl-nowrap flex-wrap">
                            <div className="sel-wrapper text-center w-100 mr-2">
                                <select id="slide" className="form-control input-dark" name="slide" onChange={handleChange}>
                                    {graphicItems.map((element,index) => <option value={index+1}>{index+1}</option>)}
                                </select>
                                <span className="icon">
                                        <svg width="10px" height="10px" viewBox="0 0 10 10">
                                           <DownArrowIcon />
                                        </svg>
                                    </span>
                                <label htmlFor="slide">SLIDE</label>
                            </div>
                            <div className="sel-wrapper text-center w-100 mr-2">
                                <select id="language" className="form-control input-dark" name="language" onChange={handleChange} >
                                    {tts.results.map(element => <option value={element.language_code}>{element.name}</option>)}
                                </select>
                                <span className="icon">
                                        <svg width="10px" height="10px" viewBox="0 0 10 10">
                                           <DownArrowIcon />
                                        </svg>
                                    </span>
                                <label htmlFor="language">LANGUAGE</label>
                            </div>
                            <div className="sel-wrapper text-center w-100">
                                <select id="voice" className="form-control input-dark" onChange={handleChange} name="currentVoice" value={tts.currentVoice}>
                                    {tts.voices.map(res => <option value={res.voice_id}>{res.voice_id + ' :: ' + res.gender}</option>)}
                                </select>
                                <span className="icon">
                                        <svg width="10px" height="10px" viewBox="0 0 10 10">
                                           <DownArrowIcon />
                                        </svg>
                                    </span>
                                <label htmlFor="voice">VOICE</label>
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <textarea id="enter-script" className="form-control input-dark" name="text" placeholder="Enter Script" onChange={handleChange}> </textarea>
                        </div>
                        <div className="btn-wrapper d-flex">
                            <button id="preview" className="btn btn-2 mr-1" onClick={() => handlePreview('preview')}>{buttons.prevButton}</button>
                            <button id="save-use" className="btn btn-2" onClick={() => handlePreview('save')}>{buttons.saveButton}</button>
                        </div>

                </div>
            </div>
        </div>
    )
}
export default TextToSpeech;