import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as VideoIcon } from "../../../../assets/images/upload-complete.svg";
import { ReactComponent as AudioIcon } from "../../../../assets/images/using-audio.svg";
import store from "../../../../../Store/store";
import axios from "axios";
import { baseURL } from "../../../../../Global";
import { projectFetch } from "../../../../../Store/actions/projectAction";
import { displayNotification } from "../../../../../Store/actions/notificationAction";
import { uploadReset } from "../../../../../Store/actions/uploadActions";
function UploadComplete(props) {
    const [disable, setDisabled] = useState(true);
    const [language, setLanguage] = useState([]);
    const [projectDetails, setProjectDetails] = useState({
        projectName: '',
        projectLanguage: 'en-US',
        sendingData: false
    })
    const projectRef = useRef();

    useEffect(() => {
        // send a request to fetch Languages
        const options = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().auth.token,
            }
        }
        axios.get(baseURL + 'transcribe-languages', options)
            .then(res => {
                if (res.data.status === true) {
                    setLanguage(res.data.data.languages);
                }
                else {
                    console.log('Error', res);
                }
            })

    }, []);

    const handleChange = (e) => {
        setProjectDetails({
            ...projectDetails,
            projectName: e.target.value
        });
        setDisabled(false);
    }
    const handleProjectLang = (e) => {
        setProjectDetails({
            ...projectDetails,
            projectLanguage: e.target.value
        });
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setProjectDetails({
            ...projectDetails,
            sendingData: true
        })
        const projectData = {
            projectName: projectDetails.projectName,
            language: projectDetails.projectLanguage,
            fileName: store.getState().upload.fileName.file_name,
            user_id: store.getState().auth.user.user_id
        }
        // send data through axios
        const options = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + store.getState().auth.token,
            }
        }
        axios.post(baseURL + 'project-create', projectData, options)
            .then(res => {
                store.dispatch(uploadReset());
                if (res.data.status === true) {
                    // Close popup and load Projects details
                    const projectData = 'user_id=' + store.getState().auth.user.user_id;
                    store.dispatch(projectFetch(projectData));
                    store.dispatch(displayNotification('success', res.data.message));
                    props.onClose();

                }
                else {
                    store.dispatch(displayNotification('error', res.data.message))
                    props.onClose();
                }
            })
    }
    const cName = 'show';
    return (
        <div id="upload-vid-aud" style={{ display: 'block' }} className={`modal fade ${cName}`} tabIndex="-1" role="dialog" data-backdrop="static"
            aria-labelledby="modal-title" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered mx-auto" role="document">
                <div className="modal-content">
                    <div className="modal-body bg-white text-center">
                        <div className="img-wrapper">
                            {props.type === 'video' ? <VideoIcon /> : <AudioIcon />}
                        </div>
                        <h4 className="modal-title mb-4" id="delete-warn" style={{ color: '#000000' }}>Upload
                            Complete</h4>
                        <p className="w-100 mx-auto mb-4">
                            <span className="d-block"
                                style={{ fontWeight: '600' }}>Please choose a project name.</span>
                            We will alert you once the text version of your video is ready.
                        </p>
                        <form method="post" action="" className="mb-3" onSubmit={handleSubmit}>
                            <input type="text" className="proj-name form-control h-auto mb-4" name="name"
                                placeholder="Projects name"
                                autoComplete="off"
                                ref={projectRef}
                                onChange={handleChange}
                                required />
                            <select id="proj-language" className="proj-name form-control h-auto mb-4" name="language" onChange={handleProjectLang} value={projectDetails.projectLanguage}>
                                {language.map(lang => <option key={lang.id} value={lang.code}>{lang.language}</option>)}
                            </select>
                            {
                                disable ? <input type="submit" className="btn btn-3 save-project" value="Save" disabled /> :
                                    <input type="submit" className="btn btn-3 save-project" value="Save" />
                            }
                            {projectDetails.sendingData && <div className="spinner-grow" style={{ color: '#6b33d3' }}> </div>}
                        </form>
                        {/* <a href="#" className="align-top do-latter" onClick={this.props.onClose}>
                            I will later
                        </a> */}
                    </div>
                </div>
            </div>
        </div>
    );

}

export default UploadComplete;