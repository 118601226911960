export const UpdateBackground = (element,properties) => {
    const canvasObjects = element.layers['objects'];
    let set = false;
    if(properties.type === 'video')
    {
        element.layers.background = '#222';
        for(let i=0; i<canvasObjects.length; i++)
        {
            if(canvasObjects[i].hasOwnProperty('background_slide_type'))
            {
                canvasObjects[i].video_src = properties.value;
                canvasObjects[i].background_slide_type = 'video'
                canvasObjects[i].src = 'https://coursereelserver.reelapps.io/assets/images/logo.png';
                element.source.thumbnail = properties.thumbnail;
                element.source.url = properties.value;
                element.source.type = properties.type;
                element.source.color = false;
                set = true;
                break;
            }
        }

        // if set is false which means there is not layer for the video and thus create a new one
        if(set === false)
        {
            // create a new layer
            const newLayer = {"type":"image","version":"3.4.0","originX":"left","originY":"top","left":0,"top":0,"width":1920,"height":1080,"fill":"rgb(0,0,0)","stroke":null,"strokeWidth":0,"strokeDashArray":null,"strokeLineCap":"butt","strokeDashOffset":0,"strokeLineJoin":"miter","strokeMiterLimit":4,"scaleX":0.43,"scaleY":0.43,"angle":0,"flipX":false,"flipY":false,"opacity":1,"shadow":null,"visible":true,"clipTo":null,"backgroundColor":"","fillRule":"nonzero","paintFirst":"fill","globalCompositeOperation":"source-over","transformMatrix":null,"skewX":0,"skewY":0,"crossOrigin":"","cropX":0,"cropY":0,"video_src":"","src":"","filters":[]}
            newLayer.video_src = properties.value;
            newLayer.src = 'https://coursereelserver.reelapps.io/assets/images/logo.png';
            newLayer.background_slide_type = 'video';
            canvasObjects.push(newLayer);
            element.source.thumbnail = properties.thumbnail;
            element.source.url = properties.url;
            element.source.type = properties.type;
            element.source.color = false;
        }
    }


    if(properties.type === 'image')
    {
        element.layers.background = '#222';
        for(let i=0; i<canvasObjects.length; i++)
        {
            if (canvasObjects[i].hasOwnProperty('background_slide_type'))
            {
                canvasObjects[i].src = properties.value;
                canvasObjects[i].video_src = '';
                canvasObjects[i].background_slide_type = 'image'
                element.source.thumbnail = properties.thumbnail;
                element.source.url = properties.value;
                element.source.type = properties.type;
                element.source.color = false;
                set = true;
                break;
            }
        }
        // if set is false which means there is not layer for the video and thus create a new one
        if(set === false)
        {
            // create a new layer
            const newLayer = {"type":"image","version":"3.4.0","originX":"left","originY":"top","left":0,"top":0,"width":1920,"height":1080,"fill":"rgb(0,0,0)","stroke":null,"strokeWidth":0,"strokeDashArray":null,"strokeLineCap":"butt","strokeDashOffset":0,"strokeLineJoin":"miter","strokeMiterLimit":4,"scaleX":0.43,"scaleY":0.43,"angle":0,"flipX":false,"flipY":false,"opacity":1,"shadow":null,"visible":true,"clipTo":null,"backgroundColor":"","fillRule":"nonzero","paintFirst":"fill","globalCompositeOperation":"source-over","transformMatrix":null,"skewX":0,"skewY":0,"crossOrigin":"","cropX":0,"cropY":0,"video_src":"","src":"","filters":[]}
            newLayer.src = properties.value;
            newLayer.background_slide_type = 'video';
            canvasObjects.push(newLayer);
            element.source.thumbnail = properties.thumbnail;
            element.source.url = properties.url;
            element.source.type = properties.type;
            element.source.color = false;
        }
    }

    if(properties.type === 'color')
    {
        for(let i=0; i<canvasObjects.length; i++) {
            if (canvasObjects[i].hasOwnProperty('background_slide_type')) {
                canvasObjects.splice(i, 1);
            }
        }
        element.layers.background = properties.value;
        element.source.thumbnail = properties.thumbnail;
        element.source.url = properties.url;
        element.source.type = properties.type;
        element.source.color = properties.value;
        set = true;
        console.log(element);
    }

    return element;
}