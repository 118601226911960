import Menu from "../../Includes/Menu/Menu";
import store from "../../../Store/store";
import React from "react";
import Footer from "../../Includes/Footer/Footer";
import Amazon from "./Amazon/Amazon";
function Account(props) {
    return(
        <body>
        <Menu profile={store.getState().auth.user.profile_pic} location={props.location} />
        <section id="acc-sec-1" className="bg-dark">
            <div className="container">
                <Amazon />
            </div>
        </section>
        <Footer />
        </body>
    )
}
export default Account;