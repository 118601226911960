import getVideoElement from "./GetElement";
import {fabric} from "fabric";

export default async function loadVideoInFabric(canvas,loader,url) {
    if(loader.current !== null)
        loader.current.style.display='block';

    const videoElement = await getVideoElement(url);
    const fab_video = new fabric.Image(videoElement, {left:-10, top: -10});
    canvas.add(fab_video);
    fab_video.set('video_src',url);
    fab_video.set('src','https://coursereelserver.reelapps.io/assets/images/logo.png');
    fab_video.scaleToHeight(canvas.height);
    fab_video.getElement().play();
    fab_video.center();
    fabric.util.requestAnimFrame(function render() {
        canvas.renderAll();
        fabric.util.requestAnimFrame(render);
    });

    if(loader.current !== null)
        loader.current.style.display='none';

    return fab_video;
}