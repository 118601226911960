import { fabric } from "fabric";
export const bounce = (canvas, activeObj) => {
    requestAnimationFrame(function () {
        activeObj.animate({
            top: activeObj.top - 50,
        }, {
            duration: 100,
            onChange: canvas.renderAll.bind(canvas),
            onComplete: function () {
                activeObj.animate({
                    top: activeObj.top + 50,
                }, {
                    duration: 500,
                    onChange: canvas.renderAll.bind(canvas),
                    easing: fabric.util.ease.easeOutBounce
                });
            },
            easing: fabric.util.ease.easeOutBounce
        });
    });
}

export const flash = (canvas, activeObj) => {
    requestAnimationFrame(function () {
        activeObj.animate({
            opacity: 0,
        }, {
            duration: 500,
            onChange: canvas.renderAll.bind(canvas),
            onComplete: function () {
                activeObj.animate({
                    opacity: 1,
                }, {
                    duration: 500,
                    onChange: canvas.renderAll.bind(canvas),
                    easing: fabric.util.ease.easeInSine
                });
            }
        });
    })
}

export const pulse = (canvas, activeObj) => {
    var initScaleX = activeObj.scaleX;
    var initScaleY = activeObj.scaleY;
    requestAnimationFrame(function () {
        activeObj.animate({
            scaleX: initScaleX + 0.2,
            scaleY: initScaleY + 0.2,
        }, {
            duration: 500,
            onChange: canvas.renderAll.bind(canvas),
            onComplete: function () {
                activeObj.animate({
                    scaleX: initScaleX,
                    scaleY: initScaleY,
                }, {
                    duration: 500,
                    onChange: canvas.renderAll.bind(canvas),
                    easing: fabric.util.ease.easeOutCubic
                });
            }
        });
    })
}


export const rubberBand = (canvas, activeObj) => {
    var initScaleX = activeObj.scaleX;
    var initScaleY = activeObj.scaleY;
    requestAnimationFrame(function () {
        activeObj.animate({
            scaleX: initScaleX + 0.2,
            scaleY: initScaleY - 0.3,
        }, {
            duration: 500,
            onChange: canvas.renderAll.bind(canvas),
            onComplete: function () {
                activeObj.animate({
                    scaleX: initScaleX,
                    scaleY: initScaleY,
                }, {
                    duration: 600,
                    onChange: canvas.renderAll.bind(canvas),
                    easing: fabric.util.ease.easeOutElastic
                });
            }
        });
    })
}

export const shakeX = (canvas, activeObj) => {
    requestAnimationFrame(function () {
        activeObj.animate({
            left: activeObj.left - 20,
        }, {
            duration: 300,
            onChange: canvas.renderAll.bind(canvas),
            onComplete: function () {
                activeObj.animate({
                    left: activeObj.left + 20,
                }, {
                    duration: 600,
                    onChange: canvas.renderAll.bind(canvas),
                    easing: fabric.util.ease.easeOutElastic
                });
            },
            easing: fabric.util.ease.easeInBack
        });
    });
}

export const shakeY = (canvas, activeObj) => {
    requestAnimationFrame(function () {
        activeObj.animate({
            top: activeObj.top - 20,
        }, {
            duration: 300,
            onChange: canvas.renderAll.bind(canvas),
            onComplete: function () {
                activeObj.animate({
                    top: activeObj.top + 20,
                }, {
                    duration: 600,
                    onChange: canvas.renderAll.bind(canvas),
                    easing: fabric.util.ease.easeOutElastic
                });
            },
            easing: fabric.util.ease.easeInBack
        });
    })
}

export const headShake = (canvas, activeObj) => {
    requestAnimationFrame(function () {
        activeObj.animate({
            left: activeObj.left - 5,
        }, {
            duration: 300,
            onChange: canvas.renderAll.bind(canvas),
            onComplete: function () {
                activeObj.animate({
                    left: activeObj.left + 5,
                }, {
                    duration: 600,
                    onChange: canvas.renderAll.bind(canvas),
                    easing: fabric.util.ease.easeOutElastic
                });
            },
            easing: fabric.util.ease.easeInBack
        });
    })
}

export function swing(canvas, activeObj) {
    activeObj.animate({
        angle: 8,
        left: activeObj.left - 20
    }, {
        duration: 400,
        onChange: canvas.renderAll.bind(canvas),
        onComplete: function () {
            activeObj.animate({
                angle: -8,
                left: activeObj.left + 40
            }, {
                duration: 300,
                onChange: canvas.renderAll.bind(canvas),
                onComplete: function () {
                    activeObj.animate({
                        angle: 0,
                        left: activeObj.left - 20
                    }, {
                        duration: 200,
                        onChange: canvas.renderAll.bind(canvas),
                        easing: fabric.util.ease.easeOutSine
                    });
                },
                easing: fabric.util.ease.easeOutSine
            });
        },
        easing: fabric.util.ease.easeOutSine
    });
}

export function heartBeat(canvas, activeObj) {
    var initScaleX = activeObj.scaleX;
    var initScaleY = activeObj.scaleY;
    activeObj.animate({
        scaleX: initScaleX + 0.2,
        scaleY: initScaleY + 0.2,
    }, {
        duration: 500,
        onChange: canvas.renderAll.bind(canvas),
        onComplete: function () {
            activeObj.animate({
                scaleX: initScaleX,
                scaleY: initScaleY,
            }, {
                duration: 500,
                onChange: canvas.renderAll.bind(canvas),
                easing: fabric.util.ease.easeOutCubic
            });
        }
    });
}

export function backInDown(canvas, activeObj) {
    var initTop = activeObj.top;
    var initScaleX = activeObj.scaleX;
    var initScaleY = activeObj.scaleY;
    activeObj.set({
        top: -100,
        scaleX: initScaleX - 0.2,
        scaleY: initScaleY - 0.2,
        opacity: 0.5
    });
    canvas.renderAll();

    activeObj.animate({
        top: initTop,
    }, {
        duration: 600,
        onChange: canvas.renderAll.bind(canvas),
        onComplete: function () {
            activeObj.animate({
                scaleX: initScaleX,
                scaleY: initScaleY,
                opacity: 1
            }, {
                duration: 300,
                onChange: canvas.renderAll.bind(canvas),
                easing: fabric.util.ease.easeOutExpo
            });
        },
        easing: fabric.util.ease.easeOutExpo
    });
}


export function backInLeft(canvas, activeObj) {
    var initLeft = activeObj.left;
    var initScaleX = activeObj.scaleX;
    var initScaleY = activeObj.scaleY;
    activeObj.set({
        left: -initLeft,
        scaleX: initScaleX - 0.2,
        scaleY: initScaleY - 0.2,
        opacity: 0.5
    });
    canvas.renderAll();

    activeObj.animate({
        left: initLeft,
    }, {
        duration: 800,
        onChange: canvas.renderAll.bind(canvas),
        onComplete: function () {
            activeObj.animate({
                scaleX: initScaleX,
                scaleY: initScaleY,
                opacity: 1
            }, {
                duration: 300,
                onChange: canvas.renderAll.bind(canvas),
                easing: fabric.util.ease.easeOutExpo
            });
        },
        easing: fabric.util.ease.easeOutExpo
    });
}

export function backInRight(canvas, activeObj) {
    var initLeft = activeObj.left;
    var initScaleX = activeObj.scaleX;
    var initScaleY = activeObj.scaleY;
    activeObj.set({
        left: canvas.width + initLeft,
        scaleX: initScaleX - 0.2,
        scaleY: initScaleY - 0.2,
        opacity: 0.5
    });
    canvas.renderAll();

    activeObj.animate({
        left: initLeft,
    }, {
        duration: 800,
        onChange: canvas.renderAll.bind(canvas),
        onComplete: function () {
            activeObj.animate({
                scaleX: initScaleX,
                scaleY: initScaleY,
                opacity: 1
            }, {
                duration: 300,
                onChange: canvas.renderAll.bind(canvas),
                easing: fabric.util.ease.easeOutExpo
            });
        },
        easing: fabric.util.ease.easeOutExpo
    });
}

export function backInUp(canvas, activeObj) {
    var initTop = activeObj.top;
    var initScaleX = activeObj.scaleX;
    var initScaleY = activeObj.scaleY;
    activeObj.set({
        top: canvas.height + initTop,
        scaleX: initScaleX - 0.1,
        scaleY: initScaleY - 0.1,
        opacity: 0.5
    });
    canvas.renderAll();

    activeObj.animate({
        top: initTop,
    }, {
        duration: 800,
        onChange: canvas.renderAll.bind(canvas),
        onComplete: function () {
            activeObj.animate({
                scaleX: initScaleX,
                scaleY: initScaleY,
                opacity: 1
            }, {
                duration: 300,
                onChange: canvas.renderAll.bind(canvas),
                easing: fabric.util.ease.easeOutExpo
            });
        },
        easing: fabric.util.ease.easeOutExpo
    });
}



