import { ReactComponent as ImageSearchIcon } from "../../../../../../assets/images/search.svg";
import { ReactComponent as ImagePlusIcon } from "../../../../../../assets/images/plus_1.svg";
import React, { useRef, useState } from "react";
import HoverVideoPlayer from "react-hover-video-player";
import videoRequest from "../../../../../../../Global/videolibrary";
import { updateCanvasBackgroundProperties } from "../../../../../../../Store/actions/timelineActions";
import store from "../../../../../../../Store/store";
import VideoLoad from "./VideoLoad";
import ImageLoad from "../Image/ImageLoad";
function Video(props) {
	const slideType = props.type;
	const keywordRef = useRef();
	const btnClass = 'label w-100 btn btn-link text-white p-0 ';
	const [videoLibrary, setVideoLibrary] = useState({
		isLoading: false,
		results: []
	})
	let page = 1;
	const handleSubmit = (e) => {
		e.preventDefault();
		const keyword = keywordRef.current.value;
		if (keyword !== '') {
			setVideoLibrary({
				isLoading: true,
				results: []
			});
		}
		// send axios request for the
		const data = 'keyword=' + keyword + '&page=' + page;
		videoRequest(data).then(results => {
			if (results.length > 1) {
				setVideoLibrary({
					isLoading: false,
					results: results
				})
			}
		}).catch(error => {
			setVideoLibrary({
				isLoading: false,
				results: []
			})
		});

	}

	let uploadRes = {}
	if(props.file)
	{
		uploadRes = {
			thumbnail:false,
			url:props.file.remote_path
		}
	}

	return (
		<>
			<div id="bg-vid" className="card-header">
				<button className={slideType === 'video' ? btnClass : btnClass + 'collapsed'}
					type="button"
					data-toggle="collapse" data-target="#collapseFour" aria-expanded={slideType === 'video' ? 'true' : 'false'}
					aria-controls="collapseFour">
					Background Video
								</button>
			</div>
			<div id="collapseFour" className={slideType === 'video' ? 'collapse show' : 'collapse'} aria-labelledby="bg-vid" data-parent="#bg-color-pick">
				<div className="card-body mt-3 p-0">
					<div className="input-group mb-3">
						<form method="POST" onSubmit={handleSubmit} className="w-100">
							<input id="search-vid" type="search" name="search-vid"
								className="search-vid-media form-control input-dark"
								placeholder="Search" ref={keywordRef} />
							<div className="input-group-prepend v-center cursor-pointer" onClick={handleSubmit}>
								<svg width="16px" height="16px" viewBox=" 0 0 16 16">
									<ImageSearchIcon />
								</svg>
							</div>
						</form>
					</div>
					<div className="results-wrapper d-flex flex-wrap">
						{videoLibrary.isLoading ? <div className="spinner-grow" style={{ color: '#6b33d3' }}> </div> : null}
						{videoLibrary.results.map((result,index) => <VideoLoad result={result} index={index} layer={props.layer} trackID={props.trackID} />)}

						{props.file ? <VideoLoad result={uploadRes} index={Math.random()} layer={props.layer} trackID={props.trackID} /> : null}
					</div>
					<button id="up-bg-vid"
						className="up-vid-btn btn btn-3 text-white d-block mx-auto">
						<input type="file" className="file-type"
							accept=".mp4, .mov, .mpeg, .WEBM, .MOV" onChange={props.change} />
						<svg width="12px" height="12px" className="ico v-center"
							viewBox=" 0 0 12 12"
							style={{ color: '#d19df8' }}>
							<ImagePlusIcon />
						</svg>
												Upload Media
										</button>
				</div>
			</div>
		</>
	)
}
export default Video;