import React, {useEffect, useState} from "react";
import store from "../../../../../../Store/store";
import {initiateUploadFile, uploadFile} from "../../../../../../Store/actions/uploadActions";
import {connect} from "react-redux";
import RecordingModal from "../../../../Dashboard/Record/RecordingModal/RecordingModal";
import UploadProgress from "../../../../Dashboard/Upload/UploadProgress/UploadProgress";
import {baseURL} from "../../../../../../Global";
import {add_new_track} from "../../../../../../Store/actions/timelineActions";
import ItemFilter from "../../../../../../Global/ItemFilter";

function AudioRecord(props) {
    const [record,setRecord] = useState({
        showModal:false,
        showProgress:false
    })
    const {uploadedPercentage,isUploaded,type,fileName} = props.upload;
    const openModel = () => {
        setRecord({
            showModal:true,
            showProgress:false
        })
    }
    const closeModal = () => {
        setRecord({
            showModal:false,
            showProgress:false
        })
    }
    const doneRecord = (blob) => {
        console.log(blob);
        let data = new FormData();
        const file = new File([blob.blob], 'recording', { type: 'audio/mp3' })
        data.append('file',file);
        data.append('file_type','recording');
        data.append('upload_type','music');
        data.append('user_id',store.getState().auth.user.user_id);
        store.dispatch(initiateUploadFile());
        setRecord({
            showModal:false,
            showProgress:true
        })
        store.dispatch(uploadFile(data,'audioRecord'));
    }

    const handleProgressClose = () => {
        setRecord({
            showModal:false,
            showProgress:false
        })
    }

    useEffect(() => {
        if(isUploaded && type === 'audioRecord')
        {
            // file has been upload now add it to the Track
            const fileUrl = baseURL+'uploads/'+store.getState().auth.user.user_id+'/music/'+fileName.file_name;
            const audioData = {
                duration:10,
                title:'Your Recorded Audio File',
                type:'TRACK',
                trackType:'audio',
                url: fileUrl
            }
            const tts_data = ItemFilter(audioData)
            store.dispatch(add_new_track(tts_data));
            handleProgressClose()
        }
        return () => {};
    },[isUploaded])
    return(
        <>
        <button id="record-voiceover" className="btn btn-2 mr-1" data-toggle="modal" data-target="#rec-aud" onClick={openModel}>Record Voiceover
        </button>
            {record.showModal ? <RecordingModal submitCall={doneRecord} close={closeModal} /> : null }
            {record.showProgress ? <UploadProgress percentage={uploadedPercentage} onClose={handleProgressClose}/> : null }
            </>
    )
}

const mapStateToProps = state => {
    return {
        upload: state.upload
    }
}
export default  connect(mapStateToProps)(AudioRecord);