import {
    USER_LOADED,
    USER_LOADING,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    LOGIN_FAIL,
    USER_UPDATED
} from "../actions";

const initialState = {
    token:null,
    isAuthenticated:false,
    isLoading:false,
    user:null,
}

export  default function (state = initialState, action) {
    switch (action.type) {
        case USER_LOADING:
            return{
                ...state,
                isLoading:true,
            }
        case USER_LOADED:
            return {
                ...state,
                token: localStorage.getItem('token'),
                isAuthenticated: true,
                isLoading: false,
                user:action.payload
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                token: localStorage.getItem('token'),
                user:action.payload
            }
        case AUTH_ERROR:
            localStorage.removeItem('token');
            return {
                ...state,
                token:null,
                user:null,
                isAuthenticated: false,
                isLoading:false
            }
        case LOGIN_FAIL:
            //localStorage.removeItem('token');
            return {
                ...state,
                token:null,
                user:null,
                isAuthenticated: false,
                isLoading:false
            }
        case LOGOUT_SUCCESS:
            localStorage.removeItem('token');
            return {
                ...state,
                token:null,
                user:null,
                isAuthenticated: false,
                isLoading:false
            }
        case USER_UPDATED:
            return{
                ...state,
                user:action.payload
            }
        default:
            return state;
    }
}