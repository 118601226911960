import React, {useEffect, useRef, useState} from "react";
import store from "../../../../../../../Store/store";
import {updateCanvasBackgroundProperties} from "../../../../../../../Store/actions/timelineActions";
import hexToRgb from "../../../../../../../Global/HextoRGB";
import reactCSS from "reactcss"
import {SketchPicker} from "react-color";
function Color(props) {
    const slideType = props.type;
    const btnClass = 'label w-100 btn btn-link text-white p-0 ';
    const backgroundColor = ['ffffff','e1e1e1','d7d7d7','cccccc','c2c2c2','b7b7b7','a1a1a1','959595','e8c9ff','d19df8','9b33e9','8133db','7033d5','01803f','54c400','606888','7c83ac'];

    const [activeColor, setActiveColor] = useState({
        displayColorPicker: false,
        hex: '#ffffff',
        color: {
            r: '255',
            g: '255',
            b: '255',
            a: '1',
        },
    });

    const fireRedux = (color) => {
        if(props.layer !== false)
        {
            const selectedLayer = props.layer;
            const trackID = props.trackID;
            const properties = {
                type:'color',
                value:color,
                thumbnail:false
            }
            store.dispatch(updateCanvasBackgroundProperties(trackID,selectedLayer.id,properties));
        }
    }
    const handleClick = () => {
        setActiveColor({
            ...activeColor,
            displayColorPicker: !activeColor.displayColorPicker
        })
    };

    const handleClose = () => {
        setActiveColor({
            ...activeColor,
            displayColorPicker: false
        })
    };

    const handleChange = (color) => {
        setActiveColor({
            ...activeColor,
            hex:color.hex,
            color: color.rgb
        })
        fireRedux(color.hex);
    };

    const onSwatchClickHandler = (color) => {
        setActiveColor({
            ...activeColor,
            hex:color,
            color: hexToRgb(color)
        })
        fireRedux(color);
    }

    const styles = reactCSS({
        'default': {
            color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: `rgba(${ activeColor.color.r }, ${ activeColor.color.g }, ${ activeColor.color.b }, ${ activeColor.color.a })`,
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });
    return (
        <>
            <div id="bg-color" className="card-header">
                <button className={slideType === 'color' ? btnClass : btnClass+' collapsed' } type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne" aria-expanded={slideType === 'color' ? 'true' : 'false'} aria-controls="collapseOne">
                    Background Color
                </button>
            </div>
            <div id="collapseOne" className={slideType === 'color' ? 'collapse show' : 'collapse'} aria-labelledby="bg-color" data-parent="#bg-color-pick">
                <div className="card-body mt-3 p-0">
                    <div className="bg-color form-group d-flex flex-wrap mb-0">
                        <div className="input-color text-center mb-2">
                            <button id="t-color-btn" style={{background:activeColor.hex}} onClick={handleClick }> </button>
                            <input id="bgColor-hex" className="jscolor-input input-dark text-white form-control" value={activeColor.hex}/>
                            <label>HEX</label>
                            { activeColor.displayColorPicker ? <div style={ styles.popover }>
                                <div style={ styles.cover } onClick={handleClose }/>
                                <SketchPicker color={ activeColor.color } onChange={ handleChange } />
                            </div> : null }
                        </div>
                        <div className="rgb-wrapper text-center">
                            <input id="bgColor-red" className="form-control input-dark" type="text"
                                   name="Red"
                                   value={activeColor.color.r} />
                            <label htmlFor="bgColor-red">R</label>
                        </div>
                        <div className="rgb-wrapper text-center">
                            <input id="bgColor-grn" className="form-control input-dark" type="text"
                                   name="Green"
                                   value={activeColor.color.g} />
                            <label htmlFor="bgColor-green">G</label>
                        </div>
                        <div className="rgb-wrapper text-center mr-0">
                            <input id="bgColor-blu" className="form-control input-dark" type="text"
                                   name="Blue"
                                   value={activeColor.color.b}/>
                            <label htmlFor="bgColor-blue">B</label>
                        </div>
                        <div className="swatches w-100 d-flex flex-wrap">
                            <button className="bgColor no-color" style={{background:'#fffff'}} onClick={() => onSwatchClickHandler('#222222')}> </button>
                            {backgroundColor.map((element,index) => <button className="bgColor swatch mb-0" key={index} style={{background:'#'+element}} onClick={() => onSwatchClickHandler('#'+element)}> </button>)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Color;