import React, { useEffect, useState } from "react";
import illosoundsvg from "../../../assets/images/illo-sound.png"
import illoeditingsvg from "../../../assets/images/illo-editing.svg"
import illoeditingpng from "../../../assets/images/illo-editing.png"
import { useSelector } from "react-redux";


export function Content() {
    const rebrandData = useSelector(state => state.rebrand);
    const [rebrand, setRebrand] = useState(rebrandData);

    useEffect(() => {
        setRebrand(rebrandData);
    }, [rebrandData])
    return(
        <section id="feature-section" className="bg-dark">
            <div className="container-fluid px-lg-0 px-4">
                <div className="row row-1 align-items-center">
                    <div className="col colmn-1 offset-xl-1 col-xl-5 col-lg-6 col-md-9 col-12 mb-lg-0 mb-5 pl-xl-0 pl-lg-5">
                        <div className="title-area mb-3 w-100" style={{maxWidth:"525"}}>
                            <h6 className="sub-heading mb-1">GET STARTED WITHOUT A PLAN</h6>
                            <h3 className="text-white font-weight-normal">All you need are your thoughts & voice
                                to get started</h3>
                        </div>
                        <div className="desc w-100 mb-3" style={{maxWidth:"565"}}>
                            <p className="sub-txt">
                                Imagine going on a morning jog and thinking of a course to teach. For 5 days
                                straight, record 5 minute
                                audios. Upload on {rebrand.data !== false ? rebrand.data.name : 'CourseReel'} and you’re first video course is ready to be
                                published on Udemy or Teachable.
                            </p>
                        </div>
                        <div className="txt-block">
                            <p className="text-white font-italic" style={{lineHeight:"2"}}>
                                See how you can use {rebrand.data !== false ? rebrand.data.name : 'CourseReel'} to
                                <a href="#" className="font-weight-bold" style={{color:"#9b33e9"}}>
                                    <i> create your first course</i>
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="col colmn-2 col-lg-6 col-md-9 col-12">
                        <div className="img-wrapper">
                            <picture>
                                <source media="(max-width: 991px)" srcSet={illosoundsvg} />
                                <img className="img-fluid" src={illosoundsvg} alt="Graphics" />
                            </picture>
                        </div>
                    </div>
                </div>
                <div className="row row-2 align-items-center">
                    <div className="col colmn-1 offset-lg-1 col-lg-5 col-md-9 col-12 order-lg-2 order-1 mb-lg-0 mb-5 pr-4">
                        <div className="title-area mb-3 w-100" style={{maxWidth:"525px"}}>
                            <h6 className="sub-heading mb-1">EDIT LIKE A PROFESSIONAL</h6>
                            <h3 className="text-white font-weight-normal">Pour creativity into your videos</h3>
                        </div>
                        <div className="desc w-100 mb-3" style={{maxWidth:"570px"}}>
                            <p className="sub-txt">
                                Edit your videos with ease. Customize your slides the way you want without a
                                rocket science degree. Just
                                use our point-and-click video editing engine and make as robust as you’d like.
                            </p>
                        </div>
                        <div className="txt-block">
                            <p className="text-white font-italic" style={{lineHeight:"2"}}>
                                Watch our <a href="#" className="font-weight-bold" style={{color:"#9b33e9"}}><i> video
                                editing tutorial</i></a> on how to save time while editing your videos
                            </p>
                        </div>
                    </div>
                    <div className="col colmn-2 col-lg-6 col-md-9 col-12 order-lg-1 order-2">
                        <div className="img-wrapper">
                            <picture>
                                <source media="(max-width: 991px)" srcSet={illoeditingsvg} />
                                <img className="img-fluid" src={illoeditingpng} alt="Graphics" />
                            </picture>
                        </div>
                    </div>
                </div>
                <div className="row row-3 align-items-center mb-0">
                    <div className="col colmn-1 offset-xl-1 col-xl-5 col-lg-6 col-md-9 col-12 mb-lg-0 mb-5 pl-xl-0 pl-lg-5">
                        <div className="title-area mb-3 w-100" style={{maxWidth:'525px'}}>
                            <h6 className="sub-heading mb-1">TAKE A BACKSEAT</h6>
                            <h3 className="text-white font-weight-normal">Let your team do it all for you</h3>
                        </div>
                        <div className="desc w-100 mb-3" style={{maxWidth:'565px'}}>
                            <p className="sub-txt">
                                With {rebrand.data !== false ? rebrand.data.name : 'CourseReel'} Professional, share access with teams<br /> to create videos and
                                courses for you. Provide reviews and comments to ensure everyone is on the same page.
                            </p>
                        </div>
                        <div className="txt-block">
                            <p className="text-white font-italic" style={{lineHeight:'2'}}>
                                Find out how the
                                <a href="#" className="font-weight-bold" style={{color:'#9b33e9'}}><i> Team
                                    Members Access</i></a> feature works
                            </p>
                        </div>
                    </div>
                    <div className="col colmn-2 col-lg-6 col-md-9 col-12">
                        <div className="img-wrapper">
                            <picture>
                                <source media="(max-width: 991px)"
                                        srcSet={illoeditingsvg}/>
                                <img className="img-fluid" src={illoeditingpng}
                                     alt="Graphics"/>
                            </picture>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}