export default function ItemFilter(item) {
    if(item.hasOwnProperty('trackType') && item.trackType === 'audio')
    {
        // Handle Item Audio
        // check if the Audio is an existing item from the track or a new Item
        if(item.hasOwnProperty('layer'))
        {
            // Its an existing Item
           return item.layer
        }
        else
        {
            // Its an new Item needs to added into the Track
            return {
                type:'audio',
                startTime:0,
                endTime:item.duration,
                duration:item.duration,
                layers:false,
                is_loaded:false,
                id:Math.random().toString(36).substr(2, 9),
                source:{
                    title:item.title,
                    type:'audio',
                    url:item.url
                },
                volume:1,
                isSelected:false,
                sentence:false,
                keyword:false
            }
        }
    }
    else if(item.hasOwnProperty('trackType') && item.trackType === 'graphic')
    {
        // Handle Item Graphic
        // check if the Item is already exists in the timeline or not
        if(item.hasOwnProperty('layer'))
        {
            return item.layer
        }
        else
        {
            // Check if its an image or a video
            const newLayer = {
                type:'graphic',
                startTime:0,
                endTime:item.duration,
                duration:item.duration,
                is_loaded:false,
                id:Math.random().toString(36).substr(2, 9),
                source:{
                    thumbnail:item.url,
                    type:'image',
                    url:item.url,
                    color:false
                },
                layers:{
                    version:'3.4.0',
                    objects:[],
                    background:'#222222'
                },
                volume:1,
                isSelected:false,
                sentence:false,
                keyword:false
            }

            const newBackground = {type: 'image',
                version: '3.4.0',
                originX:'left',
                originY:'top',
                left:0,
                top:0,
                width:1920,
                height:1080,
                fill:"rgb(0,0,0)",
                stroke:null,
                strokeWidth:0,
                strokeDashArray:null,
                strokeLineCap:'butt',
                strokeDashOffset:0,
                strokeLineJoin:"milter",
                strokeMiterLimit:4,
                scaleX:0.43,
                scaleY:0.43,
                angle:false,
                flipY:false,
                opacity:1,
                shadow:null,
                visible:true,
                clipTo:null,
                backgroundColor:"",
                fillRule:'nonzero',
                paintFirst:'fill',
                globalCompositeOperation:'source-over',
                transformMatrix:null,
                skewX:0,
                skewY:0,
                crossOrigin:"",
                cropX:0,
                cropY:0,
                src:item.url,
                background_slide_type:'image',
                filters:[]
            }

            if(item.graphicType === 'image')
            {
                // create a new image Layer
                newLayer.source.type = 'image';
                newBackground.background_slide_type = 'image';
                newLayer.layers.objects.push(newBackground)
                return newLayer;
            }
            if(item.graphicType === 'video')
            {
                // create a new image Layer
                newLayer.source.type = 'video';
                newLayer.source.thumbnail = item.thumbnail;
                newLayer.source.url = item.url;
                newBackground.background_slide_type = 'video';
                newBackground.video_src = item.url;
                newBackground.src = 'https://coursereelserver.reelapps.io/assets/images/logo.png';
                newLayer.layers.objects.push(newBackground)
                return newLayer;
            }
        }
    }
    else
    {
        return false
    }
}