import React from "react";
import PdfIcon from "../../assets/images/pdf-icon.jpg"

function TrainingPDF() {
    const trainingPdf = [
        {id:'1',image:'',title:'Walkthrough of the App',link:'https://trainingpdfallapps.s3.amazonaws.com/CourseReel/CourseReel-+Walkthrough.pdf'},
        {id:'2',image:'',title:'Creating a Project',link:'https://trainingpdfallapps.s3.amazonaws.com/CourseReel/CourseReel-+Create+Project.pdf'},
        {id:'3',image:'',title:'Connect Social Accounts',link:'https://trainingpdfallapps.s3.amazonaws.com/CourseReel/CourseReel-+Connect+Social+Accounts.pdf'},
        {id:'4',image:'',title:'Project Customization',link:'https://trainingpdfallapps.s3.amazonaws.com/CourseReel/CourseReel-Customizing+the+project.pdf'},
        {id:'5',image:'',title:'Create and Add Amazon Details ',link:'https://trainingpdfallapps.s3.amazonaws.com/CourseReel/CourseReel-+Connect+Amazon.pdf'}
    ]

    return (
        <section id="tpc-section" className="section">
            <div className="container-fluid px-4">
                <div className="row">
                    <div className="col colmn-1 col-12 mb-4">
                        <div className="title-area text-center">
                            <h3 className="text-white">Important Topics</h3>
                        </div>
                    </div>
                    <div className="card-row d-flex flex-row flex-wrap w-100 mx-auto tpc-section-wrap">
                        {trainingPdf.map(training =>
                            <a href={training.link} target="_blank">
                                <div className="tpc-card card border-0 text-center w-100 mr-4 mb-4" key={training.id}>
                                    <img className="card-img-top img-icon mb-3" src={PdfIcon}
                                         alt="Icon" />
                                    <div className="card-body p-0">
                                        <h5 className="card-title mb-0">{training.title}</h5>
                                    </div>
                                </div>
                            </a>
                        )}
                    </div>
                </div>
            </div>
        </section>
    )
}
export default  TrainingPDF;