import React, { useEffect, useState } from "react";
import { baseURL } from "../../../Global";
import { NavLink } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import store from "../../../Store/store";
import { ReactComponent as Right_arrow } from "../../assets/images/right_arrow.svg";
import logo from "../../assets/images/coursereel.svg"
// import {fetchRebrandData} from '../../../Store/actions/rebrandAction'


function Menu(props) {
    const [dropDownVisible, setDropdownVisible] = useState(true);
    const handleClick = () => {

    }
    const auth = useSelector(state => state.auth);
    const accountType = store.getState().auth.user.account_type;
    const dfyCourse = accountType.indexOf('dfy');
    const dfyCourseLite = accountType.indexOf('dfylite');
    const business = accountType.indexOf('business');
    const unlimited = accountType.indexOf('unlimited');
    const rebrandData = useSelector(state => state.rebrand);
    const [rebrand, setRebrand] = useState(rebrandData);
    let image = false;

    if (store.getState().auth.user.is_team)
        image = store.getState().auth.user.virtual.profile_pic;
    else
        image = store.getState().auth.user.profile_pic;


    useEffect(() => {
        document.addEventListener('mousedown', handleClick(), false)
        return () => {
            document.removeEventListener('mousedown', handleClick(), false)
        };
    }, []);

    //ankit tessting
    // useEffect(() => {
    //     console.log('outer run')
    //     if (auth.user !== false) {
    //             fetchRebrandData(auth.token)
    //             console.log('run')
    //     }
    // }, [])


    useEffect(() => {
        setRebrand(rebrandData);
    }, [rebrandData])

    const menuName = props.location.pathname.replace('/', '');
    return (
        <header id="app-header" className="position-fixed w-100" >
            <div className="container-fluid px-4">
                <nav className="navbar navbar-expand-lg navbar-dark p-0">
                    <NavLink className="navbar-brand p-0" to="/dashboard">
                        <img className={rebrand !== false ? "rebranding-logo" : "img-fluid"} src={rebrand.data !== false ? rebrand.data.logo : logo} alt="App Logo" width="199" />
                    </NavLink>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#app-nav"
                        aria-controls="app-nav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"> </span>
                    </button>
                    <div id="app-nav" className="collapse navbar-collapse justify-content-end">
                        <ul className="navbar-nav align-items-lg-center">
                            <li className={`nav-item nav-item-1 ${menuName === 'dashboard' ? ' active' : ''}`}>
                                <NavLink to='/dashboard' className='nav-link text-white'> Dashboard </NavLink>
                            </li>
                            <li className={`nav-item nav-item-2 ${menuName === 'projects' ? ' active' : ''}`}>
                                <NavLink to='/projects' className='nav-link text-white'> Projects </NavLink>
                            </li>
                            <li className={`nav-item nav-item-2 ${menuName === 'integrations' ? ' active' : ''}`}>
                                <NavLink to='/integrations' className='nav-link text-white'> Integrations </NavLink>
                            </li>
                            {/*<li className={`nav-item nav-item-2 ${menuName === 'billing' ? ' active' : ''}`}>*/}
                            {/*    <NavLink to='/billing' className='nav-link text-white'> Billing </NavLink>*/}
                            {/*</li>*/}
                            <li className={`nav-item nav-item-2 ${menuName === 'support' ? ' active' : ''}`}>
                                <NavLink to='/support' className='nav-link text-white'> Help </NavLink>
                            </li>
                            <li className="nav-item nav-item-7 dropdown">
                                <a id="avatar" className="nav-link text-white pr-0" href="#" role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <img className="img-fluid profile" src={image} alt="Avatar" />
                                </a>
                                {dropDownVisible ? (
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown" >
                                        <NavLink className="dropdown-item" to="/my-account">My Account</NavLink>
                                        <NavLink className="dropdown-item" to="/account">Connect Amazon</NavLink>
                                        {business !== -1 ?<NavLink className="dropdown-item" to="/rebranding">Agency Rebranding</NavLink> : ''}
                                        {unlimited !== -1 ? <>
                                            <NavLink className="dropdown-item" to="/acadeable">Acadeable</NavLink>
                                        </> : null} {dfyCourse !== -1 ? <>
                                            <NavLink className="dropdown-item" to="/dfy-course">DFY Course</NavLink>
                                        </> : null} {dfyCourseLite !== -1 ? <>
                                            <NavLink className="dropdown-item" to="/dfy-course-lite">DFY Course Lite</NavLink>
                                        </> : null}{business !== -1 ? <>
                                            <NavLink className="dropdown-item" to="/business">Business</NavLink>
                                            <NavLink className="dropdown-item" to="/team-member">Team Members</NavLink>
                                            <div className="dropdown-divider mb-0"> </div>  </> : null}
                                        <NavLink className="dropdown-item" to="/sign-out">
                                            Sign Out <span className="icon"><svg width="12px" height="12px" viewBox="0 0 12 12"><Right_arrow /></svg></span>
                                        </NavLink>
                                    </div>
                                ) : null}

                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    )
}
export default Menu;
