import {combineReducers} from "redux";
import errorReducer from './error';
import authReducer from './auth';
import uploadFile from './upload';
import projectFile from "./project";
import timeline from "./timeline";
import notification from "./notification";
import social from "./social";
import rebrandReducer from "./rebrandReducer";
export default combineReducers({
    error:errorReducer,
    auth:authReducer,
    rebrand: rebrandReducer,
    upload:uploadFile,
    projects:projectFile,
    timeline:timeline,
    notification:notification,
    social:social
});