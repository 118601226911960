import React, {useEffect, useState} from "react";
import store from "../../../Store/store";
import {logoutUser} from "../../../Store/actions/authActions";
import Dashboard from "../../Core/Dashboard/Dashboard";
import {Redirect} from "react-router-dom";
import {clearProjects} from "../../../Store/actions/projectAction";
function Logout(props) {
    const [logout, setLogout] = useState(false)
    useEffect(() => {
        const menuName = props.location.pathname.replace('/','');
        if(menuName === 'sign-out')
        {
            store.dispatch(clearProjects());
            store.dispatch(logoutUser());
            setLogout(true)
        }
    },[props.location.pathname])

    return(
        logout && <Redirect to={Dashboard} />
    );
}
export default Logout;