import {HIDE_NOTIFICATION, SHOW_NOTIFICATION} from "../actions";
const initialState = {
    showNotification:false,
    notification:{
        type:null,
        message:false,
        autoHide:{}
    }
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SHOW_NOTIFICATION:
            return{
                ...initialState,
                showNotification:true,
                notification:action.payload
            }
        case HIDE_NOTIFICATION:
            return {
                ...initialState,
                showNotification:false,
                notification:action.payload
            }
        default:
            return initialState;
    }
}