import {ReactComponent as EditIcon} from "../../assets/images/edit.svg";
import React, {useEffect, useState} from "react";
import store from "../../../Store/store";
import axios from "axios";
import {baseURL} from "../../../Global";
import {displayNotification} from "../../../Store/actions/notificationAction";
import {connect} from "react-redux";
import {updateUserDetails} from "../../../Store/actions/authActions";

function Personal(props) {
    const [personalDetails, setPersonalDetails] = useState({
        name:props.userDetails.is_team ? props.userDetails.virtual.name : props.userDetails.name,
        email:props.userDetails.is_team ? props.userDetails.virtual.email : props.userDetails.email,
        accountType:props.userDetails.account_type,
        isLoading:false,
        buttonText:'Update',
    });

    const [profilePicDetails, setProfilePicDetails] = useState({
        profilePic:props.userDetails.is_team ? props.userDetails.virtual.profile_pic : props.userDetails.profile_pic,
        isLoading:false,
        maxFileSize:2,
        buttonText:'Edit'
    })

    const config = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+store.getState().auth.token
    }
    const handleChange = (e) => {
        setPersonalDetails({
            ...personalDetails,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setPersonalDetails({
            ...personalDetails,
            isLoading:true,
            buttonText:'Updating...'
        })
        const data = {
            user_id:props.userDetails.is_team ? props.userDetails.virtual.id : store.getState().auth.user.user_id,
            name:personalDetails.name
        };

        axios.post(baseURL+'change-name',data,{headers:config}).then(res => {
            if(res.data.status === true)
            {
                store.dispatch(displayNotification('success',res.data.message))
            }
            else
            {
                store.dispatch(displayNotification('error',res.data.message))
            }
            setPersonalDetails({
                ...personalDetails,
                isLoading:false,
                buttonText:'Update'
            })
        }).catch(error => {})
    }

    const handleProfile = (e) => {
        const fileSize = e.target.files[0].size/(1024*1024);
        const fileName = e.target.files[0].name;
        if(fileSize <= profilePicDetails.maxFileSize)
        {
            const fileExtensionAr = fileName.split('.');
            const fileExtension = fileExtensionAr[fileExtensionAr.length - 1];
            if(fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png')
            {
                const data = new FormData();
                data.append('file',e.target.files[0]);
                data.append('user_id',store.getState().auth.user.user_id);

                axios.post(baseURL+'change-profile-image',data,{headers:config}).then(res => {
                    if(res.data.status === true)
                    {
                        setProfilePicDetails({
                            ...profilePicDetails,
                            profilePic:res.data.file
                        })
                        // Dispatch an action to update the Redux store of state
                        const data = {
                            name:store.getState().auth.user.name,
                            profile_pic:res.data.file
                        }
                        store.dispatch(updateUserDetails(data))
                    }
                    else
                    {
                        store.dispatch(displayNotification('error',res.data.message))
                    }
                }).catch(error => {})
            }
            else
            {
                store.dispatch(displayNotification('error','The file extension you are trying to upload is not allowed'))
            }
        }
        else
        {
            store.dispatch(displayNotification('error','You cannot upload more then 2MB in size'))
        }
    }

    return(
        <div className="row row-2 mb-lg-5 mb-4">
            <div className="col col-md-8 col-12 mb-md-0 mb-4">
                <div className="card">
                    <div className="card-header">
                        <h4 className="text-white">Update your personal information</h4>
                    </div>
                    <div className="card-body">
                        <form method="POST" onSubmit={handleSubmit}>
                            <div className="form-group">
                                <input type="text" className="form-control input-dark" name="name" value={personalDetails.name} required onChange={handleChange}/>
                            </div>
                            <div className="form-group">
                                <input type="email" className="form-control input-dark" name="email" value={personalDetails.email} readOnly />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control input-dark" name="account" value={personalDetails.accountType} readOnly />
                            </div>
                            <div className="row">
                                <div className="col col-md-12">
                                    <button className="btn btn-3 update-pass pull-right">{personalDetails.buttonText}</button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
            <div className="col col-md-4 col-12">
                <div className="card text-center h-100">
                    <div className="card-header">
                        <h4 className="text-white">Profile picture</h4>
                    </div>
                    <div className="card-body">
                        <img src={profilePicDetails.profilePic} className="img-fluid" alt="Placeholder" />
                        <div className="input-wrapper flex-wrap">
                            <input type="file" className="edit-prof" onChange={handleProfile}/>
                            <svg className="d-block mx-auto text-white mb-2" width="30px" height="30px" viewBox="0 0 30 30">
                                <EditIcon />
                            </svg>
                            <label className="text-white mx-auto w-100 align-self-start">{profilePicDetails.buttonText}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        userDetails:state.auth.user,
    }
}
export default connect(mapStateToProps)(Personal);