import Draggable from "react-draggable";
import React from "react";
import store from "../../../../../../Store/store";
import {needlePointUpdate} from "../../../../../../Store/actions/timelineActions";
import {connect} from "react-redux";

function Needle(props) {
    const needlePosition = props.needlePoint*60;
    const handlePinDrag = (e, data) => {
        const position = Math.round(data.x / 60);
        store.dispatch(needlePointUpdate(position));
    }
    return(
        <Draggable
            axis="x"
            handle=".timeline-component-pin"
            defaultPosition={{ x: 0, y: 0 }}
            position={{ x: needlePosition, y: 0 }}
            bounds=".timeline-component-ruler"
            onStop={handlePinDrag}
        >
            <div className="timeline-component-pin"> </div>
        </Draggable>
    )
}
const mapStateToProps = state => {
    return {
        needlePoint:state.timeline.needlePoint
    }
}
export default connect(mapStateToProps)(Needle);
