import React, {useEffect, useState} from "react";
import Notification from "../Notification/Notification";
import store from "../../../Store/store";
import axios from "axios";
import {baseURL} from "../../../Global";
import {displayNotification} from "../../../Store/actions/notificationAction";
import {connect} from "react-redux";
import Menu from "../../Includes/Menu/Menu";
import Amazon from "../Accounts/Amazon/Amazon";
import Footer from "../../Includes/Footer/Footer";
function TeamMember(props) {
    const [teamMember, setTeamMember] = useState({
        name:false,
        email:false,
        password:false,
        confirm_password:false,
        buttonText:'Create'
    });
    const [results, setResults] = useState([]);
    const config = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+store.getState().auth.token
    }
    const fetchTeamAccounts = () => {
        const data1 = {
            user_id:store.getState().auth.user.user_id
        }
        axios.post(baseURL+'fetch-client-team-account',data1,{headers:config}).then(res => {
            if(res.data.status === true)
            {
                setResults(res.data.data.team_members);
            }
        }).catch(res => {})
    }

    const handleChange = (e) => {
        setTeamMember({
            ...teamMember,
            [e.target.name]:e.target.value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if(teamMember.password === teamMember.confirm_password)
        {
            setTeamMember({
                ...teamMember,
                buttonText: 'Creating...'
            });

            const data = {
                user_id:store.getState().auth.user.user_id,
                name:teamMember.name,
                email:teamMember.email,
                password:teamMember.password
            };


            axios.post(baseURL+'create-team-account',data,{headers:config}).then(res => {
                if(res.data.status === true)
                {
                    store.dispatch(displayNotification('success',res.data.message))
                    fetchTeamAccounts();
                }
                else
                {
                    store.dispatch(displayNotification('error',res.data.message))
                }
                setTeamMember({
                    ...teamMember,
                    buttonText: 'Create'
                });

            }).catch(error => {})
        }
        else
        {
            store.dispatch(displayNotification('error','Passwords do not match'))
        }

    }
    useEffect(() => {
        // Fetch Team Accounts
        fetchTeamAccounts();
    },[])
    return (
            <body>
            <Menu profile={store.getState().auth.user.profile_pic} location={props.location} />
            <section id="acc-sec-1" className="bg-dark min-vh-100">
                <div className="container">
                    <div className="row row-3">
                        <Notification type={props.notification.notification.type} message={props.notification.notification.message}/>
                        <div className="col col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="text-white">Create Team Member</h4>
                                </div>
                                <div className="card-body">
                                    <div className="col col-12">
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col col-lg-6 col-12 px-2">
                                                    <div className="form-group">
                                                        <input className="form-control input-dark" type="text" name="name" onChange={handleChange} required />
                                                        <label>Name</label>
                                                    </div>
                                                </div>
                                                <div className="col col-lg-6 col-12 px-2">
                                                    <div className="form-group">
                                                        <input className="form-control input-dark" type="email" name="email"  onChange={handleChange} required />
                                                        <label>Email</label>
                                                    </div>
                                                </div>
                                                <div className="col col-lg-6 col-12 px-2">
                                                    <div className="form-group">
                                                        <input className="form-control input-dark" type="password" name="password" onChange={handleChange} required />
                                                        <label>Password</label>
                                                    </div>
                                                </div>
                                                <div className="col col-lg-6 col-12 px-2">
                                                    <div className="form-group">
                                                        <input className="form-control input-dark" type="password" name="confirm_password" onChange={handleChange} required />
                                                        <label>Confirm Password</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col col-md-12">
                                                    <button className="btn btn-3 update-pass pull-right">{teamMember.buttonText}</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="row row-3">
                        <div className="col col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="text-white">Your Team Members </h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col col-lg-12 col-12 px-2">
                                            {results.length > 0 ?
                                                <div className="table-responsive">
                                                    <table className="table text-white">
                                                        <thead>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Created</th>
                                                        <th>Action</th>
                                                        </thead>
                                                        <tbody>
                                                        {results.map(result => <tr>
                                                            <td>{result.name}</td>
                                                            <td>{result.email}</td>
                                                            <td>{result.created}</td>
                                                            <td><button className="btn btn-3 update-pass"><i className="fa fa-trash-o"> </i></button></td>
                                                        </tr> )
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                : <p className="text-white text-center">No Team Member Created</p>}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            {/* Privacy page Starts Here */}
            <section id="acc-sec-1" className="bg-dark min-vh-100 sec-privacy">
               <div className="container">
                   <div className="sec-block-wrap">
                       <h4 className="text-white">Change Email</h4>
                       <div className="sec-block">
                          <div className="form-wrap">
                              <div className="row">
                                  <div className="col-lg-6">
                                      <div className="form-group form-groupN">
                                        <p>Current Email Address</p>
                                        <input type="email" name="email" className="inp" value="ramanand@vega6.com" />
                                      </div>
                                  </div>
                                  <div className="col-lg-6">
                                      <div className="form-group form-groupN">
                                        <p>New Email Address</p>
                                        <input type="email" name="email" className="inp" value="ramanand@vega6.com" />
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="form-wrap">
                              <button className="colorBtn">Update</button>
                          </div>
                       </div>
                   </div>
                   <div className="sec-block-wrap">
                       <h4 className="text-white">Manage Your Account Data</h4>
                       <div className="sec-block">
                           <div className="data-block">
                               <div className="row align-items-center">
                                   <div className="col-lg-8">
                                       <p className="text-white">VirtualReel stores your private information like your name, email. It also stores your uploaded graphics such as images, videos and your music tiles along with TTS Text to speech). If you wish to download these data, you can do that -by clicking" download your data.</p>
                                   </div>
                                   <div className="col-lg-4 text-right">
                                   <button class="f-500 md-txt account-data-btn">Download my data</button>
                                   </div>
                               </div>
                           </div>
                           <div className="data-block">
                               <div className="row align-items-center">
                                   <div className="col-lg-8">
                                       <p className="text-white">If you wish to stop your private data from getting processed on VirtualReel you can dick the button to do so. Please note that doing this will disable your account and you will not be able to use VirtualReel further. In order to reactivate your account, you would have to contact our support team at <a href="">support@vineasx.com</a>. <span>Please Proceed With Caution</span>.</p>
                                   </div>
                                   <div className="col-lg-4 text-right">
                                   <button class="f-500 md-txt account-data-btn process-data">Stop Processing my data</button>
                                   </div>
                               </div>
                           </div>
                           <div className="data-block">
                               <div className="row align-items-center">
                                   <div className="col-lg-8">
                                       <p className="text-white">If you wish to delete all your private data, you can do so by clicking this button. Please note that this will permanently delete all the data of yours from our server. This step is not reversible and you cannot use VirtualReel further. You cannot undo this step from support also. So if you wish to re-use VirtualReel again, then you will have to start all over again from scratch. <span>Please Proceed With Caution</span>.</p>
                                   </div>
                                   <div className="col-lg-4 text-right">
                                   <button class="f-500 md-txt account-data-btn delete-data">Delete my data</button>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
            </section>
            {/* Privacy page End Here */}

            {/* Upgrades page Starts Here */}
            <section id="acc-sec-1" className="bg-dark min-vh-100 sec-upgrades">
                <div className="container-fluid">
                   <div className="row">
                       <div className="col-lg-4">
                           <div className="sidebar">
                               <div className="topics">
                                   <div className="topic-sub">
                                       <div className="list-group">
                                           <a className="active" href="">Acadeable</a>
                                           <a href="">DFY Course</a>
                                           <a href="">DFY Course Lite</a>
                                           <a href="">Business</a>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div className="col-lg-8">
                           <div className="tab-content">
                               <div className="tab-pane fade active show">
                                   <div className="tab-wrapper">
                                       <div className="tab-header">
                                           <h2>Acadeable</h2>
                                       </div>
                                       <div className="tab-desc">
                                       <section id="tpc-section" className="section">
                                            <div className="container-fluid px-4">
                                                <div className="row">
                                                    <div className="col colmn-1 col-12 mb-4">
                                                        <div className="title-area text-center">
                                                            <h3 className="text-white">Acadeable Bonus</h3>
                                                        </div>
                                                    </div>
                                                    <div className="card-row d-flex flex-row flex-wrap w-100 mx-auto">
                                                        <a href="https://acadeable.s3.amazonaws.com/Acadeable_files.zip" target="_blank" style={{margin:'0 auto'}}>
                                                            <button className="btn btn-1 text-white">Download Acadeable</button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
                </div>
            </section>
            {/* Upgrades page Starts Here */}

            {/* Account management Starts Here */}
            <section id="acc-sec-1" className="bg-dark min-vh-100 sec-management">
               <div className="container">
               <div className="sec-block-wrap">
                       <h4 className="text-white">Create Account</h4>
                       <div className="sec-block">
                          <div className="form-wrap">
                              <div className="row">
                                  <div className="col-lg-6">
                                      <div className="form-group form-groupN">
                                        <p>Account</p>
                                        <select className="inp">
                                            <option>Client Account</option>
                                            <option>Team Member Account</option>
                                        </select>
                                      </div>
                                  </div>
                                  <div className="col-lg-6">
                                      <div className="form-group form-groupN">
                                        <p>Account Name</p>
                                        <input type="text" name="" className="inp" value="Roberto" />
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="form-wrap">
                              <div className="row">
                                  <div className="col-lg-6">
                                      <div className="form-group form-groupN">
                                        <p>Account Email</p>
                                        <input type="text" name="" className="inp" value="Roberto@vega6.com" />
                                      </div>
                                  </div>
                                  <div className="col-lg-6">
                                      <div className="form-group form-groupN">
                                        <p>Account Password</p>
                                        <input type="password" name="" className="inp" value="Roberto" />
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="form-wrap">
                              <button className="colorBtn">Create</button>
                          </div>
                       </div>
                   </div>

                   <div className="sec-block-wrap">
                      <h4 className="text-white">Client Account</h4>
                      <div className="sec-block for-table">
                          <div className="table-wrapper">
                              <div className="table-responsive">
                                <table className="table">
                                    <tr>
                                        <th>Name</th>
                                        <th>Email Address</th>
                                        <th>Created</th>
                                        <th>Actions</th>
                                    </tr>
                                    <tr>
                                        <td>Name</td>
                                        <td>Email Address</td>
                                        <td>Created</td>
                                        <td>Actions</td>
                                    </tr>
                                </table>
                              </div>
                          </div>
                      </div>
                   </div>
                   <div className="sec-block-wrap">
                      <h4 className="text-white">Team Member Account</h4>
                      <div className="sec-block for-table">
                          <div className="table-wrapper">
                              <div className="table-responsive">
                                <table className="table">
                                    <tr>
                                        <th>Name</th>
                                        <th>Email Address</th>
                                        <th>Created</th>
                                        <th>Actions</th>
                                    </tr>
                                    <tr>
                                        <td>Name</td>
                                        <td>Email Address</td>
                                        <td>Created</td>
                                        <td>Actions</td>
                                    </tr>
                                </table>
                              </div>
                          </div>
                      </div>
                   </div>
               </div>
            </section>
            {/* Account management Starts Here */}

            <Footer />
            </body>
    )
}
const mapStateToProps = state => {
    return {
        notification:state.notification
    }
}
export default connect(mapStateToProps)(TeamMember);