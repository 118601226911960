import { ReactComponent as ImageSearchIcon } from "../../../../../../assets/images/search.svg";
import { ReactComponent as ImagePlusIcon } from "../../../../../../assets/images/plus_1.svg";
import React, { useRef, useState } from "react";
import imageRequest from "../../../../../../../Global/imageLibrary";
import HoverVideoPlayer from "react-hover-video-player";
import store from "../../../../../../../Store/store";
import { updateCanvasBackgroundProperties } from "../../../../../../../Store/actions/timelineActions";
import {useDrag} from "react-dnd";
import ImageLoad from "./ImageLoad";
const page = 1;
function Image(props) {
	const ImageRef = useRef();
	const [imageLibrary, setImageLibrary] = useState({
		isLoading: false,
		results: []
	})



	const slideType = props.type;
	const btnClass = 'label w-100 btn btn-link text-white p-0 ';

	const handleSubmit = (e) => {
		e.preventDefault();
		const keyword = ImageRef.current.value;
		if (keyword !== '') {
			setImageLibrary({
				isLoading: true,
				results: []
			});
		}
		// send axios request for the
		const data = 'keyword=' + keyword + '&page=' + page;
		imageRequest(data).then(results => {
			if (results.length > 1) {
				setImageLibrary({
					isLoading: false,
					results: results
				})
			}
		}).catch(error => {
			setImageLibrary({
				isLoading: false,
				results: []
			})
		});
	}

	let uploadRes = {}
	if(props.file)
	{
		uploadRes = {
			thumbnail:false,
			url:props.file.remote_path
		}
	}


	return (
		<>
			<div id="bg-img" className="card-header">
				<button className={slideType === 'image' ? btnClass : btnClass + ' collapsed'} type="button"
					data-toggle="collapse" data-target="#collapseThree" aria-expanded={slideType === 'image' ? 'true' : 'false'}
					aria-controls="collapseThree">
					Background Image
				</button>
			</div>
			<div id="collapseThree" className={slideType === 'image' ? 'collapse show' : 'collapse'} aria-labelledby="bg-img" data-parent="#bg-color-pick">
				<div className="card-body mt-3 p-0">
					<div className="input-group mb-3">
						<form onSubmit={handleSubmit} className="w-100">
							<input id="search-bg" type="search" name="search-bg"
								className="search-img-media form-control input-dark"
								placeholder="Search" ref={ImageRef} />
							<div className="input-group-prepend v-center cursor-pointer" onClick={handleSubmit}>
								<svg width="16px" height="16px" viewBox=" 0 0 16 16">
									<ImageSearchIcon />
								</svg>
							</div>
						</form>
					</div>
					<div className="results-wrapper d-flex flex-wrap">
						{imageLibrary.isLoading ? <div className="spinner-grow" style={{ color: '#6b33d3' }}> </div> : null}
						{imageLibrary.results.map((result,index) => (
							<ImageLoad result={result} index={index} layer={props.layer} trackID={props.trackID}/>
						))}

						{props.file ? <ImageLoad result={uploadRes} index={Math.random()} layer={props.layer} trackID={props.trackID} /> : null}
					</div>
					<button id="up-bg-img"
						className="up-img-btn btn btn-3 text-white d-block mx-auto">
						<input type="file" className="file-type"
							accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG" onChange={props.change} />
						<svg width="12px" height="12px" className="ico v-center"
							viewBox=" 0 0 12 12"
							style={{ color: '#d19df8' }}>
							<ImagePlusIcon />
						</svg>
						Upload Media
					</button>
				</div>
			</div>
		</>
	);
}
export default Image;