import React from "react";
import { ReactComponent as Edit_icon } from '../../../assets/images/edit.svg';
import { ReactComponent as Right_arrow } from '../../../assets/images/right-arrow.svg';
import { NavLink } from "react-router-dom";
function Write(props) {
    return (
        <NavLink to="/editor" id="create-proj" className="action-btn sub-txt d-flex align-items-center ml-auto mr-lg-0 mr-auto mb-2">
            <span className="icon text-white pr-3">
                <svg width="40px" height="40px" viewBox=" 0 0 40 40">
                    <Edit_icon />
                </svg>
            </span>
            <span className="flex-grow-1 text-white pl-3">Write & Create</span>
            <span className="icon arrow" style={{ color: '#424344' }}>
                <svg width="28px" height="28px" viewBox="0 0 28 28">
                    <Right_arrow />
                </svg>
            </span>
        </NavLink>
    );
}
export default Write;
