import {
    ACTIVE_ITEM,
    NEEDLE_POINT,
    PROJECT_DETAILS, PROJECT_UNMOUNT,
    PROJECT_UPDATE,
    TIMELINE_SETTINGS,
    TRANSCRIBE_UPDATE
} from "../actions";
const initialState = {
    project:{
    },
    transcribe:{
    },
    zoomValue:100,
    measureStick:1,
    needlePoint:0,
    length:60,
    tracks:false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case PROJECT_DETAILS:
            return{
                ...state,
                project:action.payload.details.project,
                transcribe:action.payload.details.transcribe,
                tracks:JSON.parse(action.payload.details.tracks)
            }
        case PROJECT_UNMOUNT:
            return{
                ...initialState
            }
        case PROJECT_UPDATE:
            return {
                ...state,
                project:action.payload.project
            }
        case TRANSCRIBE_UPDATE:
            return {
                ...state,
                transcribe:action.payload.transcribe
            }
        case TIMELINE_SETTINGS:
            return{
                ...state,
                zoomValue:action.payload.zoomValue,
                measureStick:action.payload.measureStick
            }
        case ACTIVE_ITEM:
            return{
                ...state,
               tracks:action.payload.tracks
            }
        case NEEDLE_POINT:
            return{
                ...state,
                needlePoint:action.payload.needlePoint
            }
        default:
            return state;
    }
}
