import {
    USER_LOADED,
    USER_LOADING,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    LOGIN_FAIL,
    SHOW_NOTIFICATION, USER_UPDATED
} from "./index";
import axios from 'axios';
import {baseURL} from '../../Global/index';

export const loginUser = (authentication) => (dispatch,getState) => {
    // user loading
    dispatch({type:USER_LOADING});
    // call axios to send a post request
    axios({
        method: 'POST',
        url: baseURL+'validate_login',
        data: authentication,
        headers: {'Content-Type': 'application/json' }
    })
        .then(res => {
            if(res.data.status === true){
                localStorage.setItem('token',res.data.token);
                dispatch({type:LOGIN_SUCCESS,payload:res.data.user});
            }
            else
            {
                const message = {
                    type:'error',
                    message:res.data.message,
                    autoHide:{
                        isAutoHide:true,
                        hideTime:3000
                    }
                }
                dispatch({type:SHOW_NOTIFICATION,payload:message});
                //dispatch({type:LOGIN_FAIL,payload:res.data.message});
            }
        })
        .catch(error => {
            dispatch({type:AUTH_ERROR});
            const message = {
                type:'error',
                message:'Something went wrong. Might be a network interruption. Please try again',
                autoHide:{
                    isAutoHide:true,
                    hideTime:3000
                }
            }
            dispatch({type:SHOW_NOTIFICATION,payload:message});
        })
}


// Fetch details based on Token
export const verifyToken = () => (dispatch,getState) => {
    // call user to fetch details
    // call axios to send a post request
    const config = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('token')
    }
    const message = {
        type:'error',
        message:'Something went wrong. Might be a network interruption. Please try again',
        autoHide:{
            isAutoHide:true,
            hideTime:3000
        }
    }
    axios.post(baseURL+'verify-token',null,{headers:config})
        .then(res => {
            if(res.data.status === true){
                //console.log(res.data);
                dispatch({type:USER_LOADED,payload:res.data.data});
            }
            else{
                message.message = res.data.message;
                dispatch({type:SHOW_NOTIFICATION,payload:message});
                dispatch({type:AUTH_ERROR,payload:res.data.message});
            }
        })
        .catch(error => {
            dispatch({type:SHOW_NOTIFICATION,payload:message});
        });
}


export const logoutUser = () => (dispatch,getState) => {
    const config = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('token')
    }
    const data = {
        user_id:getState().auth.user.user_id,
        token:localStorage.getItem('token')
    }
    const message = {
        type:'error',
        message:'Something went wrong. Might be a network interruption. Please try again',
        autoHide:{
            isAutoHide:true,
            hideTime:3000
        }
    }

    axios.post(baseURL+'logout',data,{headers:config})
        .then(res => {
            if(res.data.status === true){
                dispatch({type:LOGOUT_SUCCESS,payload:res.data.data});
            }
            else{
                message.message = res.data.message;
                dispatch({type:SHOW_NOTIFICATION,payload:message});
            }
        })
        .catch(error => {
            dispatch({type:SHOW_NOTIFICATION,payload:message});
        });
}

export const updateUserDetails = (data) => (dispatch,getState) => {
    const user = {
        ...getState().auth.user,
        name:data.name,
        profile_pic:data.profile_pic
    }
    console.log(user);
    dispatch({type:USER_UPDATED,payload:user});
}