import React, {useContext, useEffect, useState} from "react";
import {ReactComponent as PreviousIcon} from "../../../../../assets/images/back.svg";
import {ReactComponent as RewindIcon} from "../../../../../assets/images/rewind.svg";
import {ReactComponent as ForwardIcon} from "../../../../../assets/images/fast-forward.svg";
import {ReactComponent as PlayIcon} from "../../../../../assets/images/play_1.svg";
import {ReactComponent as NextIcon} from "../../../../../assets/images/forward.svg";
import {ReactComponent as FullScreenIcon} from "../../../../../assets/images/fullscreen.svg";
import {ReactComponent as PauseIcon} from "../../../../../assets/images/pause.svg";
import {fabric} from "fabric";
import store from '../../../../../../Store/store';
import getVideoElement from "../../../../../../Global/GetElement";
import CanvasContext from "../../../../../../Global/CanvasContext";
import {
    first_layer_selection,
    last_layer_selection,
    needlePointUpdate, nextElement, previousElement
} from "../../../../../../Store/actions/timelineActions";
import loadVideoInFabric from "../../../../../../Global/loadVideoInFabric";

const canvasLoad = (loader,FabricCanvas,jsonData) => {
    const canvasObjects = jsonData['objects'];
    let set = false;
    for(let i=0; i< canvasObjects.length; i++){
        if(canvasObjects[i].hasOwnProperty('background_slide_type'))
        {
            set = true;
            if(canvasObjects[i]['background_slide_type'] === 'video')
            {
                if(canvasObjects[i].hasOwnProperty('video_src') && canvasObjects[i]['video_src'] !== '') {
                    loadVideoInFabric(FabricCanvas,loader,canvasObjects[i]['video_src']).then(FabricVideoElement => {
                        FabricVideoElement.set('background_slide_type','video');
                        FabricCanvas.sendToBack(FabricVideoElement);
                        FabricVideoElement.selectable = false;
                    });
                }
            }
            if(canvasObjects[i]['background_slide_type'] === 'image')
            {
                if(canvasObjects[i].hasOwnProperty('src') && canvasObjects[i]['src'] !== '')
                {
                    loader.current.style.display='block';
                    const imageObject = FabricCanvas.getObjects().find(obj => obj.src === canvasObjects[i]['src']);
                    imageObject.selectable = false;
                    imageObject.set('background_slide_type','image');
                    imageObject.scaleToHeight(FabricCanvas.height);
                    imageObject.center();
                    FabricCanvas.sendToBack(imageObject);
                    loader.current.style.display='none';
                }
            }
        }
        else
        {
            if(canvasObjects[i].hasOwnProperty('video_src') && canvasObjects[i]['video_src'] !== '') {
                loadVideoInFabric(FabricCanvas,loader,canvasObjects[i]['video_src']).then(fabVideo => {});
            }
        }
    }

    if(!set)
    {
        loader.current.style.display='none'; // Make the loader display none if there is no background Property to take account for background Color
    }
}
let check = false;
//let time = store.getState().timeline.needlePoint;
let time = 0;
function PreviewControls(props) {
    const FabricCanvas = useContext(CanvasContext);
    const sortedTracks = [];
    const [prevControls, setPrevControls] = useState({
        isPlaying:false,
        playStartTime:store.getState().timeline.needlePoint,
        isPaused:false,
        timelineLength:store.getState().timeline.length,
    });
    useEffect(() => {
        if(props.slayer.layer !== false && props.slayer.layer !== undefined)
        {
            setPrevControls({
                ...prevControls,
                playStartTime:props.slayer.layer.startTime
            })
        }
    },[props.slayer.layer])
    const createPreviewItems = () => {
        const tracks = store.getState().timeline.tracks;
        for(const[key,value] of Object.entries(tracks))
        {
            value.map(element => sortedTracks.push(element));
        }
        sortedTracks.sort((a,b) => (a.startTime > b.startTime) ? 1 : ((b.startTime > a.startTime) ? -1 : 0));

        // set the playIcon
        setPrevControls({
            ...prevControls,
            isPlaying:true
        })
        clearInterval(check);
        check = window.setInterval(function(){
            if(!prevControls.isPaused)
            {
                // will execute only if isPause is false;
                sortedTracks.forEach((item) => {
                    if(item.startTime === time)
                    {
                        if(item.type === 'graphic')
                        {
                            //FabricCanvas.clear();

                            FabricCanvas.loadFromJSON(item.layers, function() {
                                canvasLoad(props.loader,FabricCanvas,item.layers)
                            },function(o,object){
                                // console.log(o,object)
                            })
                        }
                        if(item.type === 'audio')
                        {
                            if(item.source)
                            {
                                const audio = new Audio(item.source);
                                audio.play().then(function () {
                                }).catch(function (error) {
                                    console.log(error);
                                });
                            }
                        }
                    }
                });
            }
            time++;
            setPrevControls({
                ...prevControls,
                isPlaying:true,
                playStartTime:time
            })
            if(time > prevControls.timelineLength){
                setPrevControls({
                    ...prevControls,
                    isPlaying:false,
                    playStartTime:0
                })
                clearInterval(check);
            }
        }, 1000);
    }

    const handleClickPlay = () => {
        createPreviewItems();
    }

    const handleClickPause = () => {
        clearInterval(check);
        setPrevControls({
            ...prevControls,
            isPaused: true,
            isPlaying: false,
            playStartTime:time
        })

    }
    const handleRewindClick = () => {
        store.dispatch(first_layer_selection());
    }

    const handleClickForward = () => {
        store.dispatch(last_layer_selection());
    }

    const handleClickPrevious = () => {
        store.dispatch(previousElement());
    }
    const handleNextElement = () => {
        store.dispatch(nextElement());
    }

    let currentTime = '00:00:00';
    if(prevControls.playStartTime)
    {
        const measuredTime = new Date(null);
        measuredTime.setSeconds(prevControls.playStartTime);
        currentTime =  measuredTime.toISOString().substr(11, 8);
    }

     let totalTime = '00:00:00';
    if(prevControls.timelineLength)
    {
        const measuredTime1 = new Date(null);
        measuredTime1.setSeconds(prevControls.timelineLength);
        totalTime =  measuredTime1.toISOString().substr(11, 8);
    }
    return(
        <div className="vid-controls mt-2 d-flex flex-wrap align-items-center">
            <div className="time-wrapper d-flex">
                <span className="current-time text-white mr-3">{currentTime} </span>
                <span className="duration-time">{totalTime} </span>
            </div>
            <ul className="controls-wrapper d-flex">
                <li className="control-btn pr-3" onClick={handleClickPrevious}>
                    <a id="back" href="#" className="text-white" data-toggle="tooltip" title="Back">
                        <svg width="24px" height="24px" className="d-block" viewBox="0 0 24 24">
                          <PreviousIcon />
                        </svg>
                    </a>
                </li>
                <li className="control-btn pl-3 pr-3" onClick={handleRewindClick}>
                    <a id="rewind" href="#" className="text-white" data-toggle="tooltip" title="Rewind">
                        <svg width="24px" height="24px" className="d-block" viewBox="0 0 24 24">
                            <RewindIcon />
                        </svg>
                    </a>
                </li>
                {prevControls.isPlaying ?
                    <li className="control-btn pl-3 pr-3" onClick={handleClickPause}>
                        <a id="play" href="#" className="text-white" data-toggle="tooltip" title="Play">
                            <svg width="24px" height="24px" className="d-block" viewBox="0 0 24 24">
                                <PauseIcon />
                            </svg>
                        </a>
                    </li>:
                    <li className="control-btn pl-3 pr-3" onClick={handleClickPlay}>
                        <a id="play" href="#" className="text-white" data-toggle="tooltip" title="Play">
                            <svg width="24px" height="24px" className="d-block" viewBox="0 0 24 24">
                                <PlayIcon />
                            </svg>
                        </a>
                    </li>
                }

                <li className="control-btn pl-3 pr-3" onClick={handleClickForward}>
                    <a id="fast-forward" href="#" className="text-white" data-toggle="tooltip"
                       title="Fast Forward">
                        <svg width="24px" height="24px" className="d-block" viewBox="0 0 24 24">
                            <ForwardIcon />
                        </svg>
                    </a>
                </li>
                <li className="control-btn pl-3" onClick={handleNextElement}>
                    <a id="forward" href="#" className="text-white" data-toggle="tooltip" title="Forward">
                        <svg width="24px" height="24px" className="d-block" viewBox="0 0 24 24">
                            <NextIcon />
                        </svg>
                    </a>
                </li>
            </ul>
            <div className="icon-wrapper">
                <a id="fullscreen" href="#" className="text-white" data-toggle="tooltip"
                   title="Full Screen">
                    <svg width="24px" height="24px" className="d-block" viewBox="0 0 24 24">
                        <FullScreenIcon />
                    </svg>
                </a>
            </div>
        </div>
    )
}
export default PreviewControls;