import {FETCH_ACCOUNTS, HIDE_SOCIAL_ACCOUNTS, SHOW_NOTIFICATION} from "./index";
import axios from "axios";
import {baseURL} from "../../Global";

export const fetchSocialAccounts = () =>  (dispatch,getState)  => {
    const config = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('token')
    }
    const payload = {
        type:'',
        message:'',
        autoHide:{
            isAutoHide: true,
            hideTime:3000
        }
    }
    const data = {
        user_id:getState().auth.user.user_id
    }
    axios.post(baseURL+'fetch-social-accounts',data,{headers:config})
        .then(res => {
            if(res.data.status === true){
                //console.log(res.data);
                dispatch({type:FETCH_ACCOUNTS,payload:res.data.data});
            }
            else{
                // payload.type = 'error';
                // payload.message = res.data.message;
                // dispatch({type:SHOW_NOTIFICATION,payload:payload});
            }
        })
        .catch(error => {
            payload.type = 'error';
            payload.message = 'Something went wrong';
            dispatch({type:SHOW_NOTIFICATION,payload:payload});
        });
}


export const hideSocialAccounts = () =>  (dispatch)  =>{
    dispatch({type:HIDE_SOCIAL_ACCOUNTS});
}