import React, {useState} from "react";
import store from "../../../Store/store";
import axios from "axios";
import {baseURL} from "../../../Global";
import {displayNotification} from "../../../Store/actions/notificationAction";

function Password(props) {
    const [passwordDetails, setPasswordDetails] = useState({
        current_password:false,
        new_password:false,
        confirm_new_password:false,
        isLoading:false,
        buttonText:'Update'
    });
    const config = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+store.getState().auth.token
    }
    const handleChange = (e) => {
        setPasswordDetails({
            ...passwordDetails,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setPasswordDetails({
            ...passwordDetails,
            isLoading:true,
            buttonText:'Updating...'
        })
        if(passwordDetails.new_password === passwordDetails.confirm_new_password)
        {
            const data = {
                user_id:store.getState().auth.user.user_id,
                old_password:passwordDetails.current_password,
                new_password:passwordDetails.new_password
            };

            axios.post(baseURL+'change-password',data,{headers:config}).then(res => {
                if(res.data.status === true)
                {
                    store.dispatch(displayNotification('success',res.data.message))
                }
                else
                {
                    store.dispatch(displayNotification('error',res.data.message))
                }
                setPasswordDetails({
                    ...passwordDetails,
                    isLoading:false,
                    buttonText:'Update'
                })
            }).catch(error => {})
        }
        else
        {
            setPasswordDetails({
                ...passwordDetails,
                isLoading:false,
                buttonText:'Update'
            })
            store.dispatch(displayNotification('error','Password do not match'))
        }
    }
    return(
        <div className="row row-3">
            <div className="col col-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="text-white">Update your password</h4>
                    </div>
                    <div className="card-body">
                        <div className="col col-12">
                            <form method="POST" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col col-lg-4 col-12 px-2">
                                        <div className="form-group">
                                            <input className="form-control input-dark" type="password" name="current_password" placeholder="Current Password" required onChange={handleChange}/>
                                        </div>
                                    </div>
                                    <div className="col col-lg-4 col-12 px-2">
                                        <div className="form-group">
                                            <input className="form-control input-dark" type="password" name="new_password" placeholder="New Password" required onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col col-lg-4 col-12 px-2">
                                        <div className="form-group">
                                            <input className="form-control input-dark" type="password" name="confirm_new_password" placeholder="Confirm New Password" required onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col col-md-12">
                                        <button className="btn btn-3 update-pass pull-right">{passwordDetails.buttonText}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Password;