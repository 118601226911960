import React, { useState } from "react";
import { ReactComponent as ShowIcon } from "../../../../../assets/images/show.svg";
import { ReactComponent as HideIcon } from "../../../../../assets/images/hide.svg";
import { ReactComponent as LockIcon } from "../../../../../assets/images/lock.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/images/delete.svg";
import Track from "./Track/Track";
import store from "../../../../../../Store/store";
import {
	add_new_track,
	add_new_track_reorder,
	deleteSelectedTrack
} from "../../../../../../Store/actions/timelineActions";
import { useDrop } from "react-dnd";
import ItemFilter from "../../../../../../Global/ItemFilter";

let overTrackID = false;
function Tracks(props) {
	const [elementDetails, setElementDetails] = useState({
		activeType: "graphic"
	})

	const reOrderTracks = (item) => {
		const data = ItemFilter(item); // Item filters to return the final object
		store.dispatch(add_new_track_reorder(overTrackID, data))
	}

	const [trackIsOver, dropTrack] = useDrop({
		accept: 'TRACK',
		drop: (item, monitor) => reOrderTracks(item),
		collect: monitor => ({
			isOver: !!monitor.isOver()
		})
	})

	const fireOver = (trackKey) => {
		overTrackID = trackKey;
	}
	const handleDeleteClick = () => {
		// check which item is selected
		store.dispatch(deleteSelectedTrack())
	}
	const handleClick = (e) => {
		props.click(e);
		if (e.currentTarget.classList.contains('timeline-audio-track') === true) {
			setElementDetails({
				activeType: "audio"
			})
		}
		else {
			setElementDetails({
				activeType: "graphic"
			})
		}
	}
	const tracks = [];
	if (props.tracks) {
		for (const [key, track] of Object.entries(props.tracks)) {
			const tracks_ini =
				<div className={`timeline-component-track ${trackIsOver.isOver && 'new-track'}`} id={"track_id_" + key} key={key} ref={dropTrack} onClick={trackIsOver.isOver ? fireOver(key) : fireOver}>
					<div className="timeline-track">
						{
							elementDetails.activeType === 'audio' ? (
								<>
									<span className="track-title">Volume</span>
									<div className="volume-wrapper">
										<input className="volume form-control-range" type="range" name="volume" step="1" min="0"
											max="100" />
									</div>
								</>
							) : (
									<span className="track-title">Track</span>
								)
						}
						<div className="track-btns">
							{/*<a href="#" className="ico icon-show-hide">*/}
							{/*    <svg className="ico-show" width="12px" height="12px" viewBox="0 0 12 12">*/}
							{/*        <ShowIcon />*/}
							{/*    </svg>*/}
							{/*    <svg className="ico-hide" width="12px" height="12px" viewBox="0 0 12 12">*/}
							{/*        <HideIcon />*/}
							{/*    </svg>*/}
							{/*</a>*/}
							{/*<a href="#" className="ico icon-lock">*/}
							{/*    <svg width="12px" height="12px" viewBox="0 0 12 12">*/}
							{/*        <LockIcon />*/}
							{/*    </svg>*/}
							{/*</a>*/}
							<a href="#" className="ico icon-delete" onClick={handleDeleteClick}>
								<svg width="12px" height="12px" viewBox="0 0 12 12">
									<DeleteIcon />
								</svg>
							</a>
						</div>
					</div>
					<div className="timeline-track-layer track-layer-1">
						{track.map((element, index) => <Track key={index} element={element} click={handleClick} />)}
					</div>
				</div>
			tracks.push(tracks_ini);
		}
	}

	return (
		tracks
	)


}
export default Tracks